import API from '../../stores/API';
import { showWarningNotice } from 'Library/notifications';
import cloneDeep from 'lodash/cloneDeep';
import { setCountry } from 'ReduxActions/countries';

export const SET_SUPPLIER = 'SET_SUPPLIER';
export const SET_SUPPLIERS = 'SET_SUPPLIERS';
export const SET_PAGINATION = 'SET_PAGINATION';
export const LOGO_BLACK = 'logo_black';
export const LOGO_WHITE = 'logo_white';
export const SET_PENNYBRIDGE_LIST = 'SET_PENNYBRIDGE_LIST';
export const SET_HAS_FETCHED_PENNYBRIDGE_LIST_FROM_COUNTRY = 'SET_HAS_FETCHED_PENNYBRIDGE_LIST_FROM_COUNTRY';

const singleIncludes = [
  'addresses',
  'locations',
  'files',
  'country'
];

export function recieveSupplier (payload) {
  return {
    type: SET_SUPPLIER,
    payload
  };
}

function recieveSuppliers (payload) {
  return {
    type: SET_SUPPLIERS,
    payload
  };
}

function recieveSuppliersPagination (payload) {
  return {
    type: SET_PAGINATION,
    payload
  };
}

function setHasFetchedPennyBridgeListFromCountry (payload) {
  return {
    type: SET_HAS_FETCHED_PENNYBRIDGE_LIST_FROM_COUNTRY,
    payload
  };
}

function receivePennyBridgeList (payload) {
  return {
    type: SET_PENNYBRIDGE_LIST,
    payload
  };
}

export function getSupplier (id) {
  return async (dispatch) => {
    dispatch(recieveSupplier(null));

    const { errors, data } = await API.GET(`/suppliers/${id}?include=${singleIncludes.join(',')}`);

    if (errors && errors.code === 404) {
      alert('Supplier not found!'); // This will have to do for now...
      return;
    }

    dispatch(setCountry(data.country));

    data.locations = data.locations.map(({ id }) => id);
    dispatch(recieveSupplier(data));
  };
}

export function getPennyBridgeList (countryId) {
  return async (dispatch) => {
    const data = await API.GET(`/suppliers/get-pennybridge-list/${countryId}`);
    dispatch(receivePennyBridgeList(data));
    dispatch(setHasFetchedPennyBridgeListFromCountry(countryId));

    return true;
  };
}

export function updateSupplier (data) {
  return async (dispatch) => {
    dispatch(recieveSupplier(data));
  };
}

function formatAddress (supplier, type = 'postal') {
  const address = supplier.addresses.find(supplierAddress => supplierAddress.type === type);

  if (address) {
    const { address_1, postcode, city } = address; // eslint-disable-line camelcase
    return { address_1, postcode, city }; // eslint-disable-line camelcase
  }

  return {
    address_1: '',
    postcode: '',
    city: ''
  };
}

export function persistSupplier () {
  return async (dispatch, getState) => {
    let response;
    const { suppliers } = getState();

    // Compat fixes for legacy API
    const supplier = cloneDeep(suppliers.supplier);
    supplier.addresses = {
      postal: formatAddress(supplier),
      visit: formatAddress(supplier, 'visit'),
      invoice: formatAddress(supplier, 'invoice')
    };

    if (!supplier.id) {
      response = await API.POST('/suppliers', supplier);
    } else {
      response = await API.PUT(`/suppliers/${supplier.id}`, supplier);
    }

    parseResponseAndUpdateSupplier(response, dispatch);

    return response;
  };
}

export function deleteSupplier (id) {
  return async () => {
    await API.DELETE(`/suppliers/${id}`);
  };
}

export function toggleSupplier (id, deactivate = false) {
  return async (dispatch) => {
    const data = (deactivate) ? { deactivate: 1 } : {};
    const response = await API.POST(`/suppliers/${id}/toggle`, data);

    parseResponseAndUpdateSupplier(response, dispatch);
  };
}

export function attachFileToSupplier (id, formData) {
  return async (dispatch) => {
    const response = await API.POST(`/suppliers/${id}/attach-image`, formData, false);

    parseResponseAndUpdateSupplier(response, dispatch);
  };
}

export function detachFileFromSupplier (id, data) {
  return async (dispatch) => {
    const response = await API.POST(`/suppliers/${id}/detach-image`, data);

    parseResponseAndUpdateSupplier(response, dispatch);
  };
}

export function getSuppliers ({
  type = null,
  search = '',
  country = null,
  location = null,
  page = 1
}) {
  return async (dispatch, getState) => {
    let url = `/suppliers?limit=30&mini&with_archived&page=${page}`;

    if (search) {
      url += `&search=${search}`;
    }
    if (country?.id && location) {
      url += `&location=${location.value}`;
    }
    if (type) {
      url += `&type=${type}`;
    }
    if (country?.id) {
      url += `&country_id=${country.id}`;
    }

    const { data, status, errors, meta = {} } = await API.GET(url);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    dispatch(recieveSuppliersPagination(meta.pagination || null));
    dispatch(recieveSuppliers(data));
  };
}

function parseResponseAndUpdateSupplier (response, dispatch) {
  const { data, status, errors } = response;

  if (status !== 'ok') {
    showWarningNotice(errors.error_message);
    return;
  }

  data.locations = data.locations.map(({ id }) => id);
  dispatch(recieveSupplier(data));
}
