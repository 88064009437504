import React from 'react';
import RelatedOrders from '../RelatedOrders';
import OrderTitle from '../OrderTitle';
import UserAssigner from '../UserAssigner/UserAssigner';
import OrderStatus from '../OrderStatus';
import OrderInfoHeader from '../OrderHeaderInfo';
import useOrderTasks from '../../hooks/useOrderTasks';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import OrderAdvisorCTA from '../OrderAdvisorCTA';
import OrderSecret from '../OrderSecret';
import OrderCustomerMeeting from '../OrderCustomerMeeting';
import OrderCustomerVerified from '../OrderCustomerVerified';
import MemorialPageHeaderLink from '../MemorialPageHeaderLink';
import QueueWarning from './QueueWarning';
import DuplicateOrderLink from '../DuplicateOrderLink';
import { formatDate } from 'Library/functions';
import ArchiveButton from '../ArchiveButton';
import DeleteOrderButton from '../../views/new/DeleteOrderButton';
import { If } from 'Conditionals';
import { useTranslation } from 'react-i18next';
import MarkAsAvailable from 'Components/MarkAsAvailable';
import RestoreButton from 'Components/RestoreButton';
import OrderTags from 'Components/OrderTags';

function OrderHeader () {
  const { t } = useTranslation();
  const { order } = useSingleOrder();
  const { calculatePercentageComplete } = useOrderTasks();

  return (
    <div className='single-order-header'>
      <div className='top-row'>
        <div className='top-row__group related'>
          <RelatedOrders />
        </div>
        <div className='top-row__group flex'>
          <div className='top-row__group'>
            <div className='top-row__group'>
              <OrderTitle order={order} />
            </div>

            <div className='top-row__group'>
              <UserAssigner />
            </div>
          </div>

          <div className='top-row__group__sub-group'>
            {['invoiced', 'partially_invoiced', 'passed', 'paid_inquiry', 'new_inquiry', 'inquiry'].includes(order.status)
              ? (
                <div className='sub-group__item'>
                  <ArchiveButton order={order} />
                </div>
                )
              : null}
            <If
              condition={order.status === 'trashed' || (order.archived_at !== null && order.archived_at !== undefined)}
            >
              <RestoreButton order={order} />
            </If>
            <div className='sub-group__item'>
              <DeleteOrderButton />
            </div>
          </div>
        </div>

        <div className='top-row__group dates'>
          <span>{t('Created')}: <strong>{formatDate(order.created_at, order.country.code)}</strong></span>
          <span>{t('Edited')}: <strong>{formatDate(order.updated_at, order.country.code)}</strong></span>
        </div>

        <div className='top-row__group flex header-info-components'>
          <OrderAdvisorCTA />
          <OrderSecret />
          <OrderCustomerMeeting />
          <OrderCustomerVerified />
          <MemorialPageHeaderLink />
        </div>

        <div className='top-row__group flex header-info-components'>
          <OrderTags />
        </div>

        <div className='top-row__group flex header-info-components'>
          <DuplicateOrderLink />
          <MarkAsAvailable />
        </div>

        <QueueWarning />
        <div className='order-info-header information-box__container'>
          <OrderStatus
            percentageComplete={{ percentage: calculatePercentageComplete() }}
            order={order}
            orderHeader
          />
          <OrderInfoHeader />
        </div>
      </div>
    </div>
  );
}

export default OrderHeader;
