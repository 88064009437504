import React, { useEffect, useState } from 'react';
import { Input, Fieldset, LoadingButton, Toggle } from 'FormElements';
import Select from 'react-select';
import useUser from 'ReduxHooks/useUser';
import { useTranslation } from 'react-i18next';
import { If } from 'Conditionals';
import useCountries from 'ReduxHooks/useCountries';
import { Notice } from 'Layout';
import API from '../../stores/API';
import { showWarningNotice } from 'Library/notifications';

function UserEconomyTab () {
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [isConnectLoading, setIsConnectLoading] = useState(false);
  const [paymentMethodDescription, setPaymentMethodDescription] = useState('');
  const { user, paymentMethods, updateField, getPaymentMethods } = useUser();
  const { countrySettings, getCountrySettings } = useCountries();

  useEffect(() => {
    loadDependencies();
  }, []);

  useEffect(() => setLabel(), [paymentMethods, user.payment_method_id, isLoading]);

  const setLabel = () => {
    if (!paymentMethods || !user.payment_method_id || isLoading) {
      return;
    }

    const paymentMethod = paymentMethods.find(({ id }) => id === user.payment_method_id);

    if (!paymentMethod) {
      return;
    }

    setPaymentMethodDescription(paymentMethod.description);
  };

  function redirectToStripeConnect (user) {
    return async () => {
      setIsConnectLoading(true);
      const data = await API.GET(`/users/${user}/stripe-connect`);
      setIsConnectLoading(false);

      if (data.status !== 'ok') {
        showWarningNotice(`💔 ${data.errors.error_message}`);
        return;
      }

      window.location = data.url;
    };
  }

  const loadDependencies = async () => {
    setIsLoading(true);
    await getPaymentMethods(user.country_id);
    await getCountrySettings();
    setIsLoading(false);
  };

  const getSelectedPaymentMethod = () => {
    if (!paymentMethods || !user.payment_method_id || isLoading) {
      return null;
    }

    return paymentMethods.map(({ id, description }) => ({ value: id, label: description })).find(({ value }) => value === user.payment_method_id);
  };

  return (
    <div>
      {countrySettings && countrySettings.self_invoice_info &&
        <>
          <Notice type='info'>
            <h4>{t('Information about self invoice')}</h4>
            <p dangerouslySetInnerHTML={{ __html: countrySettings.self_invoice_info.replace('\n', '<br />') }} />
          </Notice>
          <br />
        </>}
      <If condition={user.has_stripe_connect}>
        <Fieldset legend={t('Stripe')}>
          <Toggle
            defaultChecked={user.has_therapy_vat}
            label={t('Has VAT on Therapy Orders')}
            desc={t('If this is selected the user will use the VAT set on the site for therapy orders.')}
            onChange={value => updateField('has_therapy_vat', value)}
          />

          <LoadingButton
            text={user.stripe_connect_account_id ? t('Reconnect your Stripe Account with Lavendla') : t('Connect your Stripe Account with Lavendla')}
            isLoading={isConnectLoading}
            className="white"
            onClick={redirectToStripeConnect(user.id)}
          /><br /><br />

          <If condition={user.has_stripe_connect}>
            <Input
              readOnly
              floatingLabel
              id="stripe_account_id"
              onChange={value => updateField('rating', value)}
              label={t('Stripe Account ID')}
              value={user.stripe_connect_account_id}
              type="text"
            />
          </If>
        </Fieldset>
      </If>
      <If condition={user.self_invoice}>
        <Fieldset legend={t('Company information')}>
          <Input
            floatingLabel
            id='company_name'
            onChange={value => updateField('company_name', value)}
            label={t('Company name')}
            value={user.company_name}
            type='text'
          />
          <Input
            floatingLabel
            id='company_registration_number'
            onChange={value => updateField('company_registration_number', value)}
            label={t('Company registration number')}
            value={user.company_registration_number}
            type='text'
          />
          <Input
            floatingLabel
            id='vat_identification_number'
            onChange={value => updateField('vat_identification_number', value)}
            label={t('VAT identification number')}
            value={user.vat_identification_number}
            type='text'
          />
        </Fieldset>
        <Fieldset legend={t('Invoice address')}>
          <Input
            floatingLabel
            id='invoice_address'
            onChange={value => updateField('invoice_address', value)}
            label={t('Address')}
            value={user.invoice_address}
            type='text'
          />
          <Input
            floatingLabel
            id='invoice_postcode'
            onChange={value => updateField('invoice_postcode', value)}
            label={t('Postcode')}
            value={user.invoice_postcode}
            type='text'
          />
          <Input
            floatingLabel
            id='invoice_city'
            onChange={value => updateField('invoice_city', value)}
            label={t('City')}
            value={user.invoice_city}
            type='text'
          />
        </Fieldset>
        <If condition={user.country.code !== 'global'}>
          <Fieldset legend={t('Payment settings')}>
            <div className='react-select__floating-label floating-label input has-content'>
              <Select
                id='payment_method_id'
                value={getSelectedPaymentMethod()}
                isLoading={isLoading}
                onChange={selected => updateField('payment_method_id', selected.value)}
                placeholder={isLoading ? t('-- loading --') : t('Select payment method')}
                options={paymentMethods ? paymentMethods.map(({ id, description }) => ({ value: id, label: description })) : []}
                className='lavendla-select'
                classNamePrefix='lavendla-select'
                isMulti={false}
                isClearable
              />
              <label htmlFor='payment_method_id'>{t('Payment method')}</label>
            </div>
            <If condition={user.payment_method_id}>
              <Input
                floatingLabel
                id='payment_method_value'
                onChange={value => updateField('payment_method_value', value)}
                label={paymentMethodDescription}
                value={user.payment_method_value}
                type='text'
              />
            </If>
          </Fieldset>
        </If>
      </If>
    </div>
  );
}

export default UserEconomyTab;
