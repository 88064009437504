import React, { useEffect, useState } from 'react';
import useUser from 'ReduxHooks/useUser';
import CompetenceAreaItem from 'Components/User/CompetenceContent/CompetenceAreaItem';
import CompetenceCategoryItem from 'Components/User/CompetenceContent/CompetenceCategoryItem';
import classNames from 'classnames';

const CompetenceTypeCategories = ({ competenceCategory, totalAmountOfCategories, updateCompetenceAreas }) => {
  const { user } = useUser();
  const [competenceAreas, setCompetenceAreas] = useState([]);
  const [isOpen, setIsOpen] = useState(totalAmountOfCategories === 1);
  const [allAreChecked, setAllAreChecked] = useState(false);
  const [numberOfItemsChecked, setNumberOfItemsChecked] = useState(0);

  useEffect(() => {
    const formattedItems = competenceCategory.competence_areas.map(competenceArea => {
      competenceArea.is_selected = user.competence_areas
        .filter(preSelectedCompetence => preSelectedCompetence.id === competenceArea.id)
        .length === 1;

      return competenceArea;
    });

    setCompetenceAreas(formattedItems);

    const checkedItems = formattedItems.filter(competenceArea => competenceArea.is_selected);
    setNumberOfItemsChecked(checkedItems.length);
    setAllAreChecked(checkedItems.length === formattedItems.length);
  }, []);

  const toggleAllCheckHandler = () => {
    const items = competenceAreas.map(competenceArea => {
      competenceArea.is_selected = ! allAreChecked;

      return competenceArea;
    });

    updateCompetenceAreaState(items);
  };

  const competenceAreaCheckHandler = (competenceArea) => {
    const items = competenceAreas.map(area => {
      if (competenceArea.id === area.id) {
        area.is_selected = ! area.is_selected;
      }

      return area;
    });

    updateCompetenceAreaState(items);
  };

  const updateCompetenceAreaState = (items) => {
    setCompetenceAreas(items);
    const checkedItems = items.filter(competenceArea => competenceArea.is_selected);

    updateCompetenceAreas(items, checkedItems);
    setNumberOfItemsChecked(checkedItems.length);
    setAllAreChecked(checkedItems.length === items.length);
  };

  return (
    <div
      className={'competence-type-category'}
      key={`competence_categories_${competenceCategory.category_id || 'none'}`}
    >
      <CompetenceCategoryItem
        competenceCategory={competenceCategory}
        numberOfItemsChecked={numberOfItemsChecked}
        toggleAllCheckHandler={toggleAllCheckHandler}
        allAreChecked={allAreChecked}
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />

      <div className={classNames('competence-type-category__competence-area-container', { hidden: ! isOpen })}>
          {competenceAreas
            .sort((a, b) => a.label.localeCompare(b.label))
            .map(competenceArea => (
            <CompetenceAreaItem
              key={`competence_area_${competenceArea.id}`}
              competenceArea={competenceArea}
              competenceAreaCheckHandler={competenceAreaCheckHandler}
            />
          ))}
      </div>
    </div>
  );
}

export default CompetenceTypeCategories;
