import API from '../../stores/API';

export const SET_LANGUAGES = 'SET_LANGUAGES';

function receiveLanguages (payload) {
  return {
    type: SET_LANGUAGES,
    payload
  };
}

export function getLanguages () {
  return async (dispatch, getState) => {
    const { languages } = getState();

    if (languages.languages.length) {
      return;
    }

    const { data } = await API.GET('/languages');
    return dispatch(receiveLanguages(data));
  };
}
