import React from 'react';
import CompetenceTypeCategories from './CompetenceTypeCategories';
import useUser from 'ReduxHooks/useUser';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import Spinner from 'Components/Spinner';

const CompetenceTypes = ({ type, competencesForType }) => {
  const { user, updateField } = useUser();

  const updateCompetenceAreas = (allCompetencesByCategory, selectedCompetencesByCategory) => {
    const allCompetenceIdsByCategory = allCompetencesByCategory.map((competenceArea) => competenceArea.id);

    // Remove all competences from this category,
    // and then add the selected items.
    const competenceAreas = [...user.competence_areas]
      .filter(competenceArea => allCompetenceIdsByCategory.indexOf(competenceArea.id) < 0)
      .concat(...selectedCompetencesByCategory);

    updateField('competence_areas', competenceAreas);
  };

  return (
    <div key={`type_${type}`}>
      {competencesForType.length === 0
        ? <Spinner loading />
        : <Tabs>
          <TabList>
            {competencesForType.map((competenceCategoryType) => (
              <Tab className='react-tabs__tab' key={`tab_${competenceCategoryType.category_type_id}_type_${type}`}>
                {competenceCategoryType.category_type_label}
              </Tab>
            ))}
          </TabList>
          {competencesForType.map((competenceCategoryType) => (
              <TabPanel key={`panel_${competenceCategoryType.category_type_id}_${competenceCategoryType.category_type_type}_type_${type}`}>
                <div className={'competence-type'}>
                  {competenceCategoryType.categories
                    .sort((a, b) => a.category_label.localeCompare(b.category_label))
                    .map((competenceCategory) => (
                      <CompetenceTypeCategories
                        key={`content_${competenceCategory.category_id}_panel_${competenceCategoryType.category_type_id}_${competenceCategoryType.category_type_type}_type_${type}`}
                        competenceCategory={competenceCategory}
                        totalAmountOfCategories={competenceCategoryType.categories.length}
                        updateCompetenceAreas={updateCompetenceAreas}
                      />
                    ))
                  }
                </div>
              </TabPanel>
            )
          )}
        </Tabs>
      }
    </div>
  );
}

export default CompetenceTypes;
