import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { formatUser } from 'Library/functions';
import Select from 'react-select';
import { LoadingButton } from 'FormElements/index';

function SelectUserModalContent ({ review, updateUser, cancel, users }) {
  const { t } = useTranslation();
  const [currentReview, setCurrentReview] = useState(review);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const handleChange = (user) => {
    setSelectedUser(user);

    const updatedReview = { ...currentReview, user_id: user?.value };

    setCurrentReview(updatedReview);
  };

  const dispatchUserUpdate = () => {
    updateUser(currentReview);
  };

  return (
    <div>
      <Select
        value={selectedUser}
        isClearable={true}
        className='lavendla-select'
        classNamePrefix='lavendla-select'
        options={users.map(formatUser)}
        placeholder={isLoading ? t('Loading') + ' ...' : t('Select user')}
        onChange={user => handleChange(user)}
      />

      <div className={'modal__footer'}>
        <div className={'btn-group review-requires-moderation-modal-content__buttons'}>
          <button onClick={cancel} className="btn btn-secondary">
            {t('Cancel')}
          </button>

          <LoadingButton
            isLoading={isLoading}
            onClick={dispatchUserUpdate}
          >
            {t('Update')}
          </LoadingButton>
        </div>
      </div>
    </div>
  );
}

SelectUserModalContent.propTypes = {
  review: PropTypes.object,
  updateUser: PropTypes.func,
  cancel: PropTypes.func,
};

export default SelectUserModalContent;
