import React from 'react';
import PropTypes from 'prop-types';
import { Input, Toggle, LoadingButton } from 'FormElements';
import { If } from 'Conditionals';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

const Certificates = ({ role, userProfile, updateUserProfileFields }) => {
  const { t } = useTranslation();
  const { certificates, id: userProfileId } = userProfile;

  const addRow = () => {
    let oldRows = certificates;

    if (!oldRows) {
      oldRows = [];
    }

    updateUserProfileFields('certificates', [...oldRows, { title: '', value: '', role, is_external: false, from: '', id: 'new' + Math.random(), user_profile_id: userProfileId }]);
  };

  const removeRow = id => updateUserProfileFields('certificates', certificates.filter((cert) => cert.id !== id));

  const setExternal = (id, value) => updateUserProfileFields('certificates', certificates.map((cert) => cert.id === id ? { ...cert, is_external: value } : cert));

  const updateCertificate = (id, field, value) => updateUserProfileFields('certificates', certificates.map((cert) => cert.id === id ? { ...cert, [field]: value } : cert));

  return (
    <div className='certificates__list'>
      <h3>{t('Certificates and attestations')}</h3>

      <AnimatePresence initial={false}>
        {certificates && Object.values(certificates).filter(certificate => certificate.role === role).map((certificate, index) => (
          <motion.div
            style={{ overflow: 'hidden' }}
            initial={{ height: 0 }}
            animate={{ height: 'auto' }}
            exit={{ height: 0 }}
            className='certificates__wrapper'
            key={certificate.id}
          >
            <div className='certificates__item'>
              <Input
                type='text'
                floatingLabel
                label={t('Title')}
                value={certificate.title}
                onChange={(value) => updateCertificate(certificate.id, 'title', value)}
              />
              <Input
                type='text'
                floatingLabel
                label={t('Info')}
                value={certificate.value}
                onChange={(value) => updateCertificate(certificate.id, 'value', value)}
              />
              <Toggle
                label={t('External course')}
                defaultChecked={!!certificate.is_external}
                onChange={(value) => setExternal(certificate.id, value)}
              />
              <If condition={certificate.is_external}>
                <Input
                  type='text'
                  className='certificates__external'
                  floatingLabel
                  label={t('From')}
                  value={certificate.from}
                  onChange={(value) => updateCertificate(certificate.id, 'from', value)}
                />
              </If>
              <LoadingButton
                className='certificates__remove small'
                text='x'
                onClick={() => removeRow(certificate.id)}
              />
            </div>
          </motion.div>
        ))}
      </AnimatePresence>
      <LoadingButton
        onClick={addRow}
        text={t('Add')}
      />
    </div>
  );
};

Certificates.propTypes = {
  role: PropTypes.string.isRequired,
  userProfile: PropTypes.object.isRequired,
  updateUserProfileFields: PropTypes.func.isRequired
};

export default Certificates;
