import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import CompetenceCategoryForm from './CompetenceCategoryForm';
import { useTranslation } from 'react-i18next';
import useCompetenceCategories from 'ReduxHooks/useCompetenceCategories';
import Spinner from 'Components/Spinner';

const CompetenceCategoryCreate = ({ match }) => {
  const { t } = useTranslation();
  const { fetchCompetenceCategory, competenceCategory } = useCompetenceCategories();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch(match.params.id);
  }, [match.params.id]);

  async function fetch (id) {
    setIsLoading(true);
    await fetchCompetenceCategory(id);
    setIsLoading(false);
  }

  return (
    <div className='competence-area'>
      <h2 className='page-title'>{t('Edit competence category')}</h2>

      {isLoading
        ? (<Spinner />)
        : (<CompetenceCategoryForm existingCompetenceCategory={competenceCategory} />)
      }
    </div>
  );
};

CompetenceCategoryCreate.propTypes = {
  match: PropTypes.object.isRequired
};

export default CompetenceCategoryCreate;
