import React, { useEffect, useState } from 'react';
import useCompetenceAreas from 'ReduxHooks/useCompetenceAreas';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { Link, useHistory } from 'react-router-dom';
import Spinner from '../../Spinner';
import { ALL_TYPES } from 'ReduxActions/competenceAreas';
import { useTranslation } from 'react-i18next';
import { formatDate } from 'Library/functions';
import { renderOrderType } from '../helpers';
import CompetenceHeader from 'Components/CompetenceAreas/CompetenceHeader';

function CompetenceAreaIndex ({ title}) {
  const history = useHistory();
  const { t } = useTranslation();
  const [orderType, setOrderType] = useState(ALL_TYPES);
  const [category, setCategory] = useState(0);
  const [categoryType, setCategoryType] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCompetenceAreas } = useCompetenceAreas();
  const [competenceAreas, setCompetenceAreas] = useState([]);
  const { loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [country, setCountry] = useState(userCountry);

  useEffect(() => {
    fetchData();
  }, [country]);

  async function fetchData () {
    setIsLoading(true);
    await fetchCompetenceAreas().then(setCompetenceAreas);
    setIsLoading(false);
  }

  const refreshFilters = ({ orderType, category, categoryType }) => {
    setOrderType(orderType);
    setCategory(category);
    setCategoryType(categoryType);
  }

  function filteredCompetenceAreas () {
    if (!competenceAreas) {
      return [];
    }

    let filteredCompetenceAreas = filterCompetenceAreasByOrderType(competenceAreas);
    filteredCompetenceAreas = filterCompetenceAreasByCategory(filteredCompetenceAreas);

    return filterCompetenceAreasByCategoryType(filteredCompetenceAreas);
  }

  const filterCompetenceAreasByOrderType = (competenceAreas) => {
    if (orderType === ALL_TYPES) {
      return competenceAreas;
    }

    return competenceAreas.filter(area => area.type === orderType);
  }

  const filterCompetenceAreasByCategory = (competenceAreas) => {
    const categoryValue = parseInt(category);
    if (!categoryValue) {
      return competenceAreas;
    }

    return competenceAreas.filter(area => area.competence_category_id === categoryValue);
  }

  const filterCompetenceAreasByCategoryType = (competenceAreas) => {
    const categoryTypeValue = parseInt(categoryType);
    if (!categoryTypeValue) {
      return competenceAreas;
    }

    return competenceAreas.filter(area => area.competence_category_type_id === categoryTypeValue);
  }

  return (
    <div>
      <CompetenceHeader
        type={'competence-areas'}
        title={title}
        onCreate={() => history.push('/kompetenser/skapa')}
        refreshFilters={refreshFilters}
      />

      {isLoading
        ? (<Spinner />)
        : (
          <table>
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th>{t('Order type')}</th>
                <th>{t('Category')}</th>
                <th>{t('Category type')}</th>
                <th>{t('Sort value')}</th>
                <th>{t('Hours per order')}</th>
                <th>{t('Translations')}</th>
                <th>{t('Updated')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredCompetenceAreas().map((categoryArea) => (
                <tr key={categoryArea.id}>
                  <td><Link to={`/kompetenser/${categoryArea.id}`}>{categoryArea.label || categoryArea.name}</Link></td>
                  <td>{renderOrderType(categoryArea.type)}</td>
                  <td>{categoryArea.competenceCategory?.label || '-'}</td>
                  <td>{categoryArea.competenceCategoryType?.type_label || '-'}</td>
                  <td>{categoryArea.sort_value}</td>
                  <td>{categoryArea.duration || ' - '}</td>
                  <td>{categoryArea.translations_count}</td>
                  <td>{formatDate(categoryArea.updated_at, userCountry.code)}</td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
    </div>
  );
}

export default CompetenceAreaIndex;
