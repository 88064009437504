import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { login, getLoggedInUser } from 'ReduxActions/users';
import Input from '../components/FormElements/Input';
import { AnimatePresence, motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import ReactSVG from 'react-svg';
import API from '../stores/API';
import {
  saveID,
  saveToken
} from 'Components/localstorage';
import Spinner from '../components/Spinner';
import If from 'Conditionals/If';

const LogIn = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const host = window && window.location && window.location.host;
  const apiOptions = {
    'stage.api.lavendla.com': 'https://stage.api.lavendla.com/v1',
    'stage-2.api.lavendla.com': 'https://stage-2.api.lavendla.com/v1',
    'api.lavendla.com': host.includes('localhost') ? 'https://api.lavendla.com/v1' : null,
    'api.lavendla.test': host.includes('localhost') ? 'https://api.lavendla.test/v1' : null
  };

  let defaultBackend = apiOptions['api.lavendla.com'];

  if (host.includes('localhost')) {
    defaultBackend = apiOptions['api.lavendla.test'];
  } else if (host.includes('netlify.app')) {
    defaultBackend = apiOptions['stage.api.lavendla.com'];
  }

  const [backend, setBackend] = useState(localStorage.getItem('backend') || defaultBackend);

  useEffect(() => {
    localStorage.setItem('backend', backend);
  }, [backend]);

  const handleBackendChange = (e) => {
    setBackend(e.target.value);
    localStorage.setItem('backend', e.target.value);
    window.location.reload();
  };

  const handleSubmit = async () => {
    // Extra check to make sure that we are on dev env.
    if (!host.includes('netlify.app') && !host.includes('localhost')) {
      return;
    }

    setError(null);
    setIsLoading(true);

    const response = await dispatch(login(email, password));
    setIsLoading(false);

    if (!response) {
      setError('Wrong username or password!');
    }
  };

  const isBtnDisabled = () => {
    if (isLoading) {
      return true;
    }

    return email === '' || password === '';
  };

  const googleLogin = useGoogleLogin({
    flow: 'implicit',
    scope: 'profile email openid https://www.googleapis.com/auth/calendar',
    onSuccess: async (codeResponse) => {
      setIsLoading(true);
      const response = await API.POST('/auth/google', {
        accessToken: codeResponse.access_token
      });

      saveID(response.user.id);
      saveToken(response.token);

      await dispatch(getLoggedInUser());
      setIsLoading(false);
    },
    onError: (errorResponse) => setError('Something went wrong. Try contacting helpdesk for more assistance.')
  });

  const motionProps = {
    initial: { opacity: 0, x: -25 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -25 }
  };

  return (
    <>
      <div className='login__wrapper'>
        <div className='logo'>
          <Link to='/'>
            <ReactSVG src='/icons/lavendla-logo.svg' />
          </Link>
        </div>
        <div className='login__content'>
          <>
            {error && (
              <AnimatePresence>
                <motion.div {...motionProps} className='inline-notice warning'>
                  <p>{error}</p>
                </motion.div>
              </AnimatePresence>
            )}

            {isLoading && <Spinner />}

            <If condition={!isLoading}>
              <div className='google-login'>
                <button onClick={(codeResponse) => googleLogin(codeResponse)}>
                  <ReactSVG src='/icons/google-logo.svg' />
                  <span>Sign in with Google</span>
                </button>
              </div>
            </If>

            <If condition={host.includes('netlify.app') || host.includes('localhost')}>
              <div className='backend-select'>
                <label htmlFor='backend'>Select API</label><br /><br />
                <select
                  data-cy='login-backend-select'
                  id='backend'
                  value={backend}
                  onChange={handleBackendChange}
                >
                  {Object.keys(apiOptions).map((key) => {
                    if (!apiOptions[key]) {
                      return null;
                    }

                    return <option key={key} value={apiOptions[key]}>{key}</option>;
                  })}
                </select>
              </div>
            </If>

            <If condition={host.includes('netlify.app') || host.includes('localhost')}>
              <form onSubmit={e => {
                e.preventDefault();
                handleSubmit();
              }}
              >
                <Input
                  data-cy='login-email'
                  label='E-mail'
                  type='email'
                  id='email'
                  value={email}
                  onChange={value => setEmail(value)}
                />

                <Input
                  data-cy='login-password'
                  label='Password'
                  type='password'
                  id='password'
                  value={password}
                  onChange={value => setPassword(value)}
                />

                <button
                  disabled={isBtnDisabled()}
                  className='btn'
                  type='submit'
                >
                  Log in
                </button>
              </form>
            </If>
          </>
        </div>
      </div>
    </>
  );
};

export default LogIn;
