import React, { useEffect, useState } from 'react';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Spinner';
import { formatDate } from 'Library/functions';
import useCompetenceCategories from 'ReduxHooks/useCompetenceCategories';
import { ALL_TYPES } from 'ReduxActions/competenceAreas';
import { renderOrderType } from '../helpers';
import CompetenceHeader from 'Components/CompetenceAreas/CompetenceHeader';
import useCompetenceCategoryTypes from 'ReduxHooks/useCompetenceCategoryTypes';

function CompetenceCategoryTypeIndex({ title }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCompetenceCategoryTypes, competenceCategoryTypes } = useCompetenceCategoryTypes();
  const { loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [country, setCountry] = useState(userCountry);

  useEffect(() => {
    init();
  }, [country]);

  async function init () {
    setIsLoading(true);
    await fetchCompetenceCategoryTypes();
    setIsLoading(false);
  }

  return (
    <div>
      <CompetenceHeader
        type={'competence-category-types'}
        title={title}
        onCreate={() => history.push('/competence-category-types/create')}
        showFilters={false}
      />

      {isLoading
        ? (<Spinner />)
        : (
          <table className='registry__registry-list__table'>
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th>{t('Type')}</th>
                <th>{t('Updated')}</th>
              </tr>
            </thead>
            <tbody>
              {competenceCategoryTypes
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((categoryType) => (
                  <tr key={categoryType.id}>
                    <td>
                      <Link to={`/competence-category-types/${categoryType.id}`}>
                        {categoryType.label}
                      </Link>
                    </td>
                    <td>{categoryType.type_label}</td>
                    <td>{formatDate(categoryType.updated_at, userCountry.code)}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          )}
    </div>
  );
}

export default CompetenceCategoryTypeIndex;
