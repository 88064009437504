import React from 'react';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { Checkbox, Input, Select, Textarea } from 'FormElements';
import OrderTab from './OrderTab';
import OrderAppointments from '../Appointments/OrderAppointments';
import OrderSupplierDropdown from '../OrderSupplierDropdown';
import useOrderProducts from 'ReduxHooks/useOrderProducts';
import { useTranslation } from 'react-i18next';

function EntombmentForm () {
  const {
    order,
    order: {
      entombment,
      funeral,
      deceased
    },
    updateField,
    updateOrderStatus,
    isFuneralOrder,
    persist,
    getCountryCode
  } = useSingleOrder();
  const { t } = useTranslation();
  const { toggleCoffinBearersProduct, showProductAddedNotice, showProductRemovedNotice } = useOrderProducts();
  const type = isFuneralOrder ? funeral.type : entombment.type;

  function updateEntombmentType (value) {
    if (!isFuneralOrder) {
      updateField('entombment.type', value);
      return;
    }

    updateField('funeral.type', value);

    if (getCountryCode() !== 'se') {
      return;
    }

    if (value !== 'jordbegravning' && orderHasCoffinBearers()) {
      removeCoffinBearers(value);
      return;
    }

    if (value === 'jordbegravning' && !orderHasCoffinBearers()) {
      addCoffinBearers(value);
    }
  }

  async function removeCoffinBearers (value) {
    await persist();
    await toggleCoffinBearersProduct(value);

    showProductRemovedNotice('Kistbärare');
  }

  async function addCoffinBearers (value) {
    await persist();
    await toggleCoffinBearersProduct(value);

    showProductAddedNotice('Kistbärare (×6)');
  }

  function orderHasCoffinBearers () {
    return !!order.products.find(({ slug }) => slug === 'kistbarare');
  }

  function getCremationSupplier () {
    if (!order.order) {
      return '';
    }

    const supplier = order.order.funeral.suppliers.find(({ type }) => type === 'krematorium');
    return (supplier) ? `${supplier.name} - ${supplier.email}, ${supplier.phone}` : '';
  }

  function getEntombmentOptions () {
    if (type === 'jordbegravning') {
      return {
        kistgrav: t('Coffin grave')
      };
    }

    return {
      minneslund: t('Memorial grove'),
      askgravlund: t('Ash grove', {
        description: 'Askgravlund'
      }),
      askgravplats: t('Ash grave', {
        description: 'Askgravplats'
      }),
      urn: t('Urn'),
      kolumbarium: t('Columbarium'),
      spridning_till_sjoss: t('Spread the ashes in the ocean')
    };
  }

  function showParticipationFields () {
    if (type === 'jordbegravning') {
      return true;
    }

    return entombment.entombment && !['spridning_till_sjoss', 'minneslund'].includes(entombment.entombment);
  }

  function showGraveyardFields () {
    if (type === 'jordbegravning') {
      return true;
    }

    return ['urn', 'kistgrav', 'kolumbarium'].includes(entombment.entombment);
  }

  function showCremationSupplier () {
    return ['direktkremation', 'akt_med_urna', 'kremation'].includes(type);
  }

  const inputProps = {
    className: 'col-4',
    floatingLabel: true
  };

  return (
    <OrderTab
      tab='entombment'
      header={t('Interment')}
      afterTasks={(
        <div className='row'>
          <Textarea
            onChange={value => updateField('entombment.note', value)}
            value={entombment.note}
            label={t('Other')}
            rows={10}
            className='col-12'
            data-cy='entombment.note'
            floatingLabel
          />
        </div>
      )}
    >
      <div className='row'>
        <Select
          label={t('Type')}
          onChange={value => updateEntombmentType(value)}
          value={isFuneralOrder ? funeral.type : entombment.type}
          defaultOption={t('-- Select --')}
          options={{
            direktkremation: t('Direct Cremation'),
            akt_med_urna: t('Funeral with urn', {
              description: 'Akt med urna'
            }),
            kremation: t('Cremation after ceremony'),
            jordbegravning: t('Burial')
          }}
          data-cy='entombment.type'
          {...inputProps}
        />
        <Input
          onChange={value => updateField(isFuneralOrder ? 'funeral.graveyard' : 'entombment.place', value)}
          label={t('Burial place')}
          value={isFuneralOrder ? funeral.graveyard : entombment.place}
          data-cy='entombment.place'
          {...inputProps}
        />
        <Select
          label={t('Burial type')}
          onChange={value => updateField('entombment.entombment', value)}
          value={entombment.entombment}
          defaultOption={t('-- Select --')}
          options={getEntombmentOptions()}
          data-cy='entombment.entombment'
          {...inputProps}
        />
      </div>

      {getCountryCode() === 'dk' ? (
        <div className='row'>
          <Input
            value={funeral.urn_collection}
            label={t('Who will collect the urn')}
            onChange={value => updateField('funeral.urn_collection', value)}
            data-cy="funeral.urn_collection"
            {...inputProps}
          />
        </div>
      ) : null}

      {showGraveyardFields() ? (
        <div className='row'>
          <Input
            onChange={value => updateField(isFuneralOrder ? 'funeral.grave_place' : 'entombment.graveyard', value)}
            label={t('Graveyard')}
            value={isFuneralOrder ? funeral.grave_place : entombment.graveyard}
            data-cy='entombment.graveyard'
            {...inputProps}
          />
          <Select
            label={t('Burial rights holder')}
            onChange={value => updateField('entombment.burial_rights_holder', value)}
            value={entombment.burial_rights_holder}
            defaultOption={t('-- Select --')}
            options={[deceased.name, t('Other')]}
            data-cy='entombment.burial_rights_holder'
            {...inputProps}
          />
        </div>
      ) : null}

      {showCremationSupplier() ? (
        <div className='row'>
          {isFuneralOrder ? (
            <>
              <OrderSupplierDropdown
                label={t('Crematorium')}
                type='krematorium'
                noValueLabel={t('-- No crematorium selected --')}
                className='col-8'
              />
              {type === 'direktkremation' ? (
                <Checkbox
                  className='col-4'
                  data-cy='mark-order-passed'
                  label={t('Mark the ceremony as passed')}
                  onChange={checked => updateOrderStatus(checked ? 'passed' : 'in_progress')}
                  checked={order.status === 'passed'}
                />
              ) : null}
            </>
          ) : (
            <Input
              label='Crematorium'
              onChange={() => {}}
              value={getCremationSupplier()}
              className='col-12'
              readOnly
              floatingLabel
            />
          )}
        </div>
      ) : null}

      {showParticipationFields() && (
        <>
          <div className='checkbox-grouped-container'>
            <Checkbox
              data-cy='entombment.participation'
              label={t('Relatives present')}
              defaultChecked={!!entombment.participation}
              onChange={(checked) => updateField('entombment.participation', checked)}
            />
          </div>

          <OrderAppointments
            header={t('Date/time when relatives will be present')}
            type='entombment'
            settings={{
              showMarkAsBusyToggle: true
            }}
          />
        </>
      )}
    </OrderTab>
  );
}

export default EntombmentForm;
