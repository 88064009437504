import React from 'react';
import PropTypes from 'prop-types';
import CompetenceCategoryForm from './CompetenceCategoryForm';
import { useTranslation } from 'react-i18next';

const CompetenceCategoryCreate = () => {
  const { t } = useTranslation();

  return (
    <div className='competence-area'>
      <h2 className='page-title'>{t('Create competence category')}</h2>

      <CompetenceCategoryForm />
    </div>
  );
};

CompetenceCategoryCreate.propTypes = {
  match: PropTypes.object.isRequired
};

export default CompetenceCategoryCreate;
