export default (features = {}) => {
  const formattedFeatures = Object.keys(features)
    .map(featureKey => ({ feature: featureKey, active: true }));

  return {
    id: null,
    name: '',
    competence_areas: [],
    email: '',
    employed_since: '',
    hide_from_stats: 0,
    has_stripe_connect: 0,
    remote_user: 0,
    self_invoice: 1,
    allow_phone_hours: 1,
    locations: [],
    user_features: formattedFeatures,
    locationsPriority: [],
    user_notifications: [],
    meta_fields: [],
    mobile_phone: null,
    no_schedule: 0,
    number_of_reviews: '',
    profile_image: '',
    rating: '',
    remember_token: null,
    default_role: null,
    roles: [],
    schedule: [],
    country: null,
    site_id: null,
    fixed_price_from: new Date(),
    supplier_number: null,
    countries: [],
    suppliers: [],
    tel: '',
    title: '',
    user_initials: '',
    username: ''
  };
};
