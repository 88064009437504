import { SET_COMPETENCE_CATEGORY_TYPES, SET_COMPETENCE_CATEGORY_TYPE } from '../actions/competenceCategoryTypes';

const initialState = {
  competenceCategoryTypes: [],
  competenceCategoryType: {
    category_type_id: null,
    type: null,
    label: null
  }
};

export default function competenceCategoryTypes (state = initialState, { type, payload }) {
  switch (type) {
    case SET_COMPETENCE_CATEGORY_TYPES: {
      return {
        ...state,
        competenceCategoryTypes: payload
      };
    }

    case SET_COMPETENCE_CATEGORY_TYPE: {
      return {
        ...state,
        competenceCategoryType: payload
      };
    }

    default:
      return state;
  }
}
