import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminOnly, If } from 'Conditionals';
import { Input, Toggle, Select, Fieldset } from 'FormElements';
import { Notice } from 'Layout';
import { getRoleLabel } from 'Library/UserRoles';
import useUser from 'ReduxHooks/useUser';
import CountryDropdown from 'Components/CountryDropdown';
import AutoCompleteAddressInput from '../AutoCompleteAddressInput';
import { AnimatePresence, motion } from 'framer-motion';
import { animateTop } from 'Library/motion';
import SiteDropdown from 'Components/SiteDropdown';
import FeatureFlagsFormElements from 'Components/User/FeatureFlagsFormElements';
import RolesDropdown from 'Components/RolesDropdown';
import TherapyUserFormElements from 'Components/User/TherapyUserFormElements';

function UserForm ({ isCreateMode }) {
  const { t } = useTranslation();
  const { user, updateField } = useUser();
  const [password, setPassword] = useState('');
  const [password2, setPassword2] = useState('');
  const [showMobilePhoneWarning, setShowMobilePhoneWarning] = useState(false);
  const [showPasswordWarning, setShowPasswordWarning] = useState(false);
  const host = window && window.location && window.location.host;

  useEffect(() => {
    setShowPasswordWarning(false);

    if (!password || !password2) {
      return;
    }

    if (password === password2) {
      updateField('password', password);
      return;
    }

    setShowPasswordWarning(true);
  }, [password, password2]);

  const onAddressSelect = fullAddress => {
    updateField('address', fullAddress);
  };

  const getDefaultAddress = () => {
    if (!user.address || !user.address.city) {
      return '';
    }

    const { address_1: addressOne, city, country } = user.address;

    return `${addressOne}, ${city}, ${country}`;
  };

  const updateMobilePhone = (value) => {
    const regEx = /^[0-9\s+]+$/;

    setShowMobilePhoneWarning(false);

    if (value && !regEx.test(value)) {
      setShowMobilePhoneWarning(true);
    }

    updateField('mobile_phone', value);
  };

  const updateCountry = (countries) => {
    const country = (countries.length > 0) ? countries[0] : null;

    updateField('country', country);
  };

  const getRolesOptions = () => {
    const roles = user ? user.roles : [];

    return roles.reduce((acc, role) => ({ ...acc, [role]: t(getRoleLabel(role)) }), {});
  };

  return (
    <>
      <Input
        id='name'
        label={t('Name')}
        floatingLabel
        value={user.name}
        onChange={value => updateField('name', value)}
        required={true}
        showRequiredIcon={true}
      />

      <Input
        id='phone'
        type='tel'
        label={t('Phone')}
        value={user.tel}
        floatingLabel
        onChange={value => updateField('tel', value)}
      />

      <If condition={isCreateMode}>
        <Input
          id='email'
          type='email'
          label={t('E-mail')}
          floatingLabel
          value={user.email}
          onChange={value => updateField('email', value)}
          required={true}
          showRequiredIcon={true}
        />
      </If>

      <Input
        type='tel'
        floatingLabel
        id='mobile_phone'
        label={t('Mobile phone (NOTE! Enter numbers only)')}
        value={user.mobile_phone}
        onChange={updateMobilePhone}
      />

      <If condition={showMobilePhoneWarning}>
        <Notice type='error'>
          <strong>{t('NOTE Invalid mobile phone number!')}</strong><br /><br />{t('We will not be able to send out SMS notifications to this number.')}<br /><br />{t('Only numbers and spaces are allowed, eg 0705 123 456.')}
        </Notice>
        <br /><br />
      </If>

      <AutoCompleteAddressInput
        address={getDefaultAddress()}
        onAdressSelect={onAddressSelect}
      />

      <If condition={isCreateMode}>
        <div className="react-select__floating-label floating-label input has-content">
          <CountryDropdown
            id="country-dropdown"
            selected={user.country}
            onChange={updateCountry}
            required={true}
            showRequiredIcon={true}
          />
          <label htmlFor="country-dropdown">{t('The home country of the user')}</label>
        </div>

        <If condition={!!user.country}>
          <div className="react-select__floating-label floating-label input has-content">
            <SiteDropdown
              id="site-dropdown"
              selected={user.site_id}
              country={user.country}
              onChange={site => updateField('site_id', site.value)}
            />
            <label htmlFor="site-dropdown">{t('The home site of the user')}</label>
          </div>
        </If>

        <If condition={!user.country}>
          <div className="react-select__floating-label floating-label input has-content">
            <Select
              options={[]}
              id={'placeholder-site-dropdown'}
              isDisabled={true}
              label={'The home site of the user'}
              floatingLabel
              className="lavendla-select"
              onChange={() => {
              }}
              disabledPlaceholder={t('Select the home country before selecting the home site')}
            ></Select>
          </div>
        </If>

        <div className="react-select__floating-label floating-label input has-content">
          <CountryDropdown
            isMulti
            isClearable
            id="countries-dropdown"
            selected={user.countries}
            onChange={countries => updateField('countries', countries)}
          />
          <label htmlFor="countries-dropdown">{t('Select active markets for the user')}</label>
        </div>

        <div className="react-select__floating-label floating-label input has-content">
          <RolesDropdown
            user={user}
            onChange={values => updateField('roles', values)}
          />
          <label htmlFor="role-select">{t('Roles')}</label>
        </div>
      </If>

      <If condition={isCreateMode}>
        <Select
          defaultOption={t('-- Select a default role --')}
          label={t('Default role')}
          value={user.default_role}
          options={getRolesOptions()}
          onChange={value => updateField('default_role', value)}
          className="lavendla-select location-dropdown"
          floatingLabel
          required={true}
          showRequiredIcon={true}
          isDisabled={user.roles.length === 0}
          disabledPlaceholder={t('Select roles before selecting the default role')}
        />

        <Toggle
          label={t('Accept remote orders')}
          defaultChecked={!!user.remote_user}
          onChange={() => updateField('remote_user', !user.remote_user)}
        />

        <Fieldset legend={t('User permissions')} className='user-admin__fieldset'>
          <Toggle
            label={t('Allow phone hours in schedule')}
            defaultChecked={true}
            onChange={() => updateField('allow_phone_hours', !user.allow_phone_hours)}
          />
          <Toggle
            label={t('Self invoice')}
            defaultChecked={true}
            onChange={() => updateField('self_invoice', !user.self_invoice)}
          />
        </Fieldset>
      </If>

      <If condition={isCreateMode}>
        <FeatureFlagsFormElements />
        <TherapyUserFormElements isCreateMode={true} />
      </If>

      <If condition={host.includes('netlify.app') || host.includes('localhost')}>
        <AdminOnly>
          <div className='grey-box password'>
            <Fieldset legend={user.id ? t('Change password') : t('Enter password')}>
              <Input
                floatingLabel
                label={user.id ? t('New password') : t('Password')}
                id='password1'
                type='password'
                value={password}
                onChange={value => setPassword(value)}
              />

              <Input
                floatingLabel
                label={t('Repeat password')}
                id='password2'
                type='password'
                value={password2}
                onChange={value => setPassword2(value)}
              />

              <AnimatePresence>
                <If condition={showPasswordWarning}>
                  <motion.div {...animateTop}>
                    <Notice type='error'>
                      {t('The passwords do not match!')}
                    </Notice>
                    <br />
                  </motion.div>
                </If>
              </AnimatePresence>
            </Fieldset>
          </div>
        </AdminOnly>
      </If>
    </>
  );
}

export default UserForm;
