import API from '../../stores/API';
import { showNotice, showWarningNotice } from 'Library/notifications';
import i18n from 'i18next';

export const SET_COMPETENCE_CATEGORIES = 'SET_COMPETENCE_CATEGORIES';
export const SET_COMPETENCE_CATEGORY = 'SET_COMPETENCE_CATEGORY';

function receiveCompetenceCategories (payload = null) {
  return {
    type: SET_COMPETENCE_CATEGORIES,
    payload
  };
}

function receiveCompetenceCategory (payload = null) {
  return {
    type: SET_COMPETENCE_CATEGORY,
    payload
  };
}

export function getCompetenceCategories () {
  return async (dispatch, getState) => {
    const { competenceCategories } = getState();

    if (competenceCategories.competenceCategories.length) {
      return;
    }

    const { data } = await API.GET('/competence-categories');

    return dispatch(receiveCompetenceCategories(data));
  };
}

export const forgetCompetenceCategories = () => {
  return async (dispatch) => {
    dispatch(receiveCompetenceCategories([]));
  };
};

export function getCompetenceCategory (id) {
  return async (dispatch) => {
    dispatch(receiveCompetenceCategory());

    const { data, status, errors } = await API.GET(`/competence-categories/${id}`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);

      return;
    }

    return dispatch(receiveCompetenceCategory(data));
  };
}

export function updateCompetenceCategory (id, payload) {
  return async (dispatch) => {
    const { data, status, errors } = await API.PUT(`/competence-categories/${id}`, payload);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);

      return;
    }

    showNotice(i18n.t('The category was updated'));

    return dispatch(receiveCompetenceCategory(data));
  };
}

export function createCompetenceCategory (params) {
  return async (dispatch) => {
    const { data, status, errors } = await API.POST('/competence-categories', params);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);

      return false;
    }

    showNotice(i18n.t('The category was created'));

    return dispatch(receiveCompetenceCategory(data));
  };
}

export function deleteCompetenceCategory (id) {
  return async (dispatch) => {
    const { status, errors } = await API.DELETE(`/competence-categories/${id}`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);

      return false;
    }

    showNotice(i18n.t('The category was deleted'));

    return dispatch(receiveCompetenceCategory(null));
  };
}
