import { caseTypeIsEstateOrInheritance } from '../values/caseTypeDefinitions';

export function getResouceRootUri (order) {
  if (!order) {
    return '';
  }

  switch (order.class) {
    case 'App\\LawyerOrder':
      return '/lawyer_orders';
    case 'App\\TombstoneOrder':
      return '/tombstone_orders';
    case 'App\\TherapyOrder':
      return '/therapy_orders';
    case 'App\\CeremonyOrder':
      return '/ceremony_orders';
    default:
      return '/orders';
  }
}

export function getOrderTypeArchiveUrl (order) {
  if (!order) {
    return '';
  }

  switch (order.class) {
    case 'App\\LawyerOrder':
      return '/juridik';
    case 'App\\TombstoneOrder':
      return '/gravstenar';
    case 'App\\TherapyOrder':
      return '/therapy';
    case 'App\\CeremonyOrder':
      return '/ceremonies';
    default:
      return '/ordrar';
  }
}

export function getResourceUrl (order) {
  switch (order.class) {
    case 'App\\LawyerOrder':
      return `/juridik/${order.id}/kund`;
    case 'App\\TombstoneOrder':
      return `/gravstenar/${order.id}/kund`;
    case 'App\\TherapyOrder':
      return `/therapy/${order.id}/kund`;
    case 'App\\CeremonyOrder':
      return `/ceremonies/${order.id}/kund`;
    default:
      return `/ordrar/order/${order.id}/kund`;
  }
}

export function getRequiredFields (order) {
  const requiredFields = {
    customer: [
      'first_name',
      'last_name',
      'email',
      'phone',
      'address_1',
      'city',
      'postcode'
    ],
    deceased: [
      'first_name',
      'last_name'
    ]
  };

  if (
    !['App\\LawyerOrder', 'App\\TherapyOrder', 'App\\CeremonyOrder'].includes(order.class) ||
    (order.class === 'App\\LawyerOrder' && caseTypeIsEstateOrInheritance(order.case_type_definition))
  ) {
    requiredFields.customer.push('relation_to_deceased');
  }

  if (order.class === 'App\\Order') {
    requiredFields.deceased.push(
      'parish',
      'own_clothes',
      'pick_up_date',
      'address',
      'postcode',
      'city',
      'date'
    );
  }

  return requiredFields;
}

export function isTabIncomplete (order, tab = 'customer') {
  return getRequiredFields(order)[tab].filter(key => !order[tab][key]).length > 0;
}
