import { useDispatch, useSelector } from 'react-redux';
import {
  getCompetenceArea,
  getCompetenceAreas,
  getGroupedCompetenceAreas,
  updateCompetenceArea,
  createCompetenceArea,
  deleteCompetenceArea
} from '../actions/competenceAreas';

function useCompetenceAreas () {
  const dispatch = useDispatch();
  const { competenceArea } = useSelector(({ competenceAreas }) => competenceAreas);

  function fetchCompetenceAreas (filters = {}) {
    return dispatch(getCompetenceAreas(filters));
  }

  function fetchGroupedCompetenceAreas (filters = {}) {
    return dispatch(getGroupedCompetenceAreas(filters));
  }

  function fetchCompetenceArea (id) {
    return dispatch(getCompetenceArea(id));
  }

  function persist (data) {
    return dispatch(updateCompetenceArea(data.id, data));
  }

  function create (data) {
    return dispatch(createCompetenceArea(data));
  }

  function destroy (id) {
    return dispatch(deleteCompetenceArea(id));
  }

  return {
    fetchCompetenceAreas,
    fetchGroupedCompetenceAreas,
    fetchCompetenceArea,
    persist,
    create,
    destroy,
    competenceArea
  };
}

export default useCompetenceAreas;
