import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Select, LoadingButton } from 'FormElements/index';
import { getCompetenceCategoriesAsOptions, getCompetenceCategoryTypesAsOptions, getOrderTypeOptions } from '../helpers';

// Redux
import useCompetenceCategories from 'ReduxHooks/useCompetenceCategories';
import ModelTranslations from 'Components/ModelTranslations/ModelTranslations';
import useCompetenceCategoryTypes from 'ReduxHooks/useCompetenceCategoryTypes';
import CompetenceCategoryModel from './CompetenceCategoryModel';
import Checkbox from 'FormElements/Checkbox';

function CompetenceCategoryForm ({ existingCompetenceCategory = null }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { persist, create, destroy, resetCompetenceCategories } = useCompetenceCategories();
  const { fetchCompetenceCategoryTypes, competenceCategoryTypes } = useCompetenceCategoryTypes();
  const [isLoading, setIsLoading] = useState(false);
  const [localCompetenceCategory, setUpdatedCompetenceCategory] = useState(existingCompetenceCategory || CompetenceCategoryModel());

  useEffect(() => {
    fetchCompetenceCategoryTypes();
  }, []);

  const isDisabled = () => {
    return parseInt(localCompetenceCategory.competence_area_type) === 0
      || parseInt(localCompetenceCategory.competence_category_type_id) === 0
      || (localCompetenceCategory.translations || []).filter((tr) => tr.value).length === 0;
  }

  async function onSubmit () {
    if (isDisabled()) {
      return;
    }

    setIsLoading(true);

    if (existingCompetenceCategory) {
      await update();
    } else {
      await save();
    }

    resetCompetenceCategories();
    redirectToArchives();
  }

  const update = async() => {
    await persist(localCompetenceCategory);
    setIsLoading(false);
  }

  const save = async() => {
    await create(localCompetenceCategory);
    setIsLoading(false);
  }

  async function onDelete () {
    if (!window.confirm(t('Are you sure you want to delete this category? This will detach all attached competence areas.'))) {
      return;
    }

    const response = await destroy(existingCompetenceCategory.id);

    if (response) {
      resetCompetenceCategories();
      redirectToArchives();
    }
  }

  function redirectToArchives () {
    setTimeout(() => {
      history.push('/competence-categories/');
    }, 1000);
  }

  return (
    <form className="order-tab__form">
      <div className="row">
        <Select
          label={t('Order type') + ' *'}
          data-cy="competence-category__competence_area_type"
          value={localCompetenceCategory.competence_area_type}
          className="col-8"
          required
          options={{
            0: ' -- ' + t('Select order type') + ' --',
            ...getOrderTypeOptions()
          }}
          onChange={areaType => setUpdatedCompetenceCategory({
            ...localCompetenceCategory,
            ...{ competence_area_type: areaType },
          })}
          floatingLabel
        />
      </div>
      <div className="row">
        <Select
          label={t('Category type') + ' *'}
          data-cy="competence-category__competence_category_type_id"
          value={localCompetenceCategory.competence_category_type_id}
          className="col-8"
          options={getCompetenceCategoryTypesAsOptions(competenceCategoryTypes)}
          onChange={id => setUpdatedCompetenceCategory({
            ...localCompetenceCategory,
            ...{ competence_category_type_id: id },
          })}
          floatingLabel
        />
      </div>

      <div className='row'>
        <div className='col-8'>
          <Checkbox
            label={t('Selectable in inquiry menus')}
            defaultChecked={!!localCompetenceCategory.selectable_in_inquiry_menus}
            onChange={value => setUpdatedCompetenceCategory({
              ...localCompetenceCategory,
              ...{ selectable_in_inquiry_menus: value },
            })}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <ModelTranslations
            model={localCompetenceCategory}
            property={'label'}
            onChange={translations => setUpdatedCompetenceCategory({
              ...localCompetenceCategory,
              ...{ translations },
            })}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <LoadingButton
            isLoading={isLoading}
            data-cy="competence-category__submit"
            disabled={isDisabled()}
            onClick={() => onSubmit()}
          >
            {existingCompetenceCategory ? t('Update') : t('Create')}
          </LoadingButton>
          {' '}
          {existingCompetenceCategory
            ? (
              <LoadingButton
                className="delete"
                data-cy="competence-category__delete"
                onClick={() => onDelete()}
              >
                {t('Delete')}
              </LoadingButton>
            )
            : null}
        </div>
      </div>
    </form>
  );
}

CompetenceCategoryForm.propTypes = {
  existingCompetenceCategory: PropTypes.object
};

export default CompetenceCategoryForm;
