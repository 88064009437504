import API from '../../stores/API';
import { showNotice, showWarningNotice } from 'Library/notifications';
import i18n from 'i18next';

export const SET_COMPETENCE_CATEGORY_TYPES = 'SET_COMPETENCE_CATEGORY_TYPES';
export const SET_COMPETENCE_CATEGORY_TYPE = 'SET_COMPETENCE_CATEGORY_TYPE';

function receiveCompetenceCategoryTypes (payload = null) {
  return {
    type: SET_COMPETENCE_CATEGORY_TYPES,
    payload
  };
}

function receiveCompetenceCategoryType (payload = null) {
  return {
    type: SET_COMPETENCE_CATEGORY_TYPE,
    payload
  };
}

export function getCompetenceCategoryTypes () {
  return async (dispatch, getState) => {
    const { competenceCategoryTypes } = getState();

    if (competenceCategoryTypes.competenceCategoryTypes.length) {
      return;
    }

    dispatch(reFetchCompetenceCategoryTypes());
  };
}

export const reFetchCompetenceCategoryTypes = () => {
  return async (dispatch) => {
    const { data } = await API.GET('/competence-category-types');

    dispatch(receiveCompetenceCategoryTypes(data));
  };
};

export function getCompetenceCategoryType (id) {
  return async (dispatch) => {
    dispatch(receiveCompetenceCategoryType());

    const { data, status, errors } = await API.GET(`/competence-category-types/${id}`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    return dispatch(receiveCompetenceCategoryType(data));
  };
}

export function updateCompetenceCategoryType ({ id, name, slug, type, duration, sort_value, translations }) { // eslint-disable-line camelcase
  return async (dispatch) => {
    const { data, status, errors } = await API.PUT(`/competence-category-types/${id}`, { name, slug, type, duration, sort_value, translations }); // eslint-disable-line camelcase

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return;
    }

    showNotice(i18n.t('The skill was updated'));
    return dispatch(receiveCompetenceCategoryType(data));
  };
}

export function createCompetenceCategoryType (params) {
  return async () => {
    const { data, status, errors } = await API.POST('/competence-category-types', params);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return false;
    }

    showNotice(i18n.t('The skill was created'));
    return data;
  };
}

export function deleteCompetenceCategoryType (id) {
  return async (dispatch) => {
    const { status, errors } = await API.DELETE(`/competence-category-types/${id}`);

    if (status !== 'ok') {
      showWarningNotice(`💔 ${errors.error_message}`);
      return false;
    }

    showNotice(i18n.t('The skill was deleted'));
    return dispatch(receiveCompetenceCategoryType(null));
  };
}
