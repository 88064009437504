import React, { useState } from 'react';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { LoadingButton, Input, Select } from 'FormElements';
import useAppointments from 'Hooks/useAppointments';
import useSessions from 'ReduxHooks/useSessions';
import DatePicker, { registerLocale } from 'react-datepicker';
import { sv, da } from 'date-fns/locale';
import { formatCurrency } from 'Library/functions';
import OrderTab from './OrderTab';
import { isBefore, isAfter, format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import SessionsTable from 'Components/PaymentComponents/SessionsTable';
import ExpensesTable from 'Components/PaymentComponents/ExpensesTable';
import ProductsTable from 'Components/PaymentComponents/ProductsTable';
import SupplierDropdown from 'Components/SupplierDropdown';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

registerLocale('se', sv);
registerLocale('dk', da);

function SessionsForm () {
  const { t } = useTranslation();
  const { createPayment } = useSessions();
  const [numberOfSessions, setNumberOfSessions] = useState(0);
  const [meetingLength, setMeetingLength] = useState(90);
  const [isCreating, setIsCreating] = useState(false);
  const [newDeadline, setNewDeadline] = useState(48);
  const [newSessions, setNewSessions] = useState([]);
  const [newExpenses, setNewExpenses] = useState([]);
  const { order, updateField, orderCountry } = useSingleOrder();
  const { getMeetingTypesForSelect } = useAppointments();
  const { isAdmin, loggedInUser } = useLoggedInUser();
  const { appointments, expenses } = order || {};
  const hasRequiredCompetence = loggedInUser.competence_areas.some(
    area => area.id === 316 || area.id === 315
  );

  const defaultAppointment = {
    id: null,
    user_id: order.user ? order.user.id : null,
    type: 'customer_meeting',
    title: 'video_meeting',
    start_date: null,
    meeting_length: 0,
    price: 0,
    description: '',
    internalId: null
  };

  const defaultExpense = {
    description: '',
    price: 0,
    type: 'expense'
  };

  const inquestExpense = {
    description: 'Utredning',
    price: 29950,
    type: 'inquest'
  };

  const disableSupplierDropdown = (index) => {
    return newSessions[index].title !== 'meeting_at_our_offices';
  };

  const noSupplierLabel = (index) => {
    if (disableSupplierDropdown(index)) {
      return t('Please select "Meeting at our offices" as the type of meeting');
    }

    return t('-- No meeting venue selected --');
  };

  const getDefaultPrice = (sessionlength) => {
    const userProfiles = order.user?.user_profiles.filter((profile) => profile.countryCode === 'se') || {};
    if (userProfiles.length === 0) {
      return 0;
    }

    const userProfile = userProfiles.length > 1 ? userProfiles.find((profile) => profile.site_id === order.site_id) || userProfiles[0] : userProfiles[0];
    if (!userProfile) {
      return 0;
    }

    const price = userProfile.metaFields.find((field) => field.key === `fee_${sessionlength}`);
    return price ? price.value : 0;
  };

  const addSessions = () => {
    defaultAppointment.price = getDefaultPrice(meetingLength);
    defaultAppointment.meeting_length = meetingLength || 0;

    const newSessionsToCreate = Array.from({ length: numberOfSessions }, () => ({ ...defaultAppointment }));
    setNewSessions(newSessionsToCreate);
    setNumberOfSessions(0);
  };

  const addExpense = (inquest = false) => {
    if (inquest) {
      setNewExpenses([
        ...newExpenses,
        { ...inquestExpense }
      ]);
      return;
    }

    setNewExpenses([
      ...newExpenses,
      { ...defaultExpense }
    ]);
  };

  const addNewSession = () => {
    defaultAppointment.price = getDefaultPrice(meetingLength);
    defaultAppointment.meeting_length = meetingLength || 0;

    setNewSessions([
      ...newSessions,
      { ...defaultAppointment }
    ]);
  };

  const updateSessionData = (index, field, value) => {
    const sessions = newSessions.map((session, i) => {
      if (i === index) {
        return {
          ...session,
          [field]: value
        };
      }

      return session;
    });

    setNewSessions(sessions);
  };

  const updateExpenseData = (index, field, value) => {
    const updatedExpenses = newExpenses.map((expense, i) => {
      if (i === index) {
        return {
          ...expense,
          [field]: value
        };
      }

      return expense;
    });

    setNewExpenses(updatedExpenses);
  };

  const deleteSessionData = (index) => {
    const sessions = newSessions.filter((_, i) => i !== index);
    setNewSessions(sessions);
  };

  const deleteExpenseData = (index) => {
    const updatedExpenses = newExpenses.filter((_, i) => i !== index);
    setNewExpenses(updatedExpenses);
  };

  const normalizeDate = (session) => {
    const parsedDate = session.start_date ? new Date(session.start_date) : null;
    const formattedDate = parsedDate ? format(parsedDate, 'yyyy-MM-dd HH:mm') : null;
    return {
      ...session,
      start_date: formattedDate
    };
  };

  const create = async () => {
    setIsCreating(true);
    const totalAmount = [
      ...newSessions,
      ...newExpenses
    ].reduce((acc, item) => acc + parseFloat(item.price || 0), 0);

    await createPayment({
      deadline: newDeadline,
      sessions: newSessions.map(normalizeDate),
      expenses: newExpenses,
      amount: totalAmount
    });

    setNewSessions([]);
    setNewExpenses([]);
    setIsCreating(false);
  };

  const getTotalPrice = () => {
    const total = [
      ...newSessions,
      ...newExpenses
    ].reduce((acc, item) => acc + parseFloat(item.price || 0), 0);
    return formatCurrency(total, null, order.countryCode, order?.currency);
  };

  const passedSessions = appointments.filter((appointment) => appointment.type === 'customer_meeting' && appointment.start_date && isBefore(new Date(appointment.start_date), new Date()));
  const upcomingSessions = appointments.filter((appointment) => appointment.type === 'customer_meeting' && (!appointment.start_date || isAfter(new Date(appointment.start_date), new Date())));

  return (
    <OrderTab
      tab='sessions'
      header={t('Sessions')}
    >
      <div className='sessions__wrapper'>
        <div id='customer-meeting-wrapper'>
          {passedSessions.length > 0 && (
            <>
              <h3>{t('Passed sessions')}</h3>
              <SessionsTable
                appointments={passedSessions}
                order={order}
                updateField={updateField}
                isPassed
              />
            </>
          )}
          {upcomingSessions.length > 0 && (
            <>
              <h3>{t('Upcoming sessions')}</h3>
              <SessionsTable
                appointments={upcomingSessions}
                order={order}
                updateField={updateField}
              />
            </>
          )}
          {expenses.length > 0 && (
            <>
              <h3>{t('Expenses')}</h3>
              <div className='table-wrapper'>
                <ExpensesTable
                  order={order}
                  updateField={updateField}
                />
              </div>
            </>
          )}
          {order.products.length > 0 && (
            <>
              <h3>{t('Old payments')}</h3>
              <div className='table-wrapper'>
                <ProductsTable
                  order={order}
                />
              </div>
            </>
          )}
        </div>
        <div className='sessions-creator__wrapper'>
          <h3>{t('Create new payment')}</h3>
          {newSessions.length > 0 || newExpenses.length > 0
            ? (
              <div className='table-wrapper'>
                <table className='sessions-creator table'>
                  {newSessions.length > 0 && (
                    <>
                      <thead className='table__head'>
                        <tr>
                          <th>{t('Date and time')}</th>
                          <th>{t('Meeting length')}</th>
                          <th>{t('Meeting type')}</th>
                          <th>{t('Place')}</th>
                          <th>{t('Price')}</th>
                          <th>{t('Actions')}</th>
                        </tr>
                      </thead>
                      <tbody className='table__body'>
                        {newSessions.map((appointment, index) => (
                          <tr key={index}>
                            <td>
                              <DatePicker
                                selected={appointment.start_date}
                                onChange={(date) => updateSessionData(index, 'start_date', date)}
                                showTimeSelect
                                timeFormat='HH:mm'
                                timeIntervals={15}
                                timeCaption='time'
                                dateFormat='yyyy-MM-dd HH:mm'
                                className='form-control'
                                placeholderText={t('Select date and time')}
                              />
                            </td>
                            <td className='small-cell'>
                              <Input
                                label={t('Session length')}
                                value={appointment.meeting_length}
                                onChange={(value) => updateSessionData(index, 'meeting_length', value)}
                                floatingLabel
                              />
                            </td>
                            <td>
                              <Select
                                label={t('Meeting type')}
                                id='meeting-type'
                                defaultOption={t('-- Select meeting type --')}
                                value={appointment.title}
                                options={getMeetingTypesForSelect()}
                                onChange={(value) => updateSessionData(index, 'title', value)}
                                floatingLabel
                              />
                            </td>
                            <td>
                              <SupplierDropdown
                                id='meeting-supplier-id'
                                type='moteslokal'
                                noValueLabel={noSupplierLabel(index)}
                                isDisabled={disableSupplierDropdown(index)}
                                supplier={appointment.supplier_id ? { id: appointment.supplier_id } : null}
                                onChange={(type, supplier) => updateSessionData(index, 'supplier_id', supplier?.id)}
                                showSelectedInfo={false}
                                country={orderCountry}
                              />
                            </td>
                            <td className='small-cell'>
                              <Input
                                label={t('Price')}
                                value={appointment.price}
                                onChange={(value) => updateSessionData(index, 'price', value)}
                                floatingLabel
                              />
                            </td>
                            <td className='smaller-cell'>
                              <LoadingButton
                                className='btn small red'
                                onClick={() => deleteSessionData(index)}
                              >
                                {t('Remove')}
                              </LoadingButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                  {newExpenses.length > 0 && (
                    <>
                      <thead className='table__head'>
                        <tr>
                          <th colSpan='3'>{t('Expense description')}</th>
                          <th>{t('Price')}</th>
                          <th>{t('Actions')}</th>
                        </tr>
                      </thead>
                      <tbody className='table__body'>
                        {newExpenses.map((expense, index) => (
                          <tr key={`expense-${index}`}>
                            <td colSpan='3'>
                              <Input
                                label={expense.type === 'inquest' ? t('Description') : t('Expense description')}
                                value={expense.description}
                                onChange={(value) => updateExpenseData(index, 'description', value)}
                                floatingLabel
                              />
                            </td>
                            <td>
                              <Input
                                label={t('Price')}
                                value={expense.price}
                                onChange={(value) => updateExpenseData(index, 'price', value)}
                                floatingLabel
                              />
                            </td>
                            <td className='smaller-cell'>
                              <LoadingButton
                                className='btn small red'
                                onClick={() => deleteExpenseData(index)}
                              >
                                {t('Remove')}
                              </LoadingButton>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </>
                  )}
                  <tbody className='table__body'>
                    {!newExpenses.some(expense => expense.type === 'inquest')
                      ? (
                        <tr>
                          <td colSpan='7'>
                            <div className='sessions-creator__add-actions'>
                              <LoadingButton
                                className='btn white'
                                onClick={() => addNewSession()}
                              >
                                {t('+ session')}
                              </LoadingButton>
                            </div>
                          </td>
                        </tr>
                        )
                      : (
                        <tr />
                        )}
                    <tr>
                      <td colSpan='2'>
                        <Select
                          label={t('Select payment deadline')}
                          value={newDeadline}
                          onChange={paymentDeadline => setNewDeadline(paymentDeadline)}
                          options={{
                            1: t('1 hour'),
                            3: t('3 hours'),
                            5: t('5 hours'),
                            10: t('10 hours'),
                            24: t('1 day'),
                            48: t('2 days'),
                            72: t('3 days'),
                            96: t('4 days')
                          }}
                          floatingLabel
                        />
                      </td>
                      <td colSpan='5'>
                        <span className='sessions-creator__total'><strong>{t('Total')}</strong>: {getTotalPrice()}</span>
                        <LoadingButton
                          className='btn cta'
                          isLoading={isCreating}
                          onClick={() => create()}
                        >
                          {t('Create payment request')}
                        </LoadingButton>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              )
            : (
              <div className='sessions-creator__start-form'>
                <div className='sessions-creator__input sessions-creator__input--number'>
                  <Input
                    label={t('Number of sessions')}
                    type='number'
                    min={0}
                    value={numberOfSessions}
                    onChange={(value) => setNumberOfSessions(value)}
                    floatingLabel
                  />
                </div>
                <div className='sessions-creator__input'>
                  <Select
                    label={t('Session length')}
                    value={meetingLength}
                    onChange={(value) => setMeetingLength(value)}
                    options={{
                      45: '45 min',
                      60: '60 min',
                      90: '90 min',
                      '': t('Custom')
                    }}
                    floatingLabel
                  />
                </div>
                <div className='sessions-creator__input'>
                  <LoadingButton
                    className='btn btn-cta'
                    onClick={() => addSessions()}
                    disabled={numberOfSessions < 1}
                  >
                    {t('Add')}
                  </LoadingButton>
                </div>
              </div>
              )}

          {newExpenses.length == 0 && (hasRequiredCompetence || isAdmin) && (
            <div className='sessions-creator__wrapper'>
              <h3>{t('Create a payment request with products')}</h3>
              <LoadingButton
                className='btn white'
                onClick={() => addExpense(true)}
              >
                {t('Add inquest')}
              </LoadingButton>
            </div>
          )}
        </div>
      </div>
    </OrderTab>
  );
}

export default SessionsForm;
