import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { formatAsOption, generateId } from 'Library/functions';
import { TYPE_LEGAL } from 'ReduxActions/competenceAreas';
import useCompetenceAreas from 'ReduxHooks/useCompetenceAreas';
import { useTranslation } from 'react-i18next';

const formatAsCompetenceAreaOption = (competenceArea) => {
  return formatAsOption({
    name: competenceArea.label,
    id: competenceArea.id,
  })
};

const CompetenceAreaDropdown = ({
  countryId = null,
  id = generateId(),
  selected = [],
  onChange = null,
  isMulti = false,
  type = TYPE_LEGAL,
  placeholder = null,
  ...rest
}) => {
  const { t } = useTranslation();
  const { fetchCompetenceAreas } = useCompetenceAreas();
  const [selectedAreas, setSelectedAreas] = useState(selected.map(formatAsCompetenceAreaOption));
  const [isLoading, setIsLoading] = useState(false);
  const [competenceAreas, setCompetenceAreas] = useState([]);

  useEffect(() => {
    async function fetchData() {
      setIsLoading(true);
      await fetchCompetenceAreas({ countryId, type }).then(setCompetenceAreas);
      setIsLoading(false);
    }

    fetchData();
  }, [countryId, type]);

  function normalizeValue(data) {
    if (!data) {
      return [];
    }

    if (!Array.isArray(data)) {
      data = [data];
    }

    const ids = data.map(({ value }) => value);

    return competenceAreas.filter(({ id }) => ids.includes(id));
  }

  const isDisabled = () => {
    return !countryId || isLoading;
  };

  const placeholderText = () => {
    if (!countryId) {
      return t('Select a country first');
    }

    return isLoading ? t('Loading skills...') : placeholder || (isMulti ? t('-- Choose skills --') : t('-- Choose skill --'));
  };

  const update = value => {
    setSelectedAreas(value);

    if (onChange) {
      onChange(normalizeValue(value));
    }
  };

  function getOptions() {
    return [...competenceAreas]
      .filter((competenceArea) => competenceArea.label)
      .map(formatAsCompetenceAreaOption);
  }

  return (
    <Select
      id={id}
      options={getOptions()}
      value={selectedAreas}
      onChange={update}
      isMulti={isMulti}
      isDisabled={isDisabled()}
      placeholder={placeholderText()}
      className='lavendla-select'
      classNamePrefix='lavendla-select'
      isClearable
      {...rest}
    />
  );
};

export default CompetenceAreaDropdown;
