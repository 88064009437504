import React, { useEffect, useState } from 'react';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { Link, useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Spinner from '../../Spinner';
import { formatDate } from 'Library/functions';
import useCompetenceCategories from 'ReduxHooks/useCompetenceCategories';
import { ALL_TYPES } from 'ReduxActions/competenceAreas';
import { renderOrderType } from '../helpers';
import CompetenceHeader from 'Components/CompetenceAreas/CompetenceHeader';

function CompetenceCategoryIndex({ title }) {
  const history = useHistory();
  const { t } = useTranslation();
  const [orderType, setOrderType] = useState(ALL_TYPES);
  const [categoryType, setCategoryType] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { fetchCompetenceCategories, competenceCategories } = useCompetenceCategories();
  const { loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [country, setCountry] = useState(userCountry);

  useEffect(() => {
    init();
  }, [country]);

  async function init () {
    setIsLoading(true);
    await fetchCompetenceCategories();
    setIsLoading(false);
  }

  const refreshFilters = ({ orderType, categoryType }) => {
    setOrderType(orderType);
    setCategoryType(categoryType);
  }

  function filteredCompetenceCategories () {
    if (!competenceCategories) {
      return [];
    }

    const filteredCompetenceCategories = filterCompetenceAreasByOrderType(competenceCategories);

    return filterCompetenceAreasByCategoryType(filteredCompetenceCategories);
  }

  const filterCompetenceAreasByOrderType = (competenceCategories) => {
    if (orderType === ALL_TYPES) {
      return competenceCategories;
    }

    return competenceCategories.filter(category => category.competence_area_type === orderType);
  }

  const filterCompetenceAreasByCategoryType = (competenceCategories) => {
    const categoryTypeValue = parseInt(categoryType);
    if (!categoryTypeValue) {
      return competenceCategories;
    }

    return competenceCategories.filter(category => category.competence_category_type_id === categoryTypeValue);
  }

  return (
    <div>
      <CompetenceHeader
        type={'competence-categories'}
        title={title}
        onCreate={() => history.push('/competence-categories/create')}
        refreshFilters={refreshFilters}
      />

      {isLoading
        ? (<Spinner />)
        : (
          <table>
            <thead>
              <tr>
                <th>{t('Name')}</th>
                <th>{t('Order type')}</th>
                <th>{t('Category type')}</th>
                <th>{t('Updated')}</th>
              </tr>
            </thead>
            <tbody>
              {filteredCompetenceCategories()
                .filter(item => item.label)
                .sort((a, b) => a.label.localeCompare(b.label))
                .map((competenceCategory) => (
                  <tr key={competenceCategory.id}>
                    <td>
                      <Link to={`/competence-categories/${competenceCategory.id}`}>
                        {competenceCategory.label}
                      </Link>
                    </td>
                    <td>{renderOrderType(competenceCategory.competence_area_type)}</td>
                    <td>{competenceCategory.competenceCategoryType?.type_label || '-'}</td>
                    <td>{formatDate(competenceCategory.updated_at, userCountry.code)}</td>
                  </tr>
                )
              )}
            </tbody>
          </table>
          )}
    </div>
  );
}

export default CompetenceCategoryIndex;
