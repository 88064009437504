import React, { useEffect, useState } from 'react';
import { Table } from 'Layout/index';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import sprintf from 'locutus/php/strings/sprintf';
import useLanguages from 'ReduxHooks/useLanguages';
import ModelTranslation from 'Components/ModelTranslations/ModelTranslation';
import If from 'Conditionals/If';
import useInitialRenderEffect from 'Hooks/useInitialRenderEffect';
import TranslationModel from 'Components/ModelTranslations/TranslationModel';
import { getNotificationText } from 'Library/NotificationHelper';

const ModelTranslations = ({ model, property, onChange, isEditing = true }) => {
  const { t } = useTranslation();
  const { fetchLanguages, languages } = useLanguages();
  const [selectedModel, setSelectedModel] = useState(model);

  // The translations require the model to have been fully initialized
  const [hasRendered, setHasRendered] = useState(false);
  useInitialRenderEffect(() => setHasRendered(true));

  useEffect(() => {
    if (hasRendered) {
      if (languages.length === 0) {
        fetchLanguages();
      }

      if (languages.length > 0) {
        initiateTranslations();
      }
    }
  }, [hasRendered, languages]);

  const getTranslation = (languageCode) => {
    return (selectedModel.translations || [])
      .find((translation) => translation.property === property && translation.language === languageCode)
      || TranslationModel(property, languageCode);
  };

  const initiateTranslations = async () => {
    const translations = (languages || []).map((language) => getTranslation(language.value));

    setChanges(translations);
  }

  const handleTranslationChange = (updatedTranslation, language) => {
    const translations = [...(selectedModel.translations || [])];

    const thisTranslation = translations.find((translation) => {
      return translation.property === updatedTranslation.property
        && translation.language === language.value;
    });

    if (!thisTranslation) {
      translations.push(updatedTranslation);
    }

    const updatedTranslations = translations.map((translation) => {
      if (translation.property === updatedTranslation.property && translation.language === language.value) {
        translation = updatedTranslation;
      }

      return translation;
    });

    setChanges(updatedTranslations);
  };

  const setChanges = (updatedTranslations) => {
    const updatedModel = { ...selectedModel, translations: updatedTranslations };

    setSelectedModel(updatedModel);

    onChange(updatedTranslations);
  };

  return (
    <>
      <If condition={hasRendered}>
        <div>
          <hr/>
          <h2>{t('Translations')}</h2>
          {(languages || []) && (
            <Table thead={[t('Active') + ' *', t('Language'), t('Value')]}>
              {(languages || []).map((language) => (
                <ModelTranslation
                  key={`language_for_${property}_${language.value}`}
                  translation={getTranslation(language.value)}
                  property={property}
                  language={language}
                  isEditing={isEditing}
                  onChange={translation => handleTranslationChange(translation, language)}
                />
              ))}
            </Table>
          )}

          <div style={{ marginTop: '1em', fontStyle: 'italic', fontSize: '0.9em' }}>
            {t('* Inactive translations are not visible on public websites.')}
          </div>
        </div>
      </If>
      <If condition={!hasRendered}>
        <span>Not renderewd</span>
      </If>
    </>
  );
}

ModelTranslations.propTypes = {
  model: PropTypes.object,
  property: PropTypes.string,
  onChange: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default ModelTranslations;
