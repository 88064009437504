import React, { useState } from 'react';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import Select from 'react-select';
import Editor from '../Editor';
import TaskFiles from './TaskFiles';
import AttachFileToTask from '../AttachFileToTask';
import TaskEmailWarnings from './TaskEmailWarnings';
import useTaskEmail from '../../hooks/useTaskEmail';
import useTaskActions from '../../hooks/useTaskActions';
import EmailBodySavedAsDraftWarning from '../EmailBodySavedAsDraftWarning';
import { If } from 'Conditionals';
import { Input, Textarea, LoadingButton, RadioButtons } from 'FormElements';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import UserDropdown from '../UserDropdown';
import { animateTop } from 'Library/motion';
import { AnimatePresence, motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { formatCurrency, getTranslatedStripeStatus } from 'Library/functions';
import { showNotice } from 'Library/notifications';

import {
  canSendInvoiceTask,
  canSendTransportTask,
  isCreateLawyerOrderTask, isCreateTherapyOrderTask,
  isCreateTombstoneOrderTask,
  isTransportTask,
  isOfferTask,
  canSendEmailsToCustomerContacts,
  isPaymentTask,
  isInvoiceTask,
} from 'Library/Tasks';
import { Notice } from 'Layout/index';

function TaskModal ({ task, closeModal }) {
  const {
    values,
    setValue,
    restoreDefaultValues,
    hasChangedValues,
    getFileUploadHeader,
    showUserSelectCheckbox,
    getDefaultRecipient,
    getOrderCustomerContactEmailRecipients,
    customerContactEmailTaskHasRecipients
  } = useTaskEmail(task);

  const {
    createCaseTypeOrder,
    generateCoffinLabelPreview,
    sendEmail,
    saveEmail
  } = useTaskActions(task);


  const { t } = useTranslation();
  const { order, isTherapyOrder } = useSingleOrder();
  const [files, setFiles] = useState([]);
  const [offerFiles, setOfferFiles] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [isLoadingCoffinLabel, setIsLoadingCoffinLabel] = useState(false);
  const isCreateCaseOrderTask = isCreateLawyerOrderTask(task) || isCreateTombstoneOrderTask(task) || isCreateTherapyOrderTask(task);
  const canSendEmailToCustomerContacts = canSendEmailsToCustomerContacts(task);
  const customerContactEmailRecipientOptions = getOrderCustomerContactEmailRecipients();

  const showOfferDeadlineSelect = isOfferTask(task) && isTherapyOrder;

  const showPaymentSelect = isPaymentTask(task) && isTherapyOrder;

  const getSendText = () => {
    if (isCreateLawyerOrderTask(task)) {
      return t('Create case');
    }

    if (isCreateTombstoneOrderTask(task)) {
      return t('Create tombstone order');
    }

    if (isCreateTherapyOrderTask(task)) {
      return t('Create therapy order');
    }

    return t('Send');
  };

  const generateCoffinLabel = async () => {
    setIsLoadingCoffinLabel(true);
    await generateCoffinLabelPreview();
    setIsLoadingCoffinLabel(false);
  };

  const deadlines = [
    { value: '1', label: t('1 hour') },
    { value: '3', label: t('3 hours') },
    { value: '5', label: t('5 hours') },
    { value: '10', label: t('10 hours') },
    { value: '24', label: t('1 day') },
    { value: '48', label: t('2 days') },
    { value: '72', label: t('3 days') },
    { value: '96', label: t('4 days') }
  ];

  const responsibleForPaymentOptions = () => {
    const orderSuppliers = (order.suppliers || []).filter(
      supplier => supplier.can_be_billable_for_order_invoices
    );

    if (!isInvoiceTask(task) || orderSuppliers.length === 0) {
      return [];
    }

    let customerOptions = [
      {
        value: 'customer_id',
        label: t('Customer'),
        resource_type: 'App\\Customer',
        resource_id: order.customer_id
      },
    ];

    let supplierOptions = orderSuppliers.map((supplier) => {
      return {
        value: `supplier_id_${supplier.id}`,
        label: supplier.name,
        resource_type: 'App\\Supplier',
        resource_id: supplier.id,
        order_invoice_description: supplier.order_invoice_description
      };
    });

    return customerOptions.concat(supplierOptions);
  };

  const getDeadlineSelectValue = (value) => {
    return deadlines.find(deadline => deadline.value === value);
  };

  const getPaymentSelectValue = (value) => {
    return getPaymentSelectOptions().find(payment => payment.value === value);
  };

  const getPaymentSelectOptions = () => {
    if (!order.stripePayments) {
      return [];
    }

    return order.stripePayments.map(payment => {
      const price = formatCurrency(payment.amount, null, order.countryCode, order?.currency);
      return {
        value: payment.id,
        label: `#${payment.offer_id} - ${price} - ${new Date(payment.created_at).toLocaleDateString()} - ${getTranslatedStripeStatus(payment.status)}`
      };
    });
  };

  const send = async () => {
    let response;
    setIsLoading(true);

    if (showPaymentSelect && !values.payment_id) {
      setIsLoading(false);
      showNotice(t('Please choose a payment to attach to the email.'));
      return;
    }

    if (isCreateCaseOrderTask) {
      response = await createCaseTypeOrder(values, files);
    } else {
      response = await sendEmail(values, files, offerFiles);
    }

    setIsLoading(false);

    if (response) {
      closeModal();
    }
  };

  const save = async () => {
    const data = { ...values };

    // Don't save the to address for certain tasks
    if (!['Underlag borgerlig officiant', 'Information till representant'].includes(task.name)) {
      data.recipient = getDefaultRecipient();
    }

    setIsSaving(true);
    await saveEmail(data);
    setIsSaving(false);
  };

  const motionProps = {
    initial: { opacity: 0, x: -25 },
    animate: { opacity: 1, x: 0 },
    exit: { opacity: 0, x: -25 }
  };

  function isDisabled () {
    if (!values.recipient
      && (!customerContactEmailTaskHasRecipients() || !values.customerContactRecipients?.length)
    ) {
      return true;
    }

    if (!canSendTransportTask(order, task)) {
      return true;
    }

    if (!canSendInvoiceTask(order, task)) {
      return true;
    }

    return false;
  }

  function getCaseCreatorChoices () {
    const options = [];

    if (showUserSelectCheckbox()) {
      options.push({
        id: '1',
        label: t('Assign the order to yourself'),
        description: isCreateTombstoneOrderTask(task)
          ? t('Average commission: 1850 kr including VAT (based on 2h administration + commission)')
          : isCreateTherapyOrderTask(task)
            ? ''
            : t('Average commission: 7,700 kr including VAT (based on current average order 11,000 kr)')
      });
    }

    options.push({
      id: '0',
      label: isCreateTombstoneOrderTask(task)
        ? t('Assign the order to another user')
        : isCreateTherapyOrderTask(task)
          ? t('Assign the order to another therapist')
          : t('Assign the order to another lawyer'),
      description: isCreateTombstoneOrderTask(task)
        ? t('Provision after invoicing: gravestone 300 kr including VAT, completion 200 kr including VAT')
        : isCreateTherapyOrderTask(task)
          ? ''
          : t('Provision 400 kr including VAT after invoicing')
    });

    return options;
  }

  function updateCaseCreatorChoice (choice) {
    if (choice === '1') {
      setValue({
        assign_to_self: true,
        user_id: null
      });
    } else {
      setValue({ assign_to_self: false });
    }
  }

  return (
    <>
      <button className='close-modal' onClick={closeModal}>
        <ReactSVG src='/icons/icon-close-black.svg' />
      </button>
      <div className='modal-container'>
        <div>
          <h3>{task.name}</h3>
          <form action='/' key={task.id}>
            {isCreateCaseOrderTask ? (
              <div className='mail-input'>
                <label>{t('Write a note to the case')}</label>
                <div className='editor-textarea'>
                  <Textarea
                    rows={7}
                    value={values.note}
                    onChange={note => setValue({ note })}
                  />
                </div>
              </div>
            ) : (
              <>
                <If condition={canSendEmailToCustomerContacts}>
                  <div className='mail-input'>
                    <label>{t('Customer and contacts:')}</label>
                    <Select
                      value={values.customerContactRecipients}
                      onChange={customerContactRecipients => setValue({ customerContactRecipients })}
                      className='select'
                      options={customerContactEmailRecipientOptions}
                      placeholder={t('Select email recipients')}
                      isMulti
                    />
                  </div>

                  <div className='mail-input'>
                    <Input
                      data-cy='task-email.recipient'
                      value={values.recipient}
                      label={t('Additional recipients:')}
                      placeholder={t('Separate email addresses by comma')}
                      description={t('Enter multiple email addresses separated by commas.')}
                      onChange={recipient => setValue({ recipient })}
                    />
                  </div>
                </If>
                <If condition={!canSendEmailToCustomerContacts}>
                  <div className='mail-input'>
                    <Input
                      data-cy='task-email.recipient'
                      value={values.recipient}
                      label={t('Email recipient:')}
                      description={t('Enter multiple email addresses separated by commas.')}
                      onChange={recipient => setValue({ recipient })}
                    />
                  </div>
                </If>
                <div className='mail-input'>
                  <Input
                    data-cy='task-email.reply'
                    value={values.replyto}
                    label={t('Reply to:')}
                    onChange={replyto => setValue({ replyto })}
                  />
                </div>
                <If condition={showOfferDeadlineSelect}>
                  <div className='mail-input'>
                    <label>{t('Offer deadline:')}</label>
                    <Select
                      value={getDeadlineSelectValue(values.payment_deadline)}
                      onChange={paymentDeadline => setValue({ payment_deadline: paymentDeadline.value })}
                      className='select'
                      options={deadlines}
                      placeholder={t('Select offer deadline')}
                    />
                  </div>
                </If>
                <If condition={responsibleForPaymentOptions().length > 0}>
                  <div className='mail-input'>
                    <label>{t('Responsible for payment:')}</label>
                    <Select
                      onChange={item => setValue({
                        responsible_for_payment_resource_type: item.resource_type,
                        responsible_for_payment_resource_id: item.resource_id,
                        responsible_for_payment_order_invoice_description: item.order_invoice_description,
                      })}
                      className='select'
                      options={responsibleForPaymentOptions()}
                      placeholder={t('Select invoice recipient')}
                    />

                    <If condition={values.responsible_for_payment_order_invoice_description}>
                      <Notice type='warning'>
                        <p>{values.responsible_for_payment_order_invoice_description}</p>
                      </Notice>
                    </If>
                  </div>
                </If>
                <If condition={showPaymentSelect}>
                  <div className='mail-input'>
                    <label>{t('Payment to attatch to the email:')}</label>
                    <Select
                      value={getPaymentSelectValue(values.payment_id)}
                      onChange={payment => setValue({ payment_id: payment.value })}
                      className='select'
                      options={getPaymentSelectOptions()}
                      placeholder={t('Select payment')}
                    />
                  </div>
                </If>
                <div className='mail-input'>
                  <Input
                    data-cy='task-email.subject'
                    value={values.subject}
                    label={t('Subject:')}
                    onChange={subject => setValue({ subject })}
                  />
                </div>
                <div className='mail-input'>
                  <div className='editor-textarea'>
                    <Editor
                      data={values.body}
                      onChange={body => setValue({ body })}
                    />
                  </div>
                </div>
                <EmailBodySavedAsDraftWarning
                  motionProps={motionProps}
                  canResetEmail={hasChangedValues}
                />
              </>
            )}

            <TaskFiles
              task={task}
            />

            <AttachFileToTask
              task={task}
              title={getFileUploadHeader()}
              selectFilesUpdate={setFiles}
              selectOfferFilesUpdate={setOfferFiles}
              motionProps={motionProps}
            />

            <TaskEmailWarnings
              motionProps={motionProps}
              order={order}
              task={task}
            />

            <If condition={isCreateCaseOrderTask}>
              <div className='create-order-task-fields'>
                <RadioButtons
                  choice={values.assign_to_self ? '1' : '0'}
                  updateChoice={choice => updateCaseCreatorChoice(choice)}
                  name='advisor-handles-rsvp'
                  choices={getCaseCreatorChoices()}
                />
                <If condition={!values.assign_to_self}>
                  <AnimatePresence>
                    <motion.div {...animateTop}>
                      <UserDropdown
                        isClearable
                        user={values.user_id}
                        locationId={order.location_id}
                        lawyers={isCreateLawyerOrderTask(task)}
                        tombstoneUsers={isCreateTombstoneOrderTask(task)}
                        therapists={isCreateTherapyOrderTask(task)}
                        onChange={userId => setValue({ user_id: userId })}
                        placeholder={
                          isCreateLawyerOrderTask(task) ? t('Select lawyer (optional)') : t('Select user (optional)')
                        }
                      />
                    </motion.div>
                  </AnimatePresence>
                </If>
              </div>
            </If>

            <div className='mail-send__actions'>
              <LoadingButton
                role='submit'
                text={getSendText()}
                className='send'
                disabled={isDisabled()}
                isLoading={isLoading}
                onClick={send}
              />
              <If condition={!isCreateCaseOrderTask}>
                <LoadingButton
                  text={t('Save draft')}
                  className='save white'
                  disabled={!hasChangedValues}
                  isLoading={isSaving}
                  onClick={save}
                />
                <LoadingButton
                  text={t('Restore information')}
                  className='light'
                  disabled={!hasChangedValues}
                  onClick={restoreDefaultValues}
                />
              </If>
            </div>

            <p>
              <small>
                <em>{t('You will receive a copy of this email to your email. The email signature is automatically attached to all emails.')}</em>
                <If condition={isTransportTask(task)}>
                  <strong><br />{t('The coffin label on this task will be attached automatically.')}</strong>
                </If>
              </small>
            </p>

            <If condition={isTransportTask(task)}>
              <div className='preview-coffin-label-container'>
                <LoadingButton
                  text={t('Preview coffin label')}
                  className='light'
                  isLoading={isLoadingCoffinLabel}
                  onClick={generateCoffinLabel}
                />
              </div>
            </If>
          </form>
        </div>
      </div>
    </>
  );
}

TaskModal.propTypes = {
  task: PropTypes.object.isRequired,
  closeModal: PropTypes.func.isRequired
};

export default TaskModal;
