import React, { useEffect, useState } from 'react';

import useCompetenceAreas from 'ReduxHooks/useCompetenceAreas';
import {
  TYPE_CEREMONY,
  TYPE_FUNERAL,
  TYPE_LEGAL,
  TYPE_THERAPY,
} from 'ReduxActions/competenceAreas';
import { ROLES } from 'ReduxActions/users';
import useUser from 'ReduxHooks/useUser';
import CompetenceTypes from './CompetenceContent/CompetenceTypes';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { Notice } from 'Layout/index';

function CompetenceTab () {
  const { fetchGroupedCompetenceAreas } = useCompetenceAreas();
  const { user } = useUser();
  const { loggedInUser: { country }, isAdmin } = useLoggedInUser();
  const { t } = useTranslation();
  const [competenceAreas, setCompetenceAreas] = useState([]);

  const rolesAndLabels = {
    [ROLES.LEGAL]: {
      label: t('Legal'),
      value: TYPE_LEGAL,
    },
    [ROLES.FUNERAL]: {
      label: t('Funeral'),
      value: TYPE_FUNERAL,
    },
    [ROLES.THERAPIST]: {
      label: t('Therapy'),
      value: TYPE_THERAPY,
    },
    [ROLES.OFFICIANT]: {
      label: t('Ceremony'),
      value: TYPE_CEREMONY,
    },
  };

  useEffect(() => {
    loadDependencies();
  }, []);

  const loadDependencies = async () => {
    await fetchGroupedCompetenceAreas({ countryCode: country.code }).then((items) => {
      setCompetenceAreas(items);
    });
  };

  const shouldShowCompetencesForRole = (role) => {
    return Object.keys(rolesAndLabels).includes(role);
  }

  // Skip the tabs when the user only has one role
  if (user.roles.length === 1 && shouldShowCompetencesForRole(user.roles[0])) {
    return (
      <>
        <CompetenceInformation />
        <CompetenceContent
          role={user.roles[0]}
          isAdmin={isAdmin}
          competenceAreas={competenceAreas}
          rolesAndLabels={rolesAndLabels}
        />
      </>
    );
  }

  return (
    <Tabs>
      <CompetenceInformation />

      <TabList className='react-tabs__tab-list'>
        {user.roles.filter(role => shouldShowCompetencesForRole(role)).map(role => (
          <Tab className='react-tabs__tab' key={`tab_${role}`}>{rolesAndLabels[role].label}</Tab>
        ))}
      </TabList>

      {user.roles.filter(role => shouldShowCompetencesForRole(role)).map(role => (
        <TabPanel key={`panel_${role}`}>
          <CompetenceContent role={role} isAdmin={isAdmin} competenceAreas={competenceAreas} rolesAndLabels={rolesAndLabels} />
        </TabPanel>
      ))}
    </Tabs>
  );
}

const CompetenceContent = ({ role, isAdmin, competenceAreas, rolesAndLabels }) => {
  const { t } = useTranslation();

  return (
    role === ROLES.LEGAL && !isAdmin
      ? <p>{t('Please contact our support via the chat to update your legal competences and skills.')}</p>
      : <CompetenceTypes
        type={rolesAndLabels[role].value}
        competencesForType={(competenceAreas[rolesAndLabels[role].value] || [])}
      />
  )
}

const CompetenceInformation = () => {
  const { t } = useTranslation();

  return (
    <>
      <Notice>
        <h4>{t('Competences and attributes')}</h4>
        <p>{t('Select the competences and attributes that fit your experience and profile. These values are used to match with a customer\'s needs, so a precise and detailed selection is preferable and helpful for all parties.')}</p>
      </Notice>
      <br />
    </>
  )
}

export default CompetenceTab;
