import { getLanguages } from '../actions/languages';
import { useDispatch, useSelector } from 'react-redux';

function useLanguages () {
  const dispatch = useDispatch();
  const { languages } = useSelector(({ languages }) => languages);

  return {
    languages,
    fetchLanguages: () => dispatch(getLanguages())
  };
}

export default useLanguages;
