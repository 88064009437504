import { SET_LANGUAGES } from '../actions/languages';

const initialState = {
  languages: []
};

export default function languages (state = initialState, { type, payload }) {
  switch (type) {
    case SET_LANGUAGES: {
      return {
        ...state,
        languages: payload
      };
    }

    default:
      return state;
  }
}
