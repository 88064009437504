import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import useBonusReports from 'ReduxHooks/useBonusReports';
import Spinner from '../Spinner';
import { Textarea, LoadingButton } from 'FormElements';
import { Link } from 'react-router-dom';
import ManualAdjustmentsTable from './ManualAdjustmentsTable';
import ReportContainer from './ReportContainer';
import { If, AdminOnly } from 'Conditionals';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { Notice } from 'Layout';
import sprintf from 'locutus/php/strings/sprintf';

function BonusReport ({ match }) {
  const { t } = useTranslation();
  const { isAdmin } = useLoggedInUser();
  const { bonusReport, get, persist, resolvedReport, updateFields, updateStatus, generateInvoice, downloadInvoice } = useBonusReports();
  const [isLoading, setIsLoading] = useState(true);
  const [isSaving, setIsSaving] = useState(false);
  const [isInvoiced, setIsInvoiced] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [isMarkingPaid, setIsMarkingPaid] = useState(false);
  const isPaid = bonusReport && !!bonusReport.paid_at;

  async function fetchReport () {
    await get(match.params.id);
    setIsLoading(false);
  }

  const persistReport = async () => {
    setIsSaving(true);
    await persist();
    setIsSaving(false);
  };

  const createInvoice = async () => {
    setIsSaving(true);
    const invoiceResult = await generateInvoice();

    if (invoiceResult) {
      setIsInvoiced(true);
    }
    setIsSaving(false);
  };

  const getInvoice = async () => {
    setIsDownloading(true);
    await downloadInvoice(bonusReport.id);
    setIsDownloading(false);
  };

  const updateReportStatus = async (status) => {
    setIsMarkingPaid(true);

    if (isAdmin) {
      await persist();
    }

    await updateStatus(status);
    setIsMarkingPaid(false);
  };

  function renderStatus () {
    if (bonusReport.paid_at) {
      return `${bonusReport.status} ${bonusReport.paid_at}`;
    }

    return (bonusReport.confirmed_at)
      ? `${bonusReport.status} ${bonusReport.confirmed_at}`
      : sprintf(t('%s created at %s', { description: 'Draft created at 2023-02-15' }), bonusReport.status, bonusReport.created_at);
  }

  useEffect(() => {
    fetchReport();
  }, []);

  if (!resolvedReport || !bonusReport || isLoading) {
    return (
      <Spinner />
    );
  }

  return (
    <div className={classNames('bonus-report', 'order-tab__form', { 'is-admin': isAdmin })}>
      <div className='bonus-report__header'>
        <h2>
          {t('Bonus report')} #{bonusReport.id}
          <AdminOnly>
            &nbsp;({resolvedReport.user.name})
          </AdminOnly>
        </h2>

        <AdminOnly>
          <Link to={`/ersattningsrapporter/user/${resolvedReport.user.id}`}>
            <strong>{t('Show all reports for')} {resolvedReport.user.name}</strong>
          </Link>
        </AdminOnly>

        <div className={classNames('bonus-report__header__actions', { 'is-admin': isAdmin })}>
          <div className='bonus-report__header__status'>
            <span>{renderStatus()}</span>
            <span className='bonus-report__updated-status'>{sprintf(t('Last updated at %s', { description: 'Last updated at 2023-02-15' }), bonusReport.updated_at)}</span>
          </div>
          <AdminOnly>
            <Textarea
              floatingLabel
              label={t('Note')}
              readOnly={isPaid}
              value={bonusReport.note}
              rows={4}
              onChange={note => updateFields({ note })}
            />
            <If condition={!isPaid}>
              <LoadingButton
                isLoading={isSaving}
                disabled={isMarkingPaid}
                onClick={persistReport}
              >
                {t('Save')}
              </LoadingButton>
            </If>
          </AdminOnly>
          {!bonusReport.confirmed_at && !resolvedReport.user.self_invoice ? (
            <LoadingButton
              className={isAdmin ? 'white' : ''}
              onClick={() => updateReportStatus('confirmed')}
              disabled={isSaving}
              isLoading={isMarkingPaid}
            >
              {t('Confirm')}
            </LoadingButton>
          ) : null}
          <If condition={resolvedReport.user.self_invoice}>
            <LoadingButton
              className={isAdmin ? 'white' : ''}
              isLoading={isSaving}
              disabled={isMarkingPaid || isInvoiced || !!bonusReport.self_invoice || isPaid || !resolvedReport.canInvoice || resolvedReport.total <= 0}
              onClick={createInvoice}
            >
              {t('Confirm and create invoice')}
            </LoadingButton>
            <If condition={isInvoiced || !!bonusReport.self_invoice}>
              <LoadingButton
                className='white'
                isLoading={isDownloading}
                disabled={isMarkingPaid}
                onClick={getInvoice}
              >
                {t('Download invoice')}
              </LoadingButton>
            </If>
          </If>
          {bonusReport.confirmed_at ? (
            <AdminOnly>
              <If condition={!bonusReport.paid_at}>
                <LoadingButton
                  className='white'
                  onClick={() => updateReportStatus('paid')}
                  disabled={isSaving}
                  isLoading={isMarkingPaid}
                >
                  {t('Register for payment')}
                </LoadingButton>
              </If>
            </AdminOnly>
          ) : null}
        </div>
      </div>
      {resolvedReport.user.self_invoice && !resolvedReport.canInvoice &&
        <>
          <Notice type='info'>
            <h4>{t('Information about self invoice')}</h4>
            <p>{t('You need to fill in all required fields on the economy tab on your profile to use self invoice.')}</p>
          </Notice>
          <br />
        </>}
      <AdminOnly>
        <ManualAdjustmentsTable />
      </AdminOnly>

      <ReportContainer />
    </div>
  );
}

BonusReport.propTypes = {
  match: PropTypes.object.isRequired
};

export default BonusReport;
