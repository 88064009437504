import { useState } from 'react';
import API from '../stores/API';
import { filterLocalSuppliers, filterNonLocalSuppliers, getQueryParams, formatCurrency } from 'Library/functions';
import { showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

function useSuppliers (country = {}) {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const mapSupplierToValue = (supplierObject) => {
    if (!supplierObject) {
      return null;
    }

    if (Array.isArray(supplierObject)) {
      return supplierObject.map(formatSupplier);
    }

    return formatSupplier(supplierObject);
  };

  const mapSelectedValueToSupplier = (currentSupplier = null) => {
    if (!currentSupplier) {
      return null;
    }

    if (Array.isArray(currentSupplier)) {
      return currentSupplier.map(mapValueToSupplier);
    }

    return mapValueToSupplier(currentSupplier);
  };

  const formatSupplier = ({ name: label, id: value, price, type }) => {
    if (type !== 'officiant') {
      return { label, value };
    }

    if (price) {
      label = `${label} - ${formatCurrency(price, null, country.code)}`;
    }

    return { label, value, price };
  };

  const mapValueToSupplier = ({ value = null }) => {
    if (!value) {
      return null;
    }

    value = parseInt(value);

    return suppliers.find(supplier => parseInt(supplier.id) === value);
  };

  async function fetchSuppliers (type, args = {}) {
    if (isLoading) {
      return;
    }
    setIsLoading(true);

    const params = {
      ...{
        type: (Array.isArray(type)) ? type.join(',') : type,
        limit: 'all',
        include: 'locations,addresses'
      },
      ...args
    };

    if (params.countryId) {
      params.country_id = params.countryId;
      delete params.countryId;
    } else {
      if (country.id) {
        params.country_id = country.id;
      }
    }

    const { status, errors, data } = await API.GET(`/suppliers?mini=1${getQueryParams(params)}`);

    if (status === 'error') {
      showWarningNotice(errors.error_message);
      return false;
    }

    setIsLoading(false);
    setSuppliers(data);

    return data;
  }

  function getOptions ({ locationId = null, isLocal = true, isOfficial = false, isFund = false, isOrganization = false } = {}) {
    if (!isFund && isLocal) {
      const localOptions = getOptionsSuppliers(locationId, isOfficial, true);
      if (!localOptions.length) {
        localOptions.push({
          label: t('No local suppliers found'),
          value: '',
          isDisabled: true
        });
      }

      return [
        {
          label: t('Local suppliers'),
          options: localOptions
        },
        {
          label: t('Other suppliers'),
          options: getOptionsSuppliers(locationId, isOfficial)
        }
      ];
    }

    if (isFund) {
      const fundsWithDonatationSupport = getFunds(true);
      if (!fundsWithDonatationSupport.length) {
        fundsWithDonatationSupport.push({
          label: t('No funds with donation support found'),
          value: '',
          isDisabled: true
        });
      }

      return [
        {
          label: t('Funds with donation support'),
          options: fundsWithDonatationSupport
        },
        {
          label: t('Funds without donation support'),
          options: getFunds()
        }
      ];
    }

    return getOptionsSuppliers(locationId, isOfficial, false, isOrganization);
  }

  function getOptionsSuppliers (locationId = null, isOfficial = false, local = false, isOrganization = false) {
    const supplierArr = (local) ? getLocalSuppliers(locationId) : getSuppliers(locationId);

    // Filter out digital contract partners and memorial funds that are synced as organizations
    if (isOrganization) {
      return supplierArr.filter(supplier => {
        if (supplier.type === 'minnesfond' && supplier.sync_as_org) {
          return true;
        }

        return supplier.type === 'digitala_avtals_partner';
      }).map(formatSupplier);
    }

    return isOfficial
      ? supplierArr.map(formatSupplier).sort(priceCompareFn)
      : supplierArr.map(formatSupplier);
  }

  function getLocalSuppliers (locationId) {
    return suppliers.filter(supplier => filterLocalSuppliers(supplier, locationId));
  }

  function getSuppliers (locationId = null) {
    if (locationId) {
      return suppliers.filter(supplier => filterNonLocalSuppliers(supplier, locationId));
    }

    return suppliers;
  }

  function getFunds (withDonatationSupport = false) {
    const funds = suppliers.filter(supplier => supplier.type === 'minnesfond');

    return funds.filter(supplier => {
      if (!supplier.pennybridge_id) {
        return !withDonatationSupport;
      }

      return withDonatationSupport;
    }).map(formatSupplier);
  }

  function priceCompareFn (a, b) {
    if (!a.price) {
      return +1;
    }

    if (!b.price) {
      return -1;
    }

    return a.price - b.price;
  }

  function uploadExpectedCostFile (id, data) {
    return API.POST(`/suppliers/${id}/upload-expected-cost-file`, data, false);
  }

  function loadExpectedCostFile (id) {
    return API.GET(`/suppliers/${id}/get-expected-cost-file`);
  }

  return {
    fetchSuppliers,
    mapValueToSupplier,
    mapSelectedValueToSupplier,
    mapSupplierToValue,
    formatSupplier,
    getOptions,
    uploadExpectedCostFile,
    loadExpectedCostFile,
    suppliers,
    isLoading
  };
}

export default useSuppliers;
