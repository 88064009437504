import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from 'react-places-autocomplete';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import { Input } from 'FormElements';
import { useTranslation } from 'react-i18next';

function AutoCompleteAddressInput ({ onAdressSelect, address = '' }) {
  const { t } = useTranslation();
  const { isAdmin } = useLoggedInUser();

  const [updatedAddress, setUpdatedAddress] = useState(address);

  const handleSelect = async address => {
    setUpdatedAddress(address);

    const results = await geocodeByAddress(address);
    if (!results.length) {
      alert(t('No results found.'));

      return;
    }

    const firstResult = results[0];
    const {
      lat: latitude,
      lng: longitude
    } = await getLatLng(firstResult);

    onAdressSelect({
      ...parseAddressComponents(firstResult),
      coordinates: {
        latitude,
        longitude
      }
    });
  };

  function getComponentName (ac, type) {
    const component = ac.find(item => item.types.includes(type));

    return (component && component.long_name) ? component.long_name : '';
  }

  function getCity (ac) {
    return getComponentName(ac, 'postal_town') || getComponentName(ac, 'locality');
  }

  function parseAddressComponents ({ address_components: components }) {
    return {
      address_1: `${getComponentName(components, 'route')} ${getComponentName(components, 'street_number')}`,
      city: getCity(components),
      postcode: getComponentName(components, 'postal_code'),
      country: getComponentName(components, 'country', 'short')
    };
  }

  return (
    <div className='places-autocomplete'>
      <PlacesAutocomplete
        value={updatedAddress}
        onChange={(value) => setUpdatedAddress(value)}
        onSelect={handleSelect}
        /* eslint-disable-next-line react/no-children-prop */
        children={({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className={classNames('input floating-label', {
            'has-content': updatedAddress
          })}
          >
            <label htmlFor='location-search-input'>
              {t('Domicile, write your address')}
            </label>
            <input
              {...getInputProps({
                className: 'location-search-input',
                id: 'location-search-input'
              })}
            />
            <div className='autocomplete-dropdown-container'>
              {loading && (
                <div className='loading'>{t('Loading')}...</div>
              )}
              {suggestions.map((suggestion, i) => (
                <div key={i}>
                  <div
                    {...getSuggestionItemProps(suggestion)}
                    className={classNames('suggestion-item', { active: suggestion.active })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
      />
    </div>
  );
}

AutoCompleteAddressInput.propTypes = {
  onAdressSelect: PropTypes.func.isRequired,
  address: PropTypes.string
};

export default AutoCompleteAddressInput;
