import React from 'react';
import PropTypes from 'prop-types';
import Header from 'Layout/Header';

const HeaderWithFilters = ({ header, children = null }) => (
  <div className='header-with-filters'>
    <Header header={header} />

    <div className='header-with-filters__filters'>
      {/* If you want padding between your children, wrap them */}
      {/* in a .header-with-filters__filters__filter class */}
      {children}
    </div>
  </div>
);

HeaderWithFilters.propTypes = {
  header: PropTypes.node.isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ])
};

export default HeaderWithFilters;
