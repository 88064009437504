import { parseISO, isValid } from 'date-fns';
import { zonedTimeToUtc, utcToZonedTime, getTimezoneOffset } from 'date-fns-tz';

export function getDateForSetTimezone (date, timezone) {
  const tz = timezone || Intl.DateTimeFormat().resolvedOptions().timeZone;

  // Neutralize the time zone offset
  const parsedDate = zonedTimeToUtc((isValid(date) ? date : parseISO(date)), 'Europe/Stockholm');
  // Convert the date to the set time zone
  return utcToZonedTime(parsedDate, tz);
}

export function getDatesForSetTimezone (start, end, timeZone = null) {
  return [
    getDateForSetTimezone(start, timeZone),
    getDateForSetTimezone(end, timeZone)
  ];
}

export function getUTCOffset (tz) {
  const userTimezoneOffset = getTimezoneOffset(tz) / 3600000;
  return !userTimezoneOffset
    ? ''
    : (userTimezoneOffset > 0
        ? `+${userTimezoneOffset}`
        : userTimezoneOffset
      );
}
