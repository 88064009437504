import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import ReactSVG from 'react-svg';
import { useTranslation } from 'react-i18next';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import Spinner from 'Components/Spinner';
import CustomerForm from 'Components/OrderTabs/CustomerForm';
import FilesForm from 'Components/OrderTabs/FilesForm';
import DeceasedForm from 'Components/OrderTabs/DeceasedForm';
import ProductsForm from 'Components/OrderTabs/ProductsForm';
import { isTabIncomplete } from 'Library/Orders';
import SingleWrapper from './SingleWrapper';
import CeremonyForm from 'Components/OrderTabs/CeremonyForm';
import EntombmentForm from 'Components/OrderTabs/EntombmentForm';
import MemorialPageForm from 'Components/OrderTabs/MemorialPageForm';
import MemorialForm from 'Components/OrderTabs/MemorialForm';
import TransportForm from 'Components/OrderTabs/TransportForm';
import useTabIndexes from 'Hooks/useTabIndexes';
import FlowersForm from 'Components/OrderTabs/FlowersForm';
import useFlowerOrderProducts from 'ReduxHooks/useFlowerOrderProducts';
import { If } from 'Components/Conditionals';
import { getTabIndexes } from 'Library/TabHelperFunctions';

function SingleOrder ({ match }) {
  const { t } = useTranslation();
  const orderType = 'App\\Order';
  const { isLoading, get, order, getCountryCode } = useSingleOrder();
  const { hasFlowers } = useFlowerOrderProducts();
  const { tabIndex, updateTabs } = useTabIndexes({
    uri: `/ordrar/order/${match.params.id}`,
    tab: match.params.tab || null,
    type: orderType
  });

  const memorialPageIsLocked = !!order?.memorial_page?.lavendla_post_id && !!order?.memorial_page?.lavendla_locked;

  useEffect(() => {
    get(match.params.id, orderType);
  }, [match.params.id]);

  useEffect(() => {
    if (!match.params.tab) {
      return;
    }
    updateTabs(
      getTabIndexes(orderType).findIndex((tab) => tab === match.params.tab)
    );
  }, [match.params.tab]);

  if (!order || isLoading) {
    return (
      <Spinner />
    );
  }

  function hasMemorial () {
    if (!order.funeral) {
        return false;
    }

    return parseInt(order.funeral.has_memorial) === 1;
  }

  return (
    <SingleWrapper>
      <Tabs selectedIndex={tabIndex} onSelect={index => updateTabs(index)}>
        <TabList>
          <Tab className={classNames('react-tabs__tab', {
            incomplete: isTabIncomplete(order)
          })}
          >
            {t('Customer')}
          </Tab>
          <Tab className={classNames('react-tabs__tab', {
            incomplete: isTabIncomplete(order, 'deceased')
          })}
          >
            {t('Deceased')}
          </Tab>
          <Tab>
            {t('Ceremony')}
          </Tab>
          <Tab className={classNames('react-tabs__tab', {
            hidden: !['se', 'dk'].includes(getCountryCode()),
            incomplete: memorialPageIsLocked
          })}
          >
            {t('Memorial page')}
            <If condition={memorialPageIsLocked}>
              <ReactSVG src='/icons/icon-locked.svg' />
            </If>
          </Tab>
          <Tab>
            {t('Interment')}
          </Tab>
          <Tab className={classNames('react-tabs__tab', {
            hidden: !hasMemorial()
          })}
          >
            {t('Memorial')}
          </Tab>
          <Tab>
            {t('Transportation')}
          </Tab>
          <Tab className={classNames('react-tabs__tab', {
            hidden: !hasFlowers()
          })}
          >
            {t('Flowers')}
          </Tab>
          <Tab>
            {t('Products')}
          </Tab>
          <Tab>
            {t('Files')}
          </Tab>
        </TabList>
        <TabPanel>
          <CustomerForm />
        </TabPanel>
        <TabPanel>
          <DeceasedForm />
        </TabPanel>
        <TabPanel>
          <CeremonyForm />
        </TabPanel>
        <TabPanel>
          <MemorialPageForm />
        </TabPanel>
        <TabPanel>
          <EntombmentForm />
        </TabPanel>
        <TabPanel>
          <MemorialForm />
        </TabPanel>
        <TabPanel>
          <TransportForm
            updateTabs={updateTabs}
          />
        </TabPanel>
        <TabPanel>
          <FlowersForm />
        </TabPanel>
        <TabPanel>
          <ProductsForm />
        </TabPanel>
        <TabPanel>
          <FilesForm />
        </TabPanel>
      </Tabs>
    </SingleWrapper>
  );
}

SingleOrder.propTypes = {
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};

export default SingleOrder;
