import i18next from 'i18next';
import { ALL_TYPES, TYPE_CEREMONY, TYPE_FUNERAL, TYPE_LEGAL, TYPE_THERAPY } from 'ReduxActions/competenceAreas';

export function renderOrderType (type) {
  switch (type) {
    case TYPE_FUNERAL:
      return i18next.t('Funeral');
    case TYPE_LEGAL:
      return i18next.t('Legal');
    case TYPE_THERAPY:
      return i18next.t('Therapy');
    case TYPE_CEREMONY:
      return i18next.t('Ceremony');
    default:
      return ' - ';
  }
}

export const getCompetenceCategoryTypesAsOptions = (competenceCategoryTypes) => {
  const formattedOptions = Object.fromEntries([...competenceCategoryTypes].map((type) => [type.id, type.type_label]));

  return {
    0: ' -- ' + i18next.t('Select category type') + ' --',
    ...formattedOptions
  };
}

export const getCompetenceCategoriesAsOptions = (competenceCategories) => {
  const formattedOptions = Object.fromEntries([...competenceCategories].map((type) => [type.id, type.label]));

  return {
    0: ' -- ' + i18next.t('Select category') + ' --',
    ...formattedOptions
  };
}

export function getOrderTypeOptions (all = false) {
  const options = {
    [TYPE_FUNERAL]: i18next.t('Funeral'),
    [TYPE_LEGAL]: i18next.t('Legal'),
    [TYPE_THERAPY]: i18next.t('Therapy'),
    [TYPE_CEREMONY]: i18next.t('Ceremony')
  };

  if (!all) {
    return options;
  }

  return {
    ...{[ALL_TYPES]: ' -- ' + i18next.t('Select order type') + ' --'},
    ...options,
  }
}
