export function getTabIndexes (type = 'App\\Order') {
  switch (type) {
    case 'App\\TombstoneOrder':
      return [
        'kund',
        'avliden',
        'gravsatting',
        'produkter',
        'filer'
      ];

    case 'App\\TherapyOrder':
      return [
        'kund',
        'sessions',
        'records',
        'filer'
      ];

    case 'App\\CeremonyOrder':
      return [
        'kund',
        'produkter',
        'filer'
      ];
    case 'App\\LawyerOrder':
      return [
        'kund',
        'avliden',
        'produkter',
        'filer'
      ];

    case 'Profile':
      return [
        'min-profil',
        'kompetenser',
        'schema',
        'profil',
        'ekonomi',
        'notifikationer',
        'omdomen',
        'admin'
      ];

    default:
      return [
        'kund',
        'avliden',
        'ceremoni',
        'minnessida',
        'gravsattning',
        'minnesstund',
        'transport',
        'blommor',
        'produkter',
        'filer'
      ];
  }
}
