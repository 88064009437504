import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useOrderSuppliers from './useOrderSuppliers';
import { getFuneralType } from 'Library/functions';
import API from '../stores/API';
import { useState } from 'react';
import { showWarningNotice } from 'Library/notifications';
import { useTranslation } from 'react-i18next';

function useDistanceCalculations () {
  const {
    order: {
      id,
      funeral,
      location = null
    }
  } = useSingleOrder();
  const { t } = useTranslation();
  const { getSupplierOfType } = useOrderSuppliers();
  const [distance, setDistance] = useState(null);

  async function calculateDistance () {
    const { data, status, errors } = await API.GET(`/orders/calculate-distances/${id}`);

    if (status === 'error') {
      showWarningNotice(errors.error_message);
      return false;
    }

    setDistance(calculateCosts(data));
    return true;
  }

  function getSteps () {
    const mortuary = {
      label: t('Morgue'),
      type: 'supplier',
      tab: 1,
      value: getSupplierOfType('barhus')
    };

    const crematorium = {
      label: t('Crematorium'),
      type: 'supplier',
      tab: 4,
      value: getSupplierOfType('krematorium')
    };

    const ceremony = {
      label: t('Ceremony venue'),
      type: 'supplier',
      tab: 2,
      value: getSupplierOfType(['Kyrkor', 'Kapell'])
    };

    const data = [
      {
        label: t('Location'),
        type: 'location',
        tab: 2,
        value: location
      },
      mortuary
    ];

    switch (funeral.type) {
      case 'direktkremation':
        return [
          ...data,
          crematorium
        ];

      case 'jordbegravning':
      case 'kremation':
        return [
          ...data,
          ceremony
        ];

      case 'akt_med_urna':
        return [
          ...data,
          crematorium,
          ceremony
        ];

      default:
        return [];
    }
  }

  // Begravning med ceremoni, 50 km ingår i 5400 kr
  // (akt med urna, kremation efter ceremoni, jordbegravning)
  // Sträcka km < 300 km: 5400 kr + 19,5 kr/km*(sträcka km - 50 km)
  // Sträcka km > 300 km: 5400 kr + 15 kr/km*(sträcka km - 50 km)
  //
  // Direktkremation: Sträcka km < 300 km: 4200 kr + 19,5 kr/km*(sträcka km - 50 km)
  // Sträcka km > 300 km: 4200 kr + 15 kr/km*(sträcka km - 50 km
  function calculateCosts (distance) {
    const inclusiveKm = 50;

    if (!funeral.type || !distance) {
      return null;
    }

    const price = (funeral.type === 'direktkremation') ? 4200 : 5400;
    const kmRate = (distance > 300) ? 15 : 19.5;
    const additionalMileage = (distance > inclusiveKm)
      ? ((distance - inclusiveKm) * kmRate)
      : 0;

    return {
      distance,
      price,
      additionalMileage,
      kmRate
    };
  }

  const steps = getSteps();

  return {
    steps,
    calculateDistance,
    distance,
    hasAllSteps: steps.length && steps.every(({ value }) => value),
    funeralType: getFuneralType(funeral.type)
  };
}

export default useDistanceCalculations;
