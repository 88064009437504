import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Input, Select, LoadingButton } from 'FormElements/index';
import { getCompetenceCategoriesAsOptions, getCompetenceCategoryTypesAsOptions, getOrderTypeOptions } from '../helpers';

// Redux
import useCompetenceAreas from 'ReduxHooks/useCompetenceAreas';
import ModelTranslations from 'Components/ModelTranslations/ModelTranslations';
import CompetenceAreaModel from 'Components/CompetenceAreas/CompetenceArea/CompetenceAreaModel';
import useCompetenceCategories from 'ReduxHooks/useCompetenceCategories';
import useCompetenceCategoryTypes from 'ReduxHooks/useCompetenceCategoryTypes';
import useInitialRenderEffect from 'Hooks/useInitialRenderEffect';
import Toggle from 'FormElements/Toggle';
import Checkbox from 'FormElements/Checkbox';
import { Notice } from 'Layout/index';

function CompetenceAreaForm ({ competenceArea = null }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { persist, create, destroy } = useCompetenceAreas();
  const { fetchCompetenceCategories, competenceCategories } = useCompetenceCategories();
  const { fetchCompetenceCategoryTypes, competenceCategoryTypes } = useCompetenceCategoryTypes();

  const [isLoading, setIsLoading] = useState(false);
  const [updatedArea, setUpdatedArea] = useState(competenceArea || CompetenceAreaModel());
  const [hasRendered, setHasRendered] = useState(false);
  useInitialRenderEffect(() => setHasRendered(true))

  useEffect(() => {
    if (hasRendered) {
      fetchOptions();
    }
  }, [hasRendered]);

  const fetchOptions = async () => {
    setIsLoading(true);
    await fetchCompetenceCategories();
    await fetchCompetenceCategoryTypes();
    setIsLoading(false);
  }

  async function onSubmit () {
    setIsLoading(true);
    if (competenceArea) {
      await persist(updatedArea);
    } else {
      await create(updatedArea);
    }

    setIsLoading(false);

    redirectToArchives();
  }

  async function onDelete () {
    if (!window.confirm(t('Are you sure you want to delete this competence area?'))) {
      return;
    }

    const response = await destroy(competenceArea.id);

    if (response) {
      redirectToArchives();
    }
  }

  function redirectToArchives () {
    setTimeout(() => {
      history.push('/kompetenser/');
    }, 1000);
  }

  const categorySelectIsDisabled = (value) => {
    if (!value || isNaN(value)) {
      return false;
    }

    return parseInt(value) !== 0;
  }

  const setCompetenceAreaValue = (values) => {
    setUpdatedArea({
      ...updatedArea,
      ...values
    });
  };

  return (
    <form className='order-tab__form'>
      <div className='row'>
        <Input
          label={t('Name')}
          data-cy='competence-area__name'
          value={updatedArea.name}
          className='col-8'
          onChange={name => setCompetenceAreaValue({ name })}
          floatingLabel
        />
      </div>
      <div className='row'>
        <Input
          label={t('Slug')}
          data-cy='competence-area__slug'
          value={updatedArea.slug}
          className='col-8'
          onChange={slug => setCompetenceAreaValue({ slug })}
          disabled={!!competenceArea}
          floatingLabel
        />
      </div>
      <div className='row'>
        <Input
          label={t('Hours per order')}
          data-cy='competence-area__duration'
          value={updatedArea.duration}
          className='col-8'
          type='number'
          onChange={duration => setCompetenceAreaValue({ duration })}
          floatingLabel
        />
      </div>

      <div className='row'>
        <Input
          label={t('Sort value')}
          data-cy='competence-area__sort-value'
          value={updatedArea.sort_value}
          type='number'
          className='col-8'
          onChange={value => setCompetenceAreaValue({ sort_value: value })}
          floatingLabel
        />
      </div>

      <div className='row'>
        <div className='col-8'>
          <Select
            label={t('Order type')}
            data-cy='competence-area__type'
            value={updatedArea.type}
            options={getOrderTypeOptions()}
            onChange={type => setCompetenceAreaValue({ type })}
            floatingLabel
          />
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <Checkbox
            label={t('Include on profile pages and in sliders')}
            data-cy='competence-area__active_on_profile_pages_and_in_sliders'
            defaultChecked={!!updatedArea.active_on_profile_pages_and_in_sliders}
            onChange={value => setCompetenceAreaValue({ active_on_profile_pages_and_in_sliders: value })}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <Checkbox
            label={t('Include in inquiry menus')}
            data-cy='competence-area__active_in_inquiry_menus'
            defaultChecked={!!updatedArea.active_in_inquiry_menus}
            onChange={value => setCompetenceAreaValue({ active_in_inquiry_menus: value })}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <Notice type="info">
            <span>{t('A competence area should be associated with a type of category to specify what it entails.')}</span>
            <br />
            <span>{t('It can be associated with a type directly, or indirectly through a category (which adds even further specificity to the competence area).')}</span>
            <div className="row">
              <div className="col-12" style={{ marginBottom: '10px' }}>
                <Select
                  label={t('Category type')}
                  data-cy="competence-area__competence_category_type_id"
                  isDisabled={categorySelectIsDisabled(updatedArea.competence_category_id)}
                  value={updatedArea.competence_category_type_id}
                  options={getCompetenceCategoryTypesAsOptions(competenceCategoryTypes)}
                  onChange={categoryTypeId => setCompetenceAreaValue({ competence_category_type_id: categoryTypeId })}
                  floatingLabel
                />
              </div>
              <div className="col-12">
                <Select
                  label={t('Category')}
                  data-cy="competence-area__competence_category_id"
                  isDisabled={categorySelectIsDisabled(updatedArea.competence_category_type_id)}
                  value={updatedArea.competence_category_id}
                  options={getCompetenceCategoriesAsOptions(competenceCategories)}
                  onChange={categoryId => setCompetenceAreaValue({ competence_category_id: categoryId })}
                  floatingLabel
                />
              </div>
            </div>
          </Notice>
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <ModelTranslations
            model={updatedArea}
            property={'label'}
            onChange={translations => setCompetenceAreaValue({ translations })}
          ></ModelTranslations>
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <LoadingButton
            isLoading={isLoading}
            data-cy="competence-area__submit"
            disabled={!updatedArea.name}
            onClick={() => onSubmit()}
          >
          {competenceArea ? t('Update') : t('Create')}
          </LoadingButton>
          {' '}
          {competenceArea
            ? (
              <LoadingButton
                className='delete'
                data-cy='competence-area__delete'
                onClick={() => onDelete()}
              >
                {t('Delete')}
              </LoadingButton>
            )
            : null}
        </div>
      </div>
    </form>
  );
}

CompetenceAreaForm.propTypes = {
  competenceArea: PropTypes.object
};

export default CompetenceAreaForm;
