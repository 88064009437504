import React from 'react';
import If from 'Conditionals/If';

const Carets = ({ isOpen }) => {
  return (
    <div className={'competence-type-category__header__title__carets'}>
      <If condition={isOpen}>
        <span>&#9660;</span>
      </If>
      <If condition={!isOpen}>
        <span>&#9654;</span>
      </If>
    </div>
  );
}

export default Carets;
