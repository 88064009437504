import { useRef, useEffect } from 'react';

/**
 * Calls method after initial render
 *
 * @param effect
 * @param dependencies
 */
export default function useInitialRenderEffect (effect, dependencies = []) {
  const isInitialRender = useRef(true);

  useEffect(() => {
    if (isInitialRender.current) {
      effect();
    }

    isInitialRender.current = false;

    return () => { isInitialRender.current = true; };
  }, dependencies);
}
