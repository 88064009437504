import React, { useEffect, useState } from 'react';
import { HeaderWithFilters, Table } from 'Layout';
import ListingPagination from 'Components/ListingPagination';
import { useTranslation } from 'react-i18next';
import { Input, LoadingButton, Toggle, Select } from 'Components/FormElements';
import useWills from 'ReduxHooks/useWills';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import Modal from 'Components/Modal';
import NewFileUpload from 'Components/NewFileUpload';
import { If } from 'Conditionals';
import PropTypes from 'prop-types';
import UserCountryDropdown from 'Components/UserCountryDropdown';

function WillsArchive ({
  history,
  isSearch = false,
  archived = false
}
) {
  const { isAdmin, hasMultipleCountries } = useLoggedInUser();
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedWill, setSelectedWill] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [isMarkingPrinted, setIsMarkingPrinted] = useState([]);
  const [isMarkingStored, setIsMarkingStored] = useState([]);
  const [country, setCountry] = useState([]);
  const { fetchWills, wills, pagination, isLoading, destroy, archive, persist, setWill, markPrinted, markStored, downloadFile } = useWills();
  const { t } = useTranslation();

  useEffect(() => {
    getWills();
  }, [country, isSearch, archived]);

  const getWills = () => {
    const filters = {
      countryId: country.id
    };

    if (isSearch && history.location.search) {
      filters.search = history.location.search.split('=')[1];
    }

    if (archived) {
      filters.archived = true;
    }

    fetchWills(filters);
  };

  useEffect(() => {
    return history.listen(location => {
      if (isSearch && location.search) {
        fetchWills({ countryId: country.id, search: location.search.split('=')[1] });
      }
    });
  }, [history]);

  const mimeTypes = [
    'application/pdf',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ];

  // Handle pagination page switch
  const switchPage = (page) => {
    if (page === 'previous') {
      if (pagination.current_page > 1) {
        page = parseInt(pagination.current_page) - 1;
      }
    }

    if (page === 'next') {
      page = parseInt(pagination.current_page) + 1;
    }

    const filters = {
      countryId: country.id,
      page
    };
    if (isSearch) {
      filters.search = window.location.search.split('=')[1] || '';
    }

    fetchWills(filters);
  };

  function getHeader () {
    if (isSearch) {
      return t('Search wills');
    }

    if (archived) {
      return t('Archived wills');
    }

    return t('Wills');
  }

  // Handle edit action
  const handleEdit = (will) => {
    setIsEditing(true);
    setSelectedWill(will);
    setModalOpen(true);
  };

  const openWill = (willId) => {
    setSelectedWill(wills.find((will) => will.id === willId));
    setModalOpen(true);
  };

  // Handle delete action
  const handleDelete = async (willId) => {
    if (window.confirm(t('Are you sure you want to delete this will?'))) {
      await destroy(willId);
      getWills();
    }
  };

  const toggleArchive = async (willId) => {
    const message = archived ? t('Are you sure you want to restore this will?') : t('Are you sure you want to archive this will?');
    if (window.confirm(message)) {
      await archive(willId, archived);
      getWills();
    }
  };

  const closeModal = () => {
    setIsEditing(false);
    setSelectedWill({});
    setModalOpen(false);
  };

  const createWill = () => {
    setSelectedWill({ source: 'Admin', country_id: country.id, people: [], testators: [], should_print: false, should_store: false, is_printed: false, is_stored: false });
    setIsEditing(true);
    setModalOpen(true);
  };

  const updatePrinted = async (willId) => {
    if (window.confirm(t('Are you sure you want to mark this will as printed?'))) {
      setIsMarkingPrinted([...isMarkingPrinted, willId]);
      await markPrinted(willId);
      setIsMarkingPrinted(isMarkingPrinted.filter((id) => id !== willId));
      fetchWills({ countryId: country.id });
    }
  };

  const updateStored = async (willId) => {
    if (window.confirm(t('Are you sure you want to mark this will as stored?'))) {
      setIsMarkingStored([...isMarkingStored, willId]);
      await markStored(willId);
      setIsMarkingStored(isMarkingStored.filter((id) => id !== willId));
      fetchWills({ countryId: country.id });
    }
  };

  const handleChange = (field, value) => {
    setSelectedWill({ ...selectedWill, [field]: value });
  };

  const handlePersonChange = (index, field, value) => {
    const updatedPeople = [...selectedWill.people];
    updatedPeople[index][field] = value;
    setSelectedWill({ ...selectedWill, people: updatedPeople });
  };

  const handleTestatorChange = (index, field, value) => {
    const updatedTestators = [...selectedWill.testators];
    updatedTestators[index][field] = value;
    setSelectedWill({ ...selectedWill, testators: updatedTestators });
  };

  const getTodo = (will) => {
    if (archived) {
      return '';
    }

    if (!will.should_print && !will.should_store) {
      return t('Nothing');
    }

    if (will.should_print && !will.is_printed && will.should_store && !will.is_stored) {
      return (
        <>
          <div className='actions-group'>
            <LoadingButton
              className='small'
              onClick={() => updatePrinted(will.id)}
              isLoading={isMarkingPrinted.includes(will.id)}
            >
              {t('Mark as printed')}
            </LoadingButton>
            <LoadingButton
              className='small'
              onClick={() => updateStored(will.id)}
              isLoading={isMarkingStored.includes(will.id)}
            >{t('Mark as stored')}
            </LoadingButton>
          </div>
        </>
      );
    }

    if (will.should_print && !will.is_printed) {
      return <LoadingButton className='small' onClick={() => updatePrinted(will.id)} isLoading={isMarkingPrinted.includes(will.id)}>{t('Mark as printed')}</LoadingButton>;
    }

    if (will.should_store && !will.is_stored) {
      return <LoadingButton className='small' onClick={() => updateStored(will.id)} isLoading={isMarkingStored.includes(will.id)}>{t('Mark as stored')}</LoadingButton>;
    }

    return t('Done');
  };

  const addPerson = () => {
    setSelectedWill({
      ...selectedWill,
      people: [...selectedWill.people || [], { name: '', ssn: '', address: '', type: '' }]
    });
  };

  const addTestator = () => {
    setSelectedWill({
      ...selectedWill,
      testators: [...selectedWill.testators || [], { name: '', ssn: '', address: '', type: '', email: '', is_alive: true, sorted_by: false }]
    });
  };

  const removePerson = (index) => {
    const updatedPeople = selectedWill.people.filter((_, i) => i !== index);
    setSelectedWill({ ...selectedWill, people: updatedPeople });
  };

  const removeTestator = (index) => {
    const updatedTestators = selectedWill.testators.filter((_, i) => i !== index);
    setSelectedWill({ ...selectedWill, testators: updatedTestators });
  };

  const attachFiles = (files) => {
    const data = new FormData();

    if (selectedWill.newFiles) {
      selectedWill.newFiles.forEach((file) => {
        data.append('files[]', file);
      });
    }

    files.forEach((file) => {
      data.append('files[]', file);
    });

    setSelectedWill({ ...selectedWill, newFiles: data });
  };

  const removeFile = (index) => {
    const updatedFiles = selectedWill.newFiles.getAll('files[]');
    updatedFiles.splice(index, 1);

    const data = new FormData();
    updatedFiles.forEach((file) => {
      data.append('files[]', file);
    });

    setSelectedWill({ ...selectedWill, newFiles: data });
  };

  const persistWill = async () => {
    await setWill(selectedWill);
    await persist();
    getWills();
    setModalOpen(false);
    setIsEditing(true);
  };

  return (
    <div className='registry'>
      <HeaderWithFilters header={getHeader()}>
        {isAdmin && (
          <div className='header-with-filters__filters__filter'>
            <button
              onClick={createWill}
              className='btn btn-primary'
            >
              {t('Add will')}
            </button>
          </div>
        )}
        {hasMultipleCountries && (
          <div className='header-with-filters__filters__filter'>
            <UserCountryDropdown
              isClearable
              selected={country.id}
              onChange={countries => setCountry(countries[0])}
            />
          </div>
        )}
      </HeaderWithFilters>
      <Table
        isLoading={isLoading}
        thead={[
          t('Created'),
          t('Name'),
          t('Social security number'),
          t('Executor'),
          t('Source'),
          t('To do'),
          t('Actions')
        ]}
      >
        {(!wills.length && !isLoading)
          ? (
            <tr>
              <td colSpan='6'>{t('No wills found')}</td>
            </tr>
            )
          : (
              wills.map((will) => (
                <tr key={will.id}>
                  <td>{will.created_at}</td>
                  <td>
                    <button className='btn btn-link' onClick={() => openWill(will.id)}>
                      {will?.testators?.map((testator) => testator.first_name + ' ' + testator.last_name).join(', ')}
                    </button>
                  </td>
                  <td>{will?.testators?.map((testator) => testator.ssn).join(', ')}</td>
                  <td>{will?.people?.find((person) => person.type === 'executor')?.name}</td>
                  <td>{will.source}</td>
                  <td>{getTodo(will)}</td>
                  <td>
                    <div className='actions-group'>
                      {!archived && (
                        <>
                          <button className='btn small' onClick={() => handleEdit(will)}>{t('Edit')}</button>
                        </>
                      )}
                      <button className='btn small white' onClick={() => toggleArchive(will.id)}>{archived ? t('Restore') : t('Archive')}</button>
                      {!archived && (
                        <>
                          <button className='btn small delete' onClick={() => handleDelete(will.id)}>{t('Delete')}</button>
                        </>
                      )}
                    </div>
                  </td>
                </tr>
              ))
            )}
      </Table>

      <ListingPagination pagination={pagination} switchPage={switchPage} />

      <Modal
        close={closeModal}
        className='category-modal will-creator-form'
        headerTitle={isEditing ? (selectedWill?.id ? t('Edit will') : t('Add will')) : t('Will details')}
        isOpen={modalOpen}
      >
        <div>
          <If condition={isEditing && selectedWill}>
            <h3>{t('Testators')}</h3>
            <Table
              isLoading={false}
              thead={[t('First name'), t('Last name'), t('Social security number'), t('Email'), t('Address'), t('Alive'), t('Sorted by'), t('Actions')]}
            >
              {selectedWill?.testators?.length > 0
                ? selectedWill?.testators?.map((testator, index) => (
                  <tr key={index} className='person-fields'>
                    <td>
                      <Input
                        label={t('First name')}
                        floatingLabel
                        name='first_name'
                        value={testator.first_name}
                        onChange={(value) => handleTestatorChange(index, 'first_name', value)}
                        required
                      />
                    </td>
                    <td>
                      <Input
                        label={t('Last name')}
                        floatingLabel
                        name='last_name'
                        value={testator.last_name}
                        onChange={(value) => handleTestatorChange(index, 'last_name', value)}
                        required
                      />
                    </td>
                    <td>
                      <Input
                        label={t('Social security number')}
                        floatingLabel
                        name='ssn'
                        value={testator.ssn}
                        onChange={(value) => handleTestatorChange(index, 'ssn', value)}
                        required
                      />
                    </td>
                    <td>
                      <Input
                        label={t('Email')}
                        floatingLabel
                        name='email'
                        value={testator.email}
                        onChange={(value) => handleTestatorChange(index, 'email', value)}
                      />
                    </td>
                    <td>
                      <Input
                        label={t('Address')}
                        floatingLabel
                        name='address'
                        value={testator.address}
                        onChange={(value) => handleTestatorChange(index, 'address', value)}
                      />
                    </td>
                    <td>
                      <Toggle
                        defaultChecked={testator.is_alive}
                        onChange={(value) => handleTestatorChange(index, 'is_alive', value)}
                      />
                    </td>
                    <td>
                      <Toggle
                        defaultChecked={testator.sorted_by}
                        onChange={(value) => handleTestatorChange(index, 'sorted_by', value)}
                      />
                    </td>
                    <td>
                      <button type='button' className='btn small delete' onClick={() => removeTestator(index)}>
                        {t('Remove')}
                      </button>
                    </td>
                  </tr>
                ))
                : (
                  <tr>
                    <td colSpan='8'>{t('No testators. Use the button below to add new testators.')}</td>
                  </tr>
                  )}
              <tr>
                <td colSpan='8'>
                  <button type='button' onClick={addTestator} className='btn btn-primary'>
                    {t('Add Testator')}
                  </button>
                </td>
              </tr>
            </Table>
            <h3>{t('People with access')}</h3>
            <Table
              isLoading={false}
              thead={[t('Name'), t('social security number'), t('Address'), t('Type'), t('Actions')]}
            >
              {selectedWill?.people?.length > 0
                ? selectedWill?.people?.map((person, index) => (
                  <tr key={index} className='person-fields'>
                    <td>
                      <Input
                        label={t('Name')}
                        floatingLabel
                        name='name'
                        value={person.name}
                        onChange={(value) => handlePersonChange(index, 'name', value)}
                        required
                      />
                    </td>
                    <td>
                      <Input
                        label={t('Social security number')}
                        floatingLabel
                        name='ssn'
                        value={person.ssn}
                        onChange={(value) => handlePersonChange(index, 'ssn', value)}
                      />
                    </td>
                    <td>
                      <Input
                        label={t('Address')}
                        floatingLabel
                        name='address'
                        value={person.address}
                        onChange={(value) => handlePersonChange(index, 'address', value)}
                        required
                      />
                    </td>
                    <td>
                      <Select
                        name='type'
                        value={person.type}
                        onChange={(value) => handlePersonChange(index, 'type', value)}
                        defaultOption={t('Select type')}
                        options={{
                          executor: t('Executor'),
                          original: t('Should have original'),
                          copy: t('Should have copy')
                        }}
                        required
                      />
                    </td>
                    <td>
                      <button type='button' className='btn small delete' onClick={() => removePerson(index)}>
                        {t('Remove')}
                      </button>
                    </td>
                  </tr>
                ))
                : (
                  <tr>
                    <td colSpan='5'>{t('No people assigned. Use the button below to add new people.')}</td>
                  </tr>
                  )}
              <tr>
                <td colSpan='5'>
                  <button type='button' onClick={addPerson} className='btn btn-primary'>
                    {t('Add Person')}
                  </button>
                </td>
              </tr>
            </Table>
            <h3>{t('Files')}</h3>
            <NewFileUpload
              onChange={attachFiles}
              mimeTypes={mimeTypes}
              isLoading={isLoading}
            />
            {selectedWill.newFiles?.getAll('files[]').length > 0 && (
              <>
                <h4>{t('New files')}</h4>
                <Table
                  thead={[t('Filename'), t('Actions')]}
                >
                  {Array.from(selectedWill.newFiles.getAll('files[]')).map((file, index) => (
                    <tr key={index}>
                      <td>{file.name}</td>
                      <td>
                        <button className='btn btn-link' onClick={() => removeFile(index)}>{t('Remove')}</button>
                      </td>
                    </tr>
                  ))}
                </Table>
              </>
            )}

            {selectedWill.files?.length > 0 && (
              <>
                <h4>{t('Already attached files')}</h4>
                <Table
                  thead={[t('Filename'), t('Actions')]}
                >
                  {selectedWill.files.map((file, index) => (
                    <tr key={index}>
                      <td>{file.filename}</td>
                      <td>
                        <button className='btn small' onClick={() => downloadFile(selectedWill.id, file.id)}>{t('Download')}</button>
                      </td>
                    </tr>
                  ))}
                </Table>
              </>
            )}
            <h3>{t('Misc')}</h3>
            <div className='creator-form-row'>
              <div className='creator-form-row__column'>
                <Input
                  label={t('Source')}
                  floatingLabel
                  name='source'
                  value={selectedWill?.source || 'Admin'}
                  onChange={(value) => handleChange('source', value)}
                  required
                />
              </div>
            </div>
            <h3>{t('Printing and storage')}</h3>
            <Toggle
              label={t('This will should be printed')}
              defaultChecked={selectedWill?.should_print}
              onChange={(value) => handleChange('should_print', value)}
            />
            <Toggle
              label={t('This has been printed')}
              defaultChecked={selectedWill?.is_printed}
              onChange={(value) => handleChange('is_printed', value)}
            />
            <Toggle
              label={t('This will should be stored')}
              defaultChecked={selectedWill?.should_store}
              onChange={(value) => handleChange('should_store', value)}
            />
            <Toggle
              label={t('This has been stored')}
              defaultChecked={selectedWill?.is_stored}
              onChange={(value) => handleChange('is_stored', value)}
            />
            <div className='modal__footer'>
              <div className='btn-group'>
                <LoadingButton
                  type='submit'
                  isLoading={isLoading}
                  onClick={persistWill}
                >
                  {selectedWill?.id ? t('Update Will') : t('Create Will')}
                </LoadingButton>
                <button onClick={closeModal} className='btn btn-secondary'>
                  {t('Cancel')}
                </button>
              </div>
            </div>
          </If>
          <If condition={!isEditing && modalOpen && selectedWill}>
            <h3>{t('Testators')}</h3>
            <Table
              isLoading={false}
              thead={[t('Sorted by'), t('First name'), t('Last name'), t('Social security number'), t('Email'), t('Address'), t('Alive')]}
            >
              {selectedWill?.testators?.map((testator, index) => (
                <tr key={index}>
                  <td>{testator.sorted_by ? t('Yes') : t('No')}</td>
                  <td>{testator.first_name}</td>
                  <td>{testator.last_name}</td>
                  <td>{testator.ssn}</td>
                  <td>{testator.email}</td>
                  <td>{testator.address}</td>
                  <td>{testator.is_alive ? t('Yes') : t('No')}</td>
                </tr>
              ))}
            </Table>
            <h3>{t('People with access')}</h3>
            <Table
              isLoading={false}
              thead={[t('Name'), t('Social security number'), t('Address'), t('Type')]}
            >
              {selectedWill?.people?.map((person, index) => (
                <tr key={index}>
                  <td>{person.name}</td>
                  <td>{person.ssn}</td>
                  <td>{person.address}</td>
                  <td>{t(person.type)}</td>
                </tr>
              ))}
            </Table>
            <h3>{t('Misc')}</h3>
            <p>
              <strong>{t('Source')}:</strong> {selectedWill.source || t('No source')}
            </p>
            <h3>{t('Files')}</h3>
            <Table
              thead={[t('Filename'), t('Actions')]}
            >
              {selectedWill.files?.length
                ? (
                  <>
                    {selectedWill.files.map((file, index) => (
                      <tr key={index}>
                        <td>{file.filename}</td>
                        <td>
                          <button className='btn small' onClick={() => downloadFile(selectedWill.id, file.id)}>{t('Download')}</button>
                        </td>
                      </tr>
                    ))}
                  </>
                  )
                : (
                  <tr>
                    <td colSpan='4'>{t('No files attached')}</td>
                  </tr>
                  )}
            </Table>
            {selectedWill.should_print || selectedWill.should_store
              ? (
                <>
                  <h3>{t('Printing and storage')}</h3>
                  {selectedWill.should_print && (
                    <>
                      <div>
                        {!selectedWill.is_printed
                          ? (
                            <LoadingButton onClick={markPrinted} isLoading={isLoading}>
                              {t('Mark as printed')}
                            </LoadingButton>
                            )
                          : (
                            <strong>{t('This has been printed')}</strong>
                            )}
                      </div>
                      <br />
                      <div>

                        {selectedWill.should_store && (
                          <>
                            {!selectedWill.is_stored
                              ? (
                                <LoadingButton onClick={markStored} isLoading={isLoading}>
                                  {t('Mark as stored')}
                                </LoadingButton>
                                )
                              : (
                                <strong>{t('This has been stored')}</strong>
                                )}
                          </>
                        )}
                      </div>
                    </>
                  )}
                </>

                )
              : null}
            <div className='modal__footer'>
              <button onClick={closeModal} className='btn btn-secondary'>
                {t('Close')}
              </button>
            </div>
          </If>
        </div>
      </Modal>
    </div>
  );
}

WillsArchive.propTypes = {
  history: PropTypes.object,
  isSearch: PropTypes.bool,
  archived: PropTypes.bool
};

export default WillsArchive;
