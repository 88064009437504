import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { generateId } from 'Library/functions';
import useSuppliers from '../hooks/useSuppliers';
import { AnimatePresence, motion } from 'framer-motion';
import SelectedSupplier from './SelectedSupplier';
import { animateOpacity } from 'Library/motion';
import { useTranslation } from 'react-i18next';

function SupplierDropdown ({
  type,
  onChange,
  filters = {},
  id = generateId(),
  label = '',
  noValueLabel = '',
  supplier = null,
  isLocal = true,
  country = {},
  locationId = null,
  showSelectedInfo = true,
  isMulti = false,
  isOrganization = false,
  ...rest
}) {
  const {
    fetchSuppliers,
    mapSupplierToValue,
    mapSelectedValueToSupplier,
    getOptions,
    isLoading,
    suppliers
  } = useSuppliers(country);
  const { t } = useTranslation();
  const [currentSupplier, setCurrentSupplier] = useState(mapSupplierToValue(supplier));
  const previousFilters = useRef('');

  useEffect(() => {
    const strFilters = JSON.stringify(filters);

    if (previousFilters.current !== strFilters) {
      fetchSuppliers(type, filters);
    }

    previousFilters.current = strFilters;
  }, [locationId]);

  useEffect(() => {
    if (!suppliers.length) {
      return;
    }

    if (!supplier && !currentSupplier) {
      return;
    }

    if (isMulti) {
      if (currentSupplier && supplier.length === currentSupplier.length) {
        return;
      }
    } else {
      if (supplier && currentSupplier && supplier.id === currentSupplier.value) {
        return;
      }
    }

    if (!currentSupplier) {
      onChange(type, null);

      return;
    }

    onChange(type, mapSelectedValueToSupplier(currentSupplier));
  }, [currentSupplier, suppliers]);

  const renderSupplierInfo = () => {
    if (!showSelectedInfo || isLoading) {
      return null;
    }

    const selectedSupplier = mapSelectedValueToSupplier(currentSupplier);
    if (!selectedSupplier) {
      return null;
    }

    if (Array.isArray(selectedSupplier) && !selectedSupplier.length) {
      return null;
    }

    return (
      <>
        {(Array.isArray(selectedSupplier))
          ? selectedSupplier.filter().map(sup => (
            <SelectedSupplier key={sup.id} supplier={sup} />
          ))
          : (
            <SelectedSupplier supplier={selectedSupplier} />
            )}
      </>
    );
  };

  return (
    <AnimatePresence>
      <motion.div {...animateOpacity} className='selected-supplier__container'>
        {label && (
          <label
            htmlFor={id}
            dangerouslySetInnerHTML={{ __html: label }}
          />
        )}
        {renderSupplierInfo()}
        <Select
          id={id}
          value={currentSupplier}
          onChange={setCurrentSupplier}
          placeholder={isLoading ? t('-- loading --') : noValueLabel || t('No supplier selected')}
          options={getOptions({ locationId, isLocal, isOfficial: type === 'officiant', isFund: type === 'minnesfond', isOrganization })}
          className='lavendla-select'
          classNamePrefix='lavendla-select'
          isMulti={isMulti}
          isClearable
          {...rest}
        />
      </motion.div>
    </AnimatePresence>
  );
}

SupplierDropdown.propTypes = {
  type: PropTypes.oneOfType([
    PropTypes.string.isRequired,
    PropTypes.arrayOf(PropTypes.string.isRequired)
  ]),
  onChange: PropTypes.func.isRequired,
  id: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  filters: PropTypes.object,
  label: PropTypes.string,
  noValueLabel: PropTypes.string,
  isLocal: PropTypes.bool,
  showSelectedInfo: PropTypes.bool,
  isMulti: PropTypes.bool,
  supplier: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object
  ]),
  country: PropTypes.object,
  locationId: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string
  ])
};

export default SupplierDropdown;
