import React, { useEffect, useState } from 'react';
import { HeaderWithFilters } from 'Layout/index';
import ListingPagination from 'Components/ListingPagination';
import { useTranslation } from 'react-i18next';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import PropTypes from 'prop-types';
import UserCountryDropdown from 'Components/UserCountryDropdown';
import useReviews from 'ReduxHooks/useReviews';
import { getID } from 'Components/localstorage';
import SingleReview from './SingleReview';
import { formatUser } from 'Library/functions';
import Select from 'react-select';
import { setUser } from '@sentry/react';

function ReviewsArchive ({ history }) {
  const { hasMultipleCountries } = useLoggedInUser();
  const [country, setCountry] = useState([]);
  const [users, setUsers] = useState([]);
  const [usersForDropdownWithPlaceholder, setUsersForDropdownWithPlaceholder] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [currentPage, setCurrentPage] = useState(new URLSearchParams(history.location.search).get('page') || 1);
  const { fetchReviews, reviews, pagination, isLoading, setReview, persistReviewForReply, persistReviewForRequiresModeration, persistReviewForUserUpdate, getDropdownUsersForFilters } = useReviews();
  const { isAdmin } = useLoggedInUser();
  const { t } = useTranslation();

  useEffect(() => {
    getReviews();
    fetchUsers();
  }, [country]);

  useEffect(() => {
    getReviews();
  }, [selectedUser]);

  const getReviews = async () => {
    await fetchReviews(getDefaultFilters());
  };

  async function fetchUsers () {
    const { status, data } = await getDropdownUsersForFilters();

    let items = status === 'ok' ? data : [];

    setUsers(items);

    if (items.length === 0) {
      setUsersForDropdownWithPlaceholder([]);

      return;
    }

    let allItems = [{
      id: 'none',
      name: ' - ' + t('Reviews without a user')
    }];

    allItems = allItems.concat(items);

    setUsersForDropdownWithPlaceholder(allItems);
  }

  const switchPage = (direction) => {
    let page = 1;

    if (direction === 'previous') {
      if (currentPage > 1) {
        page = parseInt(currentPage) - 1;
      }
    }

    if (direction === 'next') {
      page = parseInt(currentPage) + 1;
    }

    setCurrentPage(page);

    let filters = getDefaultFilters();
    filters.page = page;

    fetchReviews(filters);
  };

  const getDefaultFilters = () => {
    return {
      countryId: country?.id,
      userId: isAdmin ? -1 : getID(),
      selectedUserId: selectedUser?.value,
      page: currentPage
    };
  }

  const updateReply = async (review) => {
    const updatedReview = { ...review };

    await setReview(updatedReview);
    await persistReviewForReply();
  };

  const updateRequiresModeration = async (review) => {
    const updatedReview = { ...review };

    await setReview(updatedReview);
    await persistReviewForRequiresModeration();
  };

  const updateUser = async (review) => {
    const updatedReview = { ...review };

    await setReview(updatedReview);

    return await persistReviewForUserUpdate();
  };

  return (
    <div className='registry'>
      <HeaderWithFilters header={t('Reviews')}>
        {hasMultipleCountries && (
          <div className='header-with-filters__filters__items'>
            <Select
              value={selectedUser}
              isClearable={true}
              className='lavendla-select'
              classNamePrefix='lavendla-select'
              options={usersForDropdownWithPlaceholder.map(formatUser)}
              placeholder={isLoading ? t('Loading') + ' ...' : t('Select user')}
              onChange={user => setSelectedUser(user)}
            />
            <UserCountryDropdown
              isClearable
              selected={country?.id}
              onChange={selectedCountry => setCountry(selectedCountry[0])}
            />
          </div>
        )}
      </HeaderWithFilters>

      <div>
        {(!reviews.length && !isLoading)
          ? (<div>{t('No reviews found')}</div>)
          : (
            reviews.map((review) => (
              <SingleReview
                review={review}
                key={review.id}
                users={users}
                updateReply={updateReply}
                updateRequiresModeration={updateRequiresModeration}
                updateUser={updateUser}
                refreshReviews={getReviews}
              />
            ))
          )}
      </div>

      <ListingPagination
        pagination={pagination}
        switchPage={switchPage}
      />
    </div>
  );
}

ReviewsArchive.propTypes = {
  history: PropTypes.object,
};

export default ReviewsArchive;
