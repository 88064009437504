import React from 'react';
import PropTypes from 'prop-types';

const Header = ({ header }) => (
  <h1
    className='header-with-filters__header'
    dangerouslySetInnerHTML={{ __html: header }}
  />
);

Header.propTypes = {
  header: PropTypes.node.isRequired,
};

export default Header;
