import React from 'react';
import Carets from 'Components/User/CompetenceContent/Carets';
import { useTranslation } from 'react-i18next';

const CompetenceTypeCategories = ({ competenceCategory, toggleAllCheckHandler, allAreChecked, setIsOpen, isOpen, numberOfItemsChecked }) => {
  const { t } = useTranslation();

  return (
    <div className={'competence-type-category__header'}>
      <div
        className={'competence-type-category__header__title'}
        onClick={() => setIsOpen(!isOpen)}
      >
        <Carets isOpen={isOpen} />
        <span>
          {competenceCategory.category_label || t('Unsorted')}
        </span>
        <span className={'competence-type-category__header__title__amounts'}>
          ({numberOfItemsChecked} / {competenceCategory.competence_areas.length})
        </span>
      </div>
      <button
        type={'button'}
        onClick={toggleAllCheckHandler}
      >
        {allAreChecked ? t('Deselect all') : t('Select all')}
      </button>
    </div>
  );
}

export default CompetenceTypeCategories;
