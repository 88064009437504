import { useDispatch, useSelector } from 'react-redux';
import {
  getCompetenceCategory,
  getCompetenceCategories,
  updateCompetenceCategory,
  createCompetenceCategory,
  deleteCompetenceCategory,
  forgetCompetenceCategories
} from '../actions/competenceCategories';

function useCompetenceCategories () {
  const dispatch = useDispatch();
  const { competenceCategories, competenceCategory } = useSelector(({ competenceCategories }) => competenceCategories);

  function fetchCompetenceCategories (filters = {}) {
    return dispatch(getCompetenceCategories(filters));
  }

  function fetchCompetenceCategory (id) {
    return dispatch(getCompetenceCategory(id));
  }

  function persist (data) {
    return dispatch(updateCompetenceCategory(data.id, data));
  }

  function create (data) {
    return dispatch(createCompetenceCategory(data));
  }

  function destroy (id) {
    return dispatch(deleteCompetenceCategory(id));
  }

  function resetCompetenceCategories () {
    return dispatch(forgetCompetenceCategories());
  }

  return {
    competenceCategories,
    competenceCategory,
    fetchCompetenceCategories,
    fetchCompetenceCategory,
    persist,
    create,
    destroy,
    resetCompetenceCategories
  };
}

export default useCompetenceCategories;
