import { useDispatch, useSelector } from 'react-redux';
import { showNotice } from 'Library/notifications';
import {
  getReviews,
  persistReply,
  persistRequiresModeration,
  persistUserUpdate,
  updateReview,
  setIsLoading
} from '../actions/reviews';
import { useTranslation } from 'react-i18next';
import API from '../../stores/API';

function useReviews () {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { reviews, review, pagination, isLoading } = useSelector((state) => state.reviews);

  async function fetchReviews (filters) {
    await dispatch(setIsLoading(true));
    await dispatch(getReviews(filters));
    await dispatch(setIsLoading(false));
  }

  async function persistReviewForReply () {
    dispatch(setIsLoading(true));

    const response = await dispatch(persistReply());

    showNotice(t('The review has been updated'));

    dispatch(setIsLoading(false));

    return response;
  }

  async function persistReviewForRequiresModeration () {
    dispatch(setIsLoading(true));

    const response = await dispatch(persistRequiresModeration());

    showNotice(t('The review has been updated'));

    dispatch(setIsLoading(false));

    return response;
  }

  async function persistReviewForUserUpdate () {
    dispatch(setIsLoading(true));

    const response = await dispatch(persistUserUpdate());

    showNotice(t('The review has been updated'));

    dispatch(setIsLoading(false));

    return response;
  }

  async function getDropdownUsersForFilters () {
    dispatch(setIsLoading(true));

    const response = await API.GET('/reviews/users?limit=-1');

    dispatch(setIsLoading(false));

    return response;
  }

  async function getDropdownUsers (review) {
    dispatch(setIsLoading(true));

    const response = await API.GET(`/reviews/users/${review.id}/?limit=-1`);

    dispatch(setIsLoading(false));

    return response;
  }

  return {
    fetchReviews,
    persistReviewForReply,
    persistReviewForRequiresModeration,
    persistReviewForUserUpdate,
    getDropdownUsersForFilters,
    getDropdownUsers,
    setReview: (data) => dispatch(updateReview(data)),
    isLoading,
    reviews,
    review,
    pagination
  };
}

export default useReviews;
