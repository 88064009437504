import React, { useState } from 'react';
import PropTypes from 'prop-types';
import OrderAppointments from '../Appointments/OrderAppointments';
import ConnectedOrder from '../ConnectedOrder';
import SocialSecurityNumber from './SocialSecurityNumber';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { Checkbox, Input, Select, Textarea } from 'FormElements';
import useAppointments from 'Hooks/useAppointments';
import OrderTab from './OrderTab';
import LocationDropdown from '../LocationDropdown';
import HasLawyerOrder from '../HasLawyerOrder';
import CompetenceAreaDropdown from '../CompetenceAreaDropdown';
import OrderSupplierDropdown from 'Components/OrderSupplierDropdown';
import useDuplicateCustomer from 'Hooks/useDuplicateCustomer';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import RequestedPlace from '../RequestedPlace';
import { TYPE_FUNERAL, TYPE_THERAPY, TYPE_CEREMONY } from 'ReduxActions/competenceAreas';
import { If } from 'Conditionals';
import { Notice } from 'Layout';
import { AnimatePresence, motion } from 'framer-motion';
import { animateTop } from 'Library/motion';
import Contacts from '../Contacts/Contacts';
import { useTranslation } from 'react-i18next';
import { caseTypeIsEstateOrInheritance } from '../../values/caseTypeDefinitions';

function CustomerForm () {
  const { t } = useTranslation();
  const { isAdmin } = useLoggedInUser();
  const { order, orderCountry, updateField, updateFields, isLawyerOrder, isFuneralOrder, isTombstoneOrder, isTherapyOrder, isCeremonyOrder, getCountryCode } = useSingleOrder();
  const { customer } = order;
  const { getAppointmentOfType, toggleApointment } = useAppointments();
  const { isDuplicated } = useDuplicateCustomer();
  const customerMeeting = getAppointmentOfType('customer_meeting');
  const [showMeeting, setShowMeeting] = useState(!!customerMeeting);
  const [showPostcodeWarning, setShowPostcodeWarning] = useState(!isValidPostCode(customer.postcode));

  const onGetCustomerDetailsClick = (details) => {
    updateFields({
      customer: {
        ...customer,
        first_name: details.first_name,
        last_name: details.last_name,
        social_security_number: details.social_security_number,
        address_1: details.address2 ? details.address2 : details.address1,
        postcode: details.zip_code,
        city: details.city
      }
    });
  };

  function updatePostcode (value) {
    updateField('customer.postcode', value);

    setShowPostcodeWarning(!isValidPostCode(value));
  }

  function isValidPostCode (value) {
    if (!value) {
      return true;
    }

    return value.length <= 10 && /^[0-9a-zA-Z ]+$/.test(value);
  }

  function getCompetenceAreaDropdownProps () {
    switch (true) {
      case isLawyerOrder:
        return {}; // default values
      case isTherapyOrder:
        return {
          type: TYPE_THERAPY
        };
      case isCeremonyOrder:
        return {
          type: TYPE_CEREMONY
        };
      default:
        return {
          type: TYPE_FUNERAL,
          isMulti: true
        };
    }
  }

  function showRelationToDeceasedField () {
    if (!isLawyerOrder) {
      return false;
    }

    return caseTypeIsEstateOrInheritance(order.case_type_definition);
  }

  const inputProps = {
    className: 'col-4',
    floatingLabel: true
  };

  function isRedacted () {
    const { order_offer_invitations: orderOfferInvitations } = order;

    if (isAdmin || Array.isArray(orderOfferInvitations)) {
      return false;
    }

    return !!orderOfferInvitations;
  }

  return (
    <OrderTab
      tab='customer'
      header={t('Customer information')}
      afterTasks={(
        <>
          <div className='row'>
            <div className='col-12'>
              <ConnectedOrder />
            </div>
          </div>
          <div className='row'>
            <Textarea
              className='col-12'
              value={order.custom_info}
              onChange={value => updateField('custom_info', value)}
              label={t('Other')}
              rows={10}
              data-cy='order.custom_info'
              floatingLabel
            />
          </div>
          <RequestedPlace />
        </>
      )}
    >
      <>
        <SocialSecurityNumber
          onUpdate={onGetCustomerDetailsClick}
          onChange={socialSecurityNumber => updateField('customer.social_security_number', socialSecurityNumber)}
          value={customer.social_security_number}
          type={getCountryCode() === 'dk' ? 'cpr' : getCountryCode() === 'global' ? 'other' : 'spar'}
          title={t("Customer's social security/Company number")}
        />
        <div className='row'>
          <Input
            value={customer.first_name}
            label={t('First/Company name')}
            onChange={value => updateField('customer.first_name', value)}
            data-cy='customer.first_name'
            {...inputProps}
            showRequiredIcon
          />
          <Input
            value={customer.last_name}
            label={t('Last name/Prefix')}
            onChange={value => updateField('customer.last_name', value)}
            data-cy='customer.last_name'
            {...inputProps}
            className={isRedacted() ? 'col-4 redacted' : 'col-4'}
            showRequiredIcon
          />
          <Input
            value={customer.email}
            label={t('Email address')}
            onChange={value => updateField('customer.email', value)}
            data-cy='customer.email'
            {...inputProps}
            className={isRedacted() ? 'col-4 redacted' : 'col-4'}
            showRequiredIcon
          />
        </div>
        <div className='row'>
          <Input
            value={customer.phone}
            label={t('Phone number')}
            onChange={value => updateField('customer.phone', value)}
            onBlur={({ target }) => isDuplicated(target.value)}
            data-cy='customer.phone'
            {...inputProps}
            className={isRedacted() ? 'col-4 redacted' : 'col-4'}
            showRequiredIcon
          />
          <Input
            value={customer.address_1}
            label={t('Address')}
            onChange={value => updateField('customer.address_1', value)}
            data-cy='customer.address_1'
            {...inputProps}
            showRequiredIcon
          />
          <Input
            value={customer.postcode}
            label={t('Post code')}
            onChange={value => updatePostcode(value)}
            data-cy='customer.postcode'
            {...inputProps}
            showRequiredIcon
          />
        </div>
        <If condition={showPostcodeWarning}>
          <AnimatePresence>
            <motion.div className='row' {...animateTop}>
              <div className='col-12'>
                <Notice type='error'>
                  <strong>{t('NB! Invalid Swedish post code.')}</strong> {t('Limit to max 10 characters in order to avoid issues when the order is invoiced.')}
                </Notice>
              </div>
            </motion.div>
          </AnimatePresence>
        </If>
        <div className='row'>
          <Input
            value={customer.city}
            label={t('Postal city')}
            onChange={value => updateField('customer.city', value)}
            data-cy='customer.city'
            {...inputProps}
            showRequiredIcon
          />
          <If condition={!isFuneralOrder}>
            <div className='col-4 react-select__floating-label'>
              <LocationDropdown
                id='location'
                countryId={orderCountry.id}
                value={order.location_id}
                onChange={location => {
                  const locationId = location ? location.value : null;
                  updateField('location_id', locationId);
                }}
              />
              <label htmlFor='location'>{t('City')}</label>
            </div>
          </If>

          {!(isFuneralOrder || isTombstoneOrder)
            ? (
              <div className='col-4 react-select__floating-label competence-area-wrapper'>
                <CompetenceAreaDropdown
                  id='competence-area'
                  countryId={order.country_id}
                  selected={order.competence_areas}
                  onChange={value => updateField('competence_areas', value)}
                  placeholder={t('-- Select --')}
                  {...getCompetenceAreaDropdownProps()}
                />
                <label htmlFor='competence-area'>{t('Case type')}</label>
              </div>
              )
            : (
              <Select
                label={t('Relation to the deceased')}
                onChange={value => updateField('customer.relation_to_deceased', value)}
                value={customer.relation_to_deceased}
                defaultOption={t('-- Select --')}
                options={{
                  'maka/make': t('Husband/Wife'),
                  barn: t('Child'),
                  annat: t('Other'),
                  sambo: t('Partner'),
                  förälder: t('Parent')
                }}
                data-cy='customer.relation_to_deceased'
                {...inputProps}
                showRequiredIcon
              />
              )}
        </div>

        <If condition={isFuneralOrder}>
          <div className='row'>
            <div
              data-cy='order.competence_areas'
              className='col-8 react-select__floating-label competence-area-wrapper'
            >
              <CompetenceAreaDropdown
                id='competence-area'
                countryId={order.country_id}
                selected={order.competence_areas}
                type={TYPE_FUNERAL}
                onChange={value => updateField('competence_areas', value)}
                placeholder={t('-- Select --')}
                isMulti
              />
              <label htmlFor='competence-area'>{t('Case types')}</label>
            </div>
          </div>
        </If>

        {showRelationToDeceasedField() || isLawyerOrder
          ? (
            <div className='row'>
              <If condition={showRelationToDeceasedField()}>
                <Select
                  label={t('Relation to the deceased')}
                  onChange={value => updateField('customer.relation_to_deceased', value)}
                  value={customer.relation_to_deceased}
                  defaultOption={t('-- Select --')}
                  options={{
                    'maka/make': t('Husband/Wife'),
                    barn: t('Child'),
                    annat: t('Other'),
                    sambo: t('Partner'),
                    förälder: t('Parent')
                  }}
                  data-cy='customer.relation_to_deceased'
                  {...inputProps}
                  showRequiredIcon
                />
              </If>
              <If condition={isLawyerOrder}>
                <OrderSupplierDropdown
                  className='col-8'
                  isLocal={false}
                  isOrganization
                  label={t('Partner/Organization')}
                  noValueLabel={t('-- No partner/organization selected --')}
                  showSelectedInfo={false}
                  type={['digitala_avtals_partner', 'minnesfond']}
                />
              </If>
            </div>
            )
          : null}

        <If condition={isLawyerOrder}>
          {(order.suppliers || []).length > 0 && order.suppliers
            .filter(supplier => supplier.can_be_billable_for_order_invoices && supplier.order_invoice_description)
            .map(supplier => <p key={supplier.id}>{supplier.order_invoice_description}</p>)}
        </If>

        {order.contact_url && (
          <div className='col-8 checkbox-grouped-container'>
            <div>
              <label htmlFor='input_758'>{t('What page did the customer contact us from')}</label>
              <a href={order.contact_url} target='_blank' rel='noreferrer'>{order.contact_url}</a>
            </div>
          </div>
        )}

        <Contacts />

        <div className='checkbox-grouped-container'>
          <If condition={!isTherapyOrder}>
            <Checkbox
              label={t('Customer meeting')}
              defaultChecked={showMeeting}
              data-cy='appointment-toggle'
              onChange={async () => {
                if (customerMeeting) {
                  await toggleApointment('customer_meeting', null);
                }
                setShowMeeting(!showMeeting);
              }}
            />
          </If>
          <Checkbox
            label={t('Secrecy')}
            defaultChecked={order.secret === 1}
            data-cy='order.secret'
            onChange={() => updateField('secret', !order.secret)}
          />
        </div>

        <If condition={showMeeting && !isTherapyOrder}>
          <OrderAppointments
            showSuppliers
            header={t('Customer meeting')}
            type='customer_meeting'
            settings={{
              showMarkAsBusyToggle: true
            }}
          />
        </If>

        <If condition={isLawyerOrder}>
          <HasLawyerOrder orderId={order.id} />
        </If>
      </>
    </OrderTab>
  );
}

CustomerForm.propTypes = {
  isLawyerOrder: PropTypes.bool
};

export default CustomerForm;
