import { useDispatch, useSelector } from 'react-redux';
import {
  getCompetenceCategoryType,
  getCompetenceCategoryTypes,
  updateCompetenceCategoryType,
  createCompetenceCategoryType,
  deleteCompetenceCategoryType,
  reFetchCompetenceCategoryTypes
} from '../actions/competenceCategoryTypes';

function useCompetenceCategoryTypes () {
  const dispatch = useDispatch();
  const { competenceCategoryTypes, competenceCategoryType } = useSelector(({ competenceCategoryTypes }) => competenceCategoryTypes);

  function fetchCompetenceCategoryTypes (filters = {}) {
    return dispatch(getCompetenceCategoryTypes(filters));
  }

  function fetchCompetenceCategoryType (id) {
    return dispatch(getCompetenceCategoryType(id));
  }

  function persist (data) {
    return dispatch(updateCompetenceCategoryType(data));
  }

  function create (data) {
    return dispatch(createCompetenceCategoryType(data));
  }

  function destroy (id) {
    return dispatch(deleteCompetenceCategoryType(id));
  }

  function refreshCompetenceCategoryTypes () {
    return dispatch(reFetchCompetenceCategoryTypes());
  }

  return {
    competenceCategoryTypes,
    competenceCategoryType,
    fetchCompetenceCategoryTypes,
    fetchCompetenceCategoryType,
    persist,
    create,
    destroy,
    refreshCompetenceCategoryTypes
  };
}

export default useCompetenceCategoryTypes;
