import React from 'react';
import PropTypes from 'prop-types';
import { AnimatePresence, motion } from 'framer-motion';
import IncompleteTasksWarning from './Warnings/IncompleteTasksWarning';
import UpdatedCeremonyWarning from './Warnings/UpdatedCeremonyWarning';
import TransportTaskWarning from './Warnings/TransportTaskWarning';
import ProductWarning from './Warnings/ProductWarning';
import VismaInvoiceWarning from './Warnings/VismaInvoiceWarning';
import RequiredFuneralOrderItemsWarning from './Warnings/RequiredFuneralOrderItemsWarning';

function TaskEmailWarnings (props) {
  const warnings = [
    RequiredFuneralOrderItemsWarning,
    IncompleteTasksWarning,
    UpdatedCeremonyWarning,
    TransportTaskWarning,
    ProductWarning,
    VismaInvoiceWarning
  ];

  return warnings.map((Component, i) => (
    <AnimatePresence key={i}>
      <motion.div {...props.motionProps}>
        <Component {...props} />
      </motion.div>
    </AnimatePresence>
  ));
}

TaskEmailWarnings.propTypes = {
  order: PropTypes.object.isRequired,
  task: PropTypes.object.isRequired,
  motionProps: PropTypes.object.isRequired
};

export default TaskEmailWarnings;
