import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { HeaderWithFilters } from 'Layout/index';
import { Select } from 'FormElements/index';
import { getCompetenceCategoriesAsOptions, getCompetenceCategoryTypesAsOptions, getOrderTypeOptions } from './helpers';
import { ALL_TYPES } from 'ReduxActions/competenceAreas';
import If from 'Conditionals/If';
import useCompetenceCategoryTypes from 'ReduxHooks/useCompetenceCategoryTypes';
import Header from 'Layout/Header';
import classNames from 'classnames';
import useCompetenceCategories from 'ReduxHooks/useCompetenceCategories';

function CompetenceHeader ({ type, title, refreshFilters, onCreate, showFilters = true }) {
  const { t } = useTranslation();

  const { fetchCompetenceCategoryTypes, competenceCategoryTypes } = useCompetenceCategoryTypes();
  const { fetchCompetenceCategories, competenceCategories } = useCompetenceCategories();

  const [orderType, setOrderType] = useState(ALL_TYPES);
  const [categoryType, setCategoryType] = useState(ALL_TYPES);
  const [category, setCategory] = useState(ALL_TYPES);

  useEffect(() => {
    if (showFilters) {
      fetchCompetenceCategoryTypes();
      fetchCompetenceCategories();
    }
  }, []);

  useEffect(() => {
    if (showFilters) {
      refreshFilters({
        orderType,
        category,
        categoryType
      });
    }
  }, [orderType, category, categoryType]);

  const handleChangedOrderType = (orderType) => {
    setOrderType(orderType);
  }

  const handleChangedCategory = (category) => {
    setCategory(category);
  }

  const handleChangedCategoryType = (categoryType) => {
    setCategoryType(categoryType);
  }

  return (
    <div>
      <div className={'competence-navbar react-tabs__tab-list'}>
        <Link
          to='/kompetenser'
          className={classNames('react-tabs__tab', {'react-tabs__tab--selected' : type === 'competence-areas'})}
        >
          {t('Competence Areas')}
        </Link>
        <Link
          to="/competence-categories"
          className={classNames('react-tabs__tab', { 'react-tabs__tab--selected': type === 'competence-categories' })}
        >
          {t('Competence Categories')}
        </Link>
        <Link
          to="/competence-category-types"
          className={classNames('react-tabs__tab', { 'react-tabs__tab--selected': type === 'competence-category-types' })}
        >
          {t('Competence Category Types')}
        </Link>
      </div>

      <If condition={showFilters}>
        <HeaderWithFilters header={title}>
          <div className="header-with-filters__filters__filter">
            <Select
              value={orderType}
              className="col-6"
              options={getOrderTypeOptions(true)}
              onChange={handleChangedOrderType}
            />
          </div>

          {type !== 'competence-categories' && (
            <div className="header-with-filters__filters__filter">
              <Select
                value={category}
                className="col-6"
                options={getCompetenceCategoriesAsOptions(competenceCategories)}
                onChange={handleChangedCategory}
              />
            </div>
          )}

          <div className="header-with-filters__filters__filter">
            <Select
              value={categoryType}
              className="col-6"
              options={getCompetenceCategoryTypesAsOptions(competenceCategoryTypes)}
              onChange={handleChangedCategoryType}
            />
          </div>

          <div className="header-with-filters__filters__filter">
            <button
              onClick={onCreate}
              className="btn"
            >{t('Create new')}
            </button>
          </div>
        </HeaderWithFilters>
      </If>
      <If condition={!showFilters}>
        <div style={{ marginTop: '39px', marginBottom: '48px' }}>
          <Header header={title} />
        </div>
      </If>
    </div>
  );
}

export default CompetenceHeader;
