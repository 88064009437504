import { SET_COMPETENCE_CATEGORIES, SET_COMPETENCE_CATEGORY } from '../actions/competenceCategories';

const initialState = {
  competenceCategories: [],
  competenceCategory: {
    id: null,
    category_type_id: null,
    type: null,
    label: null
  }
};

export default function competenceCategories (state = initialState, { type, payload }) {
  switch (type) {
    case SET_COMPETENCE_CATEGORIES: {
      return {
        ...state,
        competenceCategories: payload
      };
    }

    case SET_COMPETENCE_CATEGORY: {
      return {
        ...state,
        competenceCategory: payload
      };
    }

    default:
      return state;
  }
}
