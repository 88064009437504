import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Checkbox, LoadingButton, RadioButtons } from 'FormElements/index';
import PropTypes from 'prop-types';
import Textarea from 'FormElements/Textarea';
import If from 'Conditionals/If';

function RequiresModerationModalContent ({ review, isLoading, updateRequiresModeration, cancel }) {
  const { t } = useTranslation();
  const [currentReview, setCurrentReview] = useState(review);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleChange = (field, value) => {
    const updatedReview = { ...currentReview, [field]: value };

    setCurrentReview(updatedReview);
  };

  const dispatchRequiresModeration = () => {
    setErrorMessage(null);

    if (currentReview.requires_moderation_reason && !currentReview.requires_moderation_description) {
      setErrorMessage(t('You must add a description for the moderation request.'));

      return;
    }

    if (!currentReview.requires_moderation_reason && currentReview.requires_moderation_description) {
      setErrorMessage(t('You must select a reason for the moderation request.'));

      return;
    }

    const updatedReview = {
      ...currentReview,
      requires_moderation: true,
    };

    setCurrentReview(updatedReview);

    updateRequiresModeration(updatedReview);
  };

  const removeRequest = () => {
    if (! confirm(t('Are you sure you want to remove the moderation request?'))) {
      return;
    }

    const updatedReview = {
      ...currentReview,
      requires_moderation_reason: null,
      requires_moderation_description: null,
      requires_moderation: false,
    };

    setCurrentReview(updatedReview);

    updateRequiresModeration(updatedReview);
  };

  return (
   <>
     <div className={'review-requires-moderation-modal-content'}>
       <span className={'review-requires-moderation-modal-content__reason-subtitle'}>
         {t('Why are you flagging this review?')}
       </span>

       <RadioButtons
         choice={currentReview.requires_moderation_reason || '0'}
         updateChoice={choice => handleChange('requires_moderation_reason', choice)}
         name={`req-moderation-reason-${currentReview.id}`}
         choices={currentReview.requires_moderation_reasons}
       />

       <div className={'review-requires-moderation-modal-content__error'}>
         {errorMessage && (
           <span className={'review-requires-moderation-modal-content__error'}>{errorMessage}</span>
         )}
       </div>

       <Textarea
         onChange={value => handleChange('requires_moderation_description', value)}
         label={t('Add a description')}
         rows={3}
         value={currentReview.requires_moderation_description}
         floatingLabel
       />
     </div>

     <If condition={currentReview.requires_moderation || currentReview.requires_moderation_description || currentReview.requires_moderation_reason}>
       <div className="review-requires-moderation-modal-content__reset">
         <button
           onClick={removeRequest}
           className="btn btn-link danger"
         >
           {t('Remove moderation request')}
         </button>
       </div>
     </If>

     <div className={'modal__footer'}>
       <div className={'btn-group review-requires-moderation-modal-content__buttons'}>
         <button onClick={cancel} className="btn btn-secondary">
           {t('Cancel')}
         </button>

         <LoadingButton
           isLoading={isLoading}
           onClick={dispatchRequiresModeration}
         >
           {t('Update')}
         </LoadingButton>
       </div>
     </div>
   </>
  );
}

RequiresModerationModalContent.propTypes = {
  review: PropTypes.object,
  updateRequiresModeration: PropTypes.func,
  cancel: PropTypes.func,
  isLoading: PropTypes.bool,
};

export default RequiresModerationModalContent;
