import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useGoogleLogin } from '@react-oauth/google';
import { getLoggedInUser } from '../Redux/actions/users';
import { AnimatePresence, motion } from 'framer-motion';
import ReactSVG from 'react-svg';
import API from '../stores/API';
import { saveID, saveToken } from 'Components/localstorage';
import { LoadingButton } from './FormElements';
import { useTranslation } from 'react-i18next';
import { Notice } from 'Layout';

const CalenderSyncAuth = ({ user }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [error, setError] = useState(null);

  const googleLogin = useGoogleLogin({
    flow: 'auth-code',
    scope: 'profile email openid https://www.googleapis.com/auth/calendar',
    onSuccess: async (codeResponse) => {
      setIsLoading(true);
      const response = await API.POST('/auth/google', {
        code: codeResponse.code
      });

      saveID(response.user.id);
      saveToken(response.token);

      await dispatch(getLoggedInUser());
      setIsAuthenticated(true);
      setIsLoading(false);
    },
    onError: (errorResponse) => setError('Something went wrong. Try contacting helpdesk for more assistance.')
  });

  if (user.google_calendar_sync || isAuthenticated) {
    return null;
  }

  return (
    <>
      <Notice classlist='gcal-sync-notice'>
        <h4>{t('Automatic calendar sync')}</h4>
        <p>{t('Currently we can only sync your calendar when you login.')}</p>
        <p>{t('In order to sync your calendar with Lavendla automatically, you need to activate to give permission to Lavendla to access your calendar.')}</p>
        <br />
        <LoadingButton
          className='btn g-sync-button'
          onClick={googleLogin}
          isLoading={isLoading}
          disabled={isLoading}
        >
          <ReactSVG src='/icons/google-logo.svg' />
          <span>
            {t('Give permission')}
          </span>
        </LoadingButton>

        <AnimatePresence>
          {error && (
            <motion.div
              initial={{ opacity: 0, x: -25 }}
              animate={{ opacity: 1, x: 0 }}
              exit={{ opacity: 0, x: -25 }}
              className='inline-notice warning'
            >
              <p>{error}</p>
            </motion.div>
          )}
        </AnimatePresence>
      </Notice>
    </>
  );
};

CalenderSyncAuth.propTypes = {
  user: PropTypes.object.isRequired
};

export default CalenderSyncAuth;
