import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from 'ReduxHooks/useUser';

// Components
import Fieldset from 'FormElements/Fieldset';
import Toggle from 'FormElements/Toggle';
import LavendlaDatePicker from 'Components/LavendlaDatePicker';
import { format } from 'date-fns';

function FeatureFlagsFormElements () {
  const { t } = useTranslation();
  const { user, updateField } = useUser();

  return (
    <Fieldset legend={t('Therapy')} className='user-admin__fieldset'>
      <Toggle
        desc={t('If checked, the user will use Stripe Connect to receive payments. You need to connect the user to Stripe in the Stripe Connect section in the Economy tab.')}
        label={t('Use Stripe Connect')}
        defaultChecked={!!user.has_stripe_connect}
        onChange={() => updateField('has_stripe_connect', !user.has_stripe_connect)}
      />

      <label>{t('Fixed pricing')}</label>
      <LavendlaDatePicker
        placeholder={t('No fixed pricing')}
        date={user.fixed_price_from ? format(new Date(user.fixed_price_from), 'yyyy-MM-dd') : null}
        onChange={date => updateField('fixed_price_from', date || null)}
        label={t('Use Fixed pricing')}
      />
      <p>
        <small>{t('If a date is added the user will be charged a fee that is the cost of a 45 min session for each new customer. For old customers the fee is procenttal.')}</small>
      </p>
    </Fieldset>
  );
}

export default FeatureFlagsFormElements;
