import { If } from 'Conditionals';
import React, { createRef, useEffect, useState } from 'react';
import moment from 'moment';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { LoadingButton } from 'FormElements';
import { useTranslation } from 'react-i18next';

function DeceasedInfoPreview () {
  const {
    order: {
      deceased,
      memorial_page: memorialPage
    },
    updateField
  } = useSingleOrder();
  const { t } = useTranslation();
  const placeholder = `${t('Join us and share greetings, photos and videos. Together we can relive memories and great moments with')} ${getDeceasedFirstName()}`;
  const textRef = createRef();
  const [textRefHeight, setTextRefHeight] = useState(getTextRefHeight());
  const deceasedYear = (deceased.date) ? moment(deceased.date).format('YYYY') : '';

  useEffect(() => {
    setTextRefHeight(getTextRefHeight());
  }, [memorialPage.custom_text]);

  function getDeceasedName () {
    if (!deceased.nickname) {
      return deceased.name;
    }

    return `${deceased.first_name} “${deceased.nickname}” ${deceased.last_name}`;
  }

  function getDeceasedFirstName () {
    return (deceased.nickname) ? deceased.nickname : deceased.first_name;
  }

  function getTextRefHeight () {
    if (!textRef || !textRef.current) {
      return 'auto';
    }

    return textRef.current.scrollHeight + 'px';
  }

  return (
    <>
      <h1 className='obituary__title'><span>{t('In memory of')}</span> {getDeceasedName()}</h1>

      <If condition={deceased.birth_year}>
        <p className='obituary-single__hero-date'>{deceased.birth_year} - {deceasedYear}</p>
      </If>

      <div className='obituary-single__hero-text'>
        <textarea
          ref={textRef}
          style={{
            height: textRefHeight
          }}
          value={memorialPage.custom_text || ''}
          data-cy='memorial_page.custom_text'
          placeholder={placeholder}
          onFocus={({ target }) => !target.value ? updateField('memorial_page.custom_text', placeholder) : null}
          onChange={({ target }) => updateField('memorial_page.custom_text', target.value)}
        />
        <LoadingButton
          onClick={() => textRef.current.select()}
          className='text'
        >
          {!memorialPage.custom_text ? t('Add your own text') : t('Edit text')}
        </LoadingButton>
      </div>

      <svg className='obituary-single__hero-illustration'>
        <use href='#hero-heart' />
      </svg>
    </>
  );
}

export default DeceasedInfoPreview;
