import React from 'react';
import classNames from 'classnames';

const CompetenceTypeCategories = ({ competenceArea, competenceAreaCheckHandler }) => {
  return (
    <div
      className={classNames('competence-type-category__competence-area-container__item', {
        'competence-type-category__competence-area-container__item__selected': competenceArea.is_selected,
      })}
      onClick={() => competenceAreaCheckHandler(competenceArea)}
    >
      <span className={'competence-type-category__competence-area-container__item__symbol'}>
        &#8627;
      </span>
      <span className={'competence-type-category__competence-area-container__item__label'}>
        {competenceArea.label || competenceArea.name}
      </span>
    </div>
  );
}

export default CompetenceTypeCategories;
