import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useUser from 'ReduxHooks/useUser';

// Components
import Fieldset from 'FormElements/Fieldset';
import Toggle from 'FormElements/Toggle';

function FeatureFlagsFormElements () {
  const { t } = useTranslation();
  const { user, features, updateField, getFeatures } = useUser();

  useEffect(() => {
    loadDependencies();
  }, []);

  const loadDependencies = async () => {
    await getFeatures();
  };

  return (
    <Fieldset legend={t('Feature flags')} className='user-admin__fieldset'>
      {Object.keys(features).map((featureKey) => {
        let userFeature = user.user_features.find(f => f.feature === featureKey);

        if (!userFeature) {
          userFeature = { feature: featureKey, active: false };
          user.user_features.push(userFeature);
        }

        const isActive = userFeature.active;

        return (
          <Toggle
            key={featureKey}
            label={t(features[featureKey])}
            defaultChecked={isActive}
            onChange={() => {
              const newUserFeatures = user.user_features.map(feature => {
                if (feature.feature === featureKey) {
                  return { ...feature, active: !isActive };
                }

                return feature;
              });

              updateField('user_features', newUserFeatures);
            }}
          />
        );
      })}
    </Fieldset>
  );
}

export default FeatureFlagsFormElements;
