import React from 'react';
import PropTypes from 'prop-types';
import { formatCurrency } from 'Library/functions';

const ReportTableRow = ({ row: {
  source, id, type, title, reference, total, link, customer_email, payment_id
}, reportUser }) => (
  <tr className='bonus-report__report__rows__row'>
    <td>{source}</td>
    <td>
      {link ? <a href={link} target="_blank">{id}</a> : id}<br />
      <small>{customer_email}</small><br />
      <small>{payment_id}</small>
    </td>
    <td>{type}</td>
    <td>{title}</td>
    <td>{reference}</td>
    <td>{total ? formatCurrency(total, 2, reportUser?.country?.code) : '-'}</td>
  </tr>
);

ReportTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  reportUser: PropTypes.object
};

export default ReportTableRow;
