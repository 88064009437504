import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TimePicker from 'rc-time-picker';
import moment from 'moment';
import DatePicker, { registerLocale } from 'react-datepicker';
import { sv, da } from 'date-fns/locale';
import { getDisabledMinutesFortTimePicker } from 'Library/functions';
import ReactSVG from 'react-svg';
import SupplierDropdown from '../SupplierDropdown';
import classNames from 'classnames';
import { Select, Toggle } from 'FormElements';
import { useTranslation } from 'react-i18next';
import useAppointments from 'Hooks/useAppointments';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import useCountries from 'ReduxHooks/useCountries';

registerLocale('se', sv);
registerLocale('dk', da);

const datePickerProps = {
  isClearable: true,
  dateFormat: 'PP'
};

const Appointment = ({
  appointment = {},
  showSuppliers = false,
  locationId = null,
  hideMeetingType = false,
  onChange,
  settings
}) => {
  const { t } = useTranslation();
  const { getMeetingTypesForSelect } = useAppointments();
  const { allowedCountries } = useCountries();
  const { order, isTherapyOrder, getCountryCode, orderCountry } = useSingleOrder();
  const [startDate, setStartDate] = useState(getInitialDate());
  const [startTime, setStartTime] = useState(getIntialDateMoment());
  const [endTime, setEndTime] = useState(getIntialDateMoment('end_date'));
  const countryCode = getCountryCode();
  const appointmentCountryCode = countryCode && allowedCountries.includes(countryCode) ? countryCode : 'se';

  const timePickerProps = {
    showSecond: false,
    hideDisabledOptions: true,
    disabledMinutes: getDisabledMinutesFortTimePicker
  };

  /**
   * Get the initial value from the Datetime where appropriate.
   * @param {String} date
   * @return {Date|null}
   */
  function getInitialDate (date = 'start_date') {
    return (appointment[date]) ? moment(appointment[date]).toDate() : null;
  }

  /**
   * @param date
   * @return {any}
   */
  function getIntialDateMoment (date = 'start_date') {
    return (appointment[date]) ? moment(appointment[date]) : null;
  }

  /**
   * @param {Moment} start
   * @param {Moment|null} end
   */
  function sync (start, end = null) {
    const dateTimeFormat = 'YYYY-MM-DD HH:mm:ss';
    const data = {
      start_date: null,
      end_date: null
    };

    if (start) {
      data.start_date = start.format(dateTimeFormat);
      data.end_date = end ? end.format(dateTimeFormat) : start.format(dateTimeFormat);
    }

    const updatedAppointment = { ...appointment, ...data };

    onChange(updatedAppointment);
  }

  /**
   * @param {Date|null} newDate the date sent via the Datepicker component
   */
  const updateDateField = newDate => {
    let newStartTime = null;
    let newEndTime = null;

    setStartDate(newDate);

    if (!newDate) {
      setStartTime(null);
      setEndTime(null);

      onChange(null);

      return;
    }

    if (startTime) {
      const data = {
        year: newDate.getFullYear(),
        month: newDate.getMonth(),
        date: newDate.getUTCDate()
      };

      newStartTime = startTime.clone()
        .set(data);

      newEndTime = (endTime)
        ? endTime.clone()
          .set(data)
        : null;
    } else {
      const hour = moment().add(1, 'hour')
        .get('hour');

      newStartTime = moment(newDate).set({ hour });

      newEndTime = newStartTime.clone()
        .add(1, 'hour');
    }

    setStartTime(newStartTime);
    setEndTime(newEndTime);

    sync(newStartTime, newEndTime);
  };

  /**
   * @param {Object} newTime the time sent via the Rimepicker component
   * @param {Boolean} start
   */
  function updateTimeField (newTime, start = true) {
    const updateCallback = (start) ? setStartTime : setEndTime;
    const newStart = (start) ? newTime : startTime;
    const newEnd = (start) ? endTime : newTime;

    updateCallback(newTime);

    sync(newStart, newEnd);
  }

  function disableSupplierDropdown () {
    // We need to show the dropdown for meetings that selected
    // the supplier before we had the meeting type dropdown.
    if (hideMeetingType || appointment.supplier_id) {
      return false;
    }

    return appointment.title !== 'Möte i våra lokaler' && appointment.title !== 'meeting_at_our_offices';
  }

  const onChangeMeetingType = (title) => {
    const data = {
      title
    };

    if (title !== 'Möte i våra lokaler' && title !== 'meeting_at_our_offices') {
      data.supplier = null;
      data.supplier_id = null;
    }

    onChange({
      ...appointment,
      ...data
    });
  };

  return (
    <div
      className={classNames('appointment', { 'has-suppliers': showSuppliers })}
    >
      <div className="appointment__fields">
        <ReactSVG
          className="appointment__field icon"
          src="/icons/calendar-copy.svg"
        />

        <div className="appointment__field start-date floating-label has-content">
          <DatePicker
            id="appointment-date"
            autoComplete="off"
            placeholderText={t('-- Select a date --')}
            selected={startDate}
            onChange={updateDateField}
            locale={appointmentCountryCode}
            {...datePickerProps}
          />
          <label htmlFor="appointment-date">{t('Date')}</label>
        </div>
        <div className="appointment__field start-time floating-label has-content">
          <TimePicker
            id="appointment-start-time"
            value={startTime}
            placeholder={t('Time')}
            onChange={(time) => updateTimeField(time)}
            {...timePickerProps}
          />
          <label htmlFor="appointment-start-time">{t('Start time')}</label>
        </div>
        <div className="appointment__field end-time floating-label has-content">
          <TimePicker
            id="appointment-end-time"
            value={endTime}
            placeholder={t('Time')}
            onChange={(time) => updateTimeField(time, false)}
            {...timePickerProps}
          />
          <label htmlFor="appointment-end-time">{t('End time')}</label>
        </div>
      </div>

      {showSuppliers && (
        <>
          <div className="appointment__fields supplier-meeting-type">
            {!hideMeetingType
              ? (
                <div className="appointment__field meeting-type">
                  <Select
                    label={t('Meeting type')}
                    id="meeting-type"
                    defaultOption={t('-- Select meeting type --')}
                    value={appointment.title}
                    options={getMeetingTypesForSelect()}
                    onChange={onChangeMeetingType}
                    floatingLabel
                  />
                </div>
              )
              : null}
            <div className="appointment__field supplier react-select__floating-label">
              <SupplierDropdown
                id="meeting-supplier-id"
                type="moteslokal"
                noValueLabel={
                  disableSupplierDropdown()
                    ? t(
                      'Please select "Meeting at our offices" as the type of meeting',
                    )
                    : t('-- No meeting venue selected --')
                }
                isDisabled={disableSupplierDropdown()}
                supplier={appointment.supplier}
                country={orderCountry}
                locationId={locationId}
                onChange={(type, supplier) => {
                  onChange({
                    ...appointment,
                    ...{
                      supplier,
                      supplier_id: supplier ? supplier.id : null,
                    },
                  });
                }}
              />
              <label htmlFor="meeting-supplier-id">
                {t('Select meeting venue')}
              </label>
            </div>
          </div>
          {isTherapyOrder &&
          appointment.title === 'video_meeting' &&
          order.meet_url
            ? (
              <div className="appointment__link">
                <strong>{t('Meeting link')}:</strong>{' '}
                <a
                  href={`https://meet.lavendla.com/${order.meet_url}` + '#config.prejoinConfig.enabled=true&config.subject="Lavendla"'}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  https://meet.lavendla.com/{order.meet_url}
                </a>
              </div>
            )
            : null}
        </>
      )}

      {settings.showHideEndTimeToggle && (
        <div className="appointment__fields settings">
          <div className="appointment__field">
            <Toggle
              label={t('Hide end time')}
              desc={`${t('This will hide the end time everywhere it is displayed for the customer')}`}
              onChange={() => onChange({ ...appointment, hide_end_date: !appointment.hide_end_date })}
              defaultChecked={appointment.hide_end_date}
            />
          </div>
        </div>
      )}

      {settings.showMarkAsBusyToggle && (
        <div className="appointment__fields settings">
          <div className="appointment__field">
            <Toggle
              label={t('Mark as busy')}
              desc={`${t('This will mark the time as busy in the calendar and will not be available for booking')}`}
              defaultChecked={appointment.busy}
              onChange={() => onChange({ ...appointment, busy: !appointment.busy })}
            />
          </div>
        </div>
      )}
    </div>
  );
};

Appointment.propTypes = {
  onChange: PropTypes.func.isRequired,
  appointment: PropTypes.object,
  showSuppliers: PropTypes.bool,
  locationId: PropTypes.number,
  hideMeetingType: PropTypes.bool,
  settings: PropTypes.shape({
    showHideEndTimeToggle: PropTypes.bool,
    showMarkAsBusyToggle: PropTypes.bool
  })
};

Appointment.defaultProps = {
  settings: {
    showHideEndTimeToggle: true,
    showMarkAsBusyToggle: true
  }
};

export default Appointment;
