import React, { useEffect, useState } from 'react';
import useUser from 'ReduxHooks/useUser';
import { LoadingButton } from 'FormElements';
import { useHistory } from 'react-router-dom';
import UserForm from '../components/User/UserForm';
import Spinner from '../components/Spinner';
import { useTranslation } from 'react-i18next';
import UserModel from '../models/UserModel';
import { showWarningNotice } from 'Library/notifications';

function CreateUser () {
  const history = useHistory();
  const { dispatchUser, create, user, getFeatures } = useUser();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    loadDependencies();
  }, []);

  const loadDependencies = async () => {
    await getFeatures().then((features) => dispatchUser(UserModel(features)));
  };

  async function createUser () {
    if (! user.email || ! user.name || ! user.country) {
      showWarningNotice(t('Name, e-mail and home country are required'));

      return;
    }

    setIsLoading(true);
    const response = await create();
    setIsLoading(false);

    if (response) {
      history.push(`/anvandare/id/${response.id}`);
    }
  }

  if (!user) {
    return (
      <Spinner />
    );
  }

  return (
    <div className='row order-tab__form'>
      <form className='col-12 user-profile'>
        <h2 className='page-title'>
          {t('Create user')}
        </h2>
        <div className='row new-form'>
          <div className='col-8'>
            <UserForm isCreateMode={true} />
          </div>
        </div>

        <LoadingButton
          data-cy='create-user'
          isLoading={isLoading}
          onClick={() => createUser()}
        >
          {t('Create')}
        </LoadingButton>
      </form>
    </div>
  );
}

export default CreateUser;
