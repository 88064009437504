import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import Spinner from 'Components/Spinner';
import CompetenceCategoryTypeForm from './CompetenceCategoryTypeForm';
import useCompetenceCategoryTypes from 'ReduxHooks/useCompetenceCategoryTypes';

const CompetenceCategoryTypeEdit = ({ match }) => {
  const { t } = useTranslation();
  const { fetchCompetenceCategoryType, competenceCategoryType } = useCompetenceCategoryTypes();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    fetch(match.params.id);
  }, [match.params.id]);

  async function fetch (id) {
    setIsLoading(true);
    await fetchCompetenceCategoryType(id);
    setIsLoading(false);
  }

  return (
    <div className='competence-area'>
      <h2 className='page-title'>{t('Edit competence category type')}</h2>

      {isLoading
        ? (<Spinner />)
        : (<CompetenceCategoryTypeForm existingCompetenceCategoryType={competenceCategoryType} />)
      }
    </div>
  );
};

CompetenceCategoryTypeEdit.propTypes = {
  match: PropTypes.object.isRequired
};

export default CompetenceCategoryTypeEdit;
