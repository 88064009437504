import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Spinner from '../components/Spinner';
import { HeaderWithFilters } from 'Layout';
import API from '../stores/API';
import { useTranslation } from 'react-i18next';
import { If } from 'Conditionals';
import { LoadingButton } from 'FormElements';
import { formatDate } from 'Library/functions';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';
import UserCountryDropdown from 'Components/UserCountryDropdown';

function AdministrationList () {
  const { t } = useTranslation();
  const history = useHistory();
  const [tasks, setTasks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const { hasMultipleCountries, loggedInUser: { country: userCountry } } = useLoggedInUser();
  const [country, setCountry] = useState(userCountry);

  useEffect(() => {
    fetchTasks();
  }, [country]);

  async function fetchTasks () {
    let uri = '/tasks?limit=all&include=reminder,competence_areas';

    if (country?.id) {
      uri += `&country_id=${country.id}`;
    }

    const response = await API.GET(uri);

    setIsLoading(false);
    setTasks(response.data);
  }

  function getTranslatedType (type = '') {
    switch (type) {
      case 'lawyer_order':
        return t('Law order');
      case 'tombstone_order':
        return t('Tombstone order');
      case 'funeral_order':
        return t('Funeral order');
      case 'therapy_order':
        return t('Therapy order');
      case 'ceremony_order':
        return t('Ceremony order');
      default:
        return t('Funeral order');
    }
  }

  return (
    <div className='registry'>
      <HeaderWithFilters header={t('Admin tasks')}>
        <div className='header-with-filters__filters__filter'>
          <LoadingButton
            onClick={() => history.push('/tasks/task')}
          >
            {t('Create new')}
          </LoadingButton>
        </div>

        {hasMultipleCountries && (
          <div className='header-with-filters__filters__filter'>
            <UserCountryDropdown
              selected={country}
              onChange={countries => setCountry(countries[0])}
              isClearable
            />
          </div>
        )}
      </HeaderWithFilters>

      <div className='registry__registry-list links'>
        <If condition={isLoading}>
          <Spinner />
        </If>
        <If condition={!isLoading}>
          <div className='registry__registry-list'>
            <table className='registry__registry-list__table'>
              <thead>
                <tr>
                  <th>{t('Name')}</th>
                  <th>{t('Type')}</th>
                  <th>{t('Updated at')}</th>
                </tr>
              </thead>
              <tbody>
                {tasks.map(({ name, id, type, updated_at: updatedAt }) => (
                  <tr key={id}>
                    <td>
                      <Link to={`/tasks/task/${id}`}>
                        {name}
                      </Link>
                    </td>
                    <td>{getTranslatedType(type)}</td>
                    <td>{formatDate(updatedAt, userCountry.code)}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </If>
      </div>
    </div>
  );
}

export default AdministrationList;
