import { getLanguageFromCountryCode } from './functions';

/**
 * The languages follow the ISO 639-1 standard.
 * Languages with local variations (i.e. `en-GB`) are allowed to adhere to the ISO 3166-1 standard.
 *
 * @link https://en.wikipedia.org/wiki/List_of_ISO_639_language_codes
 */
export const Language = {
  Swedish: 'sv',
  Danish: 'da',
  English: 'en-GB',
  French: 'fr',
  German: 'de',
  Spanish: 'es',
  Portuguese: 'pt-PT',
  Dutch: 'nl',

  ISO_LOCALE_MAP: {
    sv: 'sv_SE',
    da: 'da_DK',
    'en-GB': 'en_GB',
    fr: 'fr_FR',
    de: 'de_DE',
    es: 'es_ES',
    'pt-PT': 'pt_PT',
    nl: 'nl_NL'
  },

  LANGUAGE_NAMES: {
    da_DK: 'Danish',
    sv_SE: 'Swedish',
    de_DE: 'German',
    en_GB: 'English',
    fr_FR: 'French',
    es_ES: 'Spanish',
    pt_PT: 'Portuguese',
    nl_NL: 'Dutch'
  },

  getLanguageFromIsoLocale (locale = null) {
    if (locale && locale.startsWith('en_')) {
      return this.English;
    }

    const language = Object.keys(this.ISO_LOCALE_MAP).find(
      key => this.ISO_LOCALE_MAP[key] === locale
    );

    return language || null;
  },

  getIsoLocale (language) {
    return this.ISO_LOCALE_MAP[language];
  },

  getLanguageName (language) {
    return this.LANGUAGE_NAMES[this.getIsoLocale(language)];
  },

  getLanguageFromCountryCode (countryCode) {
    return getLanguageFromCountryCode(countryCode);
  }
};

// These two lists are left for backwards compatibility
export const locales = [
  Language.getIsoLocale(Language.Swedish).toLowerCase(),
  Language.getIsoLocale(Language.Danish).toLowerCase(),
  Language.getIsoLocale(Language.German).toLowerCase(),
  Language.getIsoLocale(Language.English).toLowerCase()
];

export const languagesList = {
  [Language.getIsoLocale(Language.Swedish).toLowerCase()]: Language.getLanguageName(Language.Swedish),
  [Language.getIsoLocale(Language.Danish).toLowerCase()]: Language.getLanguageName(Language.Danish),
  [Language.getIsoLocale(Language.German).toLowerCase()]: Language.getLanguageName(Language.German),
  [Language.getIsoLocale(Language.English).toLowerCase()]: Language.getLanguageName(Language.English)
};
