// Arvskifte
export const ESTATE_ADMINISTRATION = 'estate_administration';

// Bouppteckning
export const ESTATE_INVENTORY = 'estate_inventory';

// Dödsboförvaltning
export const INHERITANCE_DISTRIBUTION = 'inheritance_distribution';

export const caseTypeIsEstateOrInheritance = (caseType) => {
  return [
    ESTATE_ADMINISTRATION,
    ESTATE_INVENTORY,
    INHERITANCE_DISTRIBUTION
  ].includes(caseType);
};
