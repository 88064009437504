import { TYPE_FUNERAL } from 'ReduxActions/competenceAreas';

export default () => {
  return {
    name: '',
    slug: '',
    type: TYPE_FUNERAL,
    duration: null,
    active_on_profile_pages_and_in_sliders: true,
    active_in_inquiry_menus: true,
    sort_value: 0,
    competence_category_type_id: 0,
    competence_category_id: 0,
    translations: []
  }
};
