import React, { useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import Spinner from 'Components/Spinner';
import CustomerForm from 'Components/OrderTabs/CustomerForm';
import SessionsForm from 'Components/OrderTabs/SessionsForm';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import FilesForm from 'Components/OrderTabs/FilesForm';
import CustomerRecordsForm from 'Components/OrderTabs/CustomerRecordsForm';
import { isTabIncomplete } from 'Library/Orders';
import SingleWrapper from './SingleWrapper';
import useTabIndexes from 'Hooks/useTabIndexes';
import { useTranslation } from 'react-i18next';

function SingleTherapyOrder ({ match }) {
  const { t } = useTranslation();
  const orderType = 'App\\TherapyOrder';
  const { isLoading, get, order } = useSingleOrder();
  const { tabIndex, updateTabs } = useTabIndexes({
    uri: `/therapy/${match.params.id}`,
    tab: match.params.tab || null,
    type: orderType
  });

  useEffect(() => {
    get(match.params.id, orderType);
  }, [match.params.id]);

  if (!order || isLoading) {
    return (
      <Spinner />
    );
  }

  return (
    <SingleWrapper>
      <Tabs selectedIndex={tabIndex} onSelect={(index) => updateTabs(index)}>
        <TabList>
          <Tab
            className={classNames('react-tabs__tab', {
              incomplete: isTabIncomplete(order)
            })}
          >
            {t('Customer')}
          </Tab>
          <Tab>{t('Sessions')}</Tab>
          <Tab>{t('Records')}</Tab>
          <Tab>{t('Files')}</Tab>
        </TabList>
        <TabPanel>
          <CustomerForm />
        </TabPanel>
        <TabPanel>
          <SessionsForm />
        </TabPanel>
        <TabPanel>
          <CustomerRecordsForm />
        </TabPanel>
        <TabPanel>
          <FilesForm />
        </TabPanel>
      </Tabs>
    </SingleWrapper>
  );
}

SingleTherapyOrder.propTypes = {
  match: PropTypes.object.isRequired
};

export default SingleTherapyOrder;
