import { isInvoiceTask } from 'Library/Tasks';
import PropTypes from 'prop-types';
import React from 'react';
import { Notice } from 'Layout';
import useSingleOrder from 'ReduxHooks/useSingleOrder';
import { useTranslation } from 'react-i18next';

const DANISH_FUNERAL_HELP_PRODUCT_NAME = 'Begravelseshjælp';

const getRequiredProductNames = (countryCode) => {
  switch (countryCode) {
    case 'dk':
      return [DANISH_FUNERAL_HELP_PRODUCT_NAME];
    default:
      return [];
  }
};

const getRequiredCategoryNames = (countryCode) => {
  switch (countryCode) {
    case 'dk':
      return ['Kørsel', 'Ilægning i kiste', 'Honorar', 'Kister'];
    case 'se':
      return ['Transport', 'Omhändertagande', 'Administration', 'Ceremonivärd', 'Kista'];
    default:
      return [];
  }
};

const RequiredFuneralOrderItemsWarning = ({ order, task = null }) => {
  const { t } = useTranslation();
  const { getCountryCode, isFuneralOrder } = useSingleOrder();

  if (!isFuneralOrder || !task || !isInvoiceTask(task)) {
    return null;
  }

  const countryCode = getCountryCode();

  const missingCategoryNames = getMissingCategoryNames(order, countryCode);
  const missingProductNames = getMissingProductNames(order, countryCode);

  if (missingCategoryNames.length === 0 && missingProductNames.length === 0) {
    return null;
  }

  const missingCategoryMessage = missingCategoryNames.length === 1
    ? t('The offer/invoice is missing a product in this category that is usually obligatory.')
    : t('The offer/invoice is missing a product in these categories that is usually obligatory.');

  return (
    <Notice heading={t('Did you forget something?')}>
      {missingCategoryNames.length > 0 && (
        <>
          <p>{missingCategoryMessage}</p>
          <ul>
            {missingCategoryNames.map((name, index) => (
              <li key={`missing_category_${index}`}>{name}</li>
            ))}
          </ul>
        </>
      )}
      {missingProductNames.length > 0 && (
        <>
          <p>{t('The offer/invoice is missing one or more obligatory products.')}</p>
          <ul>
            {missingProductNames.map((name, index) => (
              <li key={`missing_product_${index}`}>{name}</li>
            ))}
          </ul>
        </>
      )}
    </Notice>
  );
};

const getMissingCategoryNames = (order, countryCode) => {
  const requiredCategoryNames = getRequiredCategoryNames(countryCode);

  if (requiredCategoryNames.length === 0) {
    return [];
  }

  const orderCategoryNames = order.products
    .map(({ categories }) => categories.map(({ name }) => name))
    .flat();

  return requiredCategoryNames.filter(name => !orderCategoryNames.includes(name));
};

const getMissingProductNames = (order, countryCode) => {
  const requiredProductNames = getRequiredProductNames(countryCode);

  if (requiredProductNames.length === 0) {
    return [];
  }

  const orderProductNames = order.products.map(({ name }) => name);

  let missingProductNames = requiredProductNames.filter(name => !orderProductNames.includes(name));

  return maybeRemoveDanishFuneralHelpProduct(missingProductNames, order);
};

/**
 * Danish citizens born before April 1st 1957 has the right to a write-off.
 * This should therefore trigger a warning for all Danish orders
 * where the deceased is born before that specific date.
 * Unfortunately we only store the birth year, so the cut-off
 * has to be before 1957 (since we can't know the date more precisely).
 */
const maybeRemoveDanishFuneralHelpProduct = (missingProductNames, order) => {
  // Leave early if the obligatory product isn't missing (i.e. is in the order)
  if (missingProductNames.length === 0 || !missingProductNames.includes(DANISH_FUNERAL_HELP_PRODUCT_NAME)) {
    return missingProductNames;
  }

  const birthYearOfTheDeceased = parseInt(order.deceased?.birth_year || 0);

  // Keep the product as missing if the birth year
  // - isn't null nor 0
  // - is before 1957
  if (birthYearOfTheDeceased && birthYearOfTheDeceased < 1957) {
    return missingProductNames;
  }

  // The birth year is unknown or after 1957, and shouldn't trigger a warning.
  // Remove the warning.
  const productIndex = missingProductNames.indexOf(DANISH_FUNERAL_HELP_PRODUCT_NAME)
  if (productIndex >= 0) {
    missingProductNames.splice(productIndex, 1);
  }

  return missingProductNames;
}

RequiredFuneralOrderItemsWarning.propTypes = {
  order: PropTypes.object.isRequired,
  task: PropTypes.object
};

export default RequiredFuneralOrderItemsWarning;
