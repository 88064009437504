import React from 'react';
import { useTranslation } from 'react-i18next';
import { formatISO, parseISO } from 'date-fns';
import { enGB, sv } from 'date-fns/locale';
import { format } from 'date-fns-tz';
import { getUTCOffset, getDatesForSetTimezone } from 'Library/dateFunctions';
import { isString } from 'lodash';
import useLoggedInUser from 'ReduxHooks/useLoggedInUser';

function AppointmentDateTime ({ start, meetingLength, customerTimezone }) {
  const { t } = useTranslation();
  const { loggedInUserCountry: { code: countryCode } } = useLoggedInUser();
  const timeFormat = 'p';
  const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone || 'Europe/Stockholm';
  const end = start && meetingLength ? new Date(start).setMinutes(new Date(start).getMinutes() + meetingLength) : null;
  const locale = {
    se: sv,
    dk: sv, // Fix for DK as date-fns does not format danish dates correctly
    global: enGB
  };

  if (!start) {
    return t('Date not set');
  }

  const userDateTime = getUserDateTime();
  const customerDateTime = getCustomerDateTime();

  function formatDate (date) {
    const dateObj = isString(date) ? parseISO(date) : formatISO(date);
    const dayName = Intl.DateTimeFormat(locale[countryCode].code, { weekday: 'short' }).format(dateObj);
    const capitalizedDayName = dayName.charAt(0).toUpperCase() + dayName.slice(1);
    const dayAndMonth = Intl.DateTimeFormat(locale[countryCode].code, { day: 'numeric', month: 'short' }).format(dateObj);

    return `${capitalizedDayName}, ${dayAndMonth}`;
  }

  function getDateAndTimes (startDateTime, endDateTime, timeZone) {
    if (!startDateTime) {
      return [];
    }

    try {
      const [zonedStart, zonedEnd] = getDatesForSetTimezone(startDateTime, endDateTime, timeZone);

      if (isNaN(zonedStart.getTime()) || isNaN(zonedEnd.getTime())) {
        return [isNaN(zonedStart.getTime()) ? null : zonedStart, isNaN(zonedEnd.getTime()) ? null : zonedEnd];
      }

      const formattedDate = formatDate(startDateTime);
      const formattedStart = zonedStart ? format(zonedStart, timeFormat, { timeZone, locale: locale[countryCode] }) : null;
      const formattedEnd = zonedEnd ? format(zonedEnd, timeFormat, { timeZone, locale: locale[countryCode] }) : null;
      const formattedStartDateTime = formattedDate ? `${formattedDate} ${formattedStart}` : formattedStart;

      return [formattedStartDateTime, formattedEnd];
    } catch (error) {
      console.error(`Error formatting date in ${timeZone} timezone:`, error);
    }
  }

  function getUserDateTime () {
    const [userFormattedStart, userFormattedEnd] = getDateAndTimes(start, end, timeZone);

    if (!userFormattedStart) {
      return t('Date not set');
    }

    return userFormattedEnd
      ? `${userFormattedStart} - ${userFormattedEnd} (UTC${getUTCOffset(timeZone) || ''})`
      : `${userFormattedStart} (UTC${getUTCOffset(timeZone) || ''})`;
  }

  function getCustomerDateTime () {
    const [customerFormattedStart, customerFormattedEnd] = getDateAndTimes(start, end, customerTimezone);

    if (!customerFormattedStart) {
      return null;
    }

    const customerAppointmentTimes = customerFormattedEnd
      ? `${customerFormattedStart} - ${customerFormattedEnd} (UTC${getUTCOffset(customerTimezone) || ''})`
      : `${customerFormattedStart} (UTC${getUTCOffset(customerTimezone) || ''})`;

    return customerAppointmentTimes;
  }

  if (!customerTimezone || userDateTime === customerDateTime) {
    return (
      <>
        {userDateTime}
      </>
    );
  }

  return (
    <>
      {t('You')} ({timeZone})
      <br />
      {userDateTime}

      {customerDateTime && (
        <>
          <br />
          <span className='customer-meeting-time'>
            {t('Customer')} ({customerTimezone})
            <br />
            {customerDateTime}
          </span>
        </>
      )}
    </>
  );
}

export default AppointmentDateTime;
