import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { LoadingButton, Input } from 'FormElements/index';

// Redux
import ModelTranslations from 'Components/ModelTranslations/ModelTranslations';
import useCompetenceCategoryTypes from 'ReduxHooks/useCompetenceCategoryTypes';

function CompetenceCategoryForm ({ existingCompetenceCategoryType }) {
  const { t } = useTranslation();
  const history = useHistory();
  const { persist, refreshCompetenceCategoryTypes } = useCompetenceCategoryTypes();
  const [isLoading, setIsLoading] = useState(false);
  const [localCompetenceCategoryType, setUpdatedCompetenceCategoryType] = useState(existingCompetenceCategoryType);

  const isDisabled = () => {
    return (localCompetenceCategoryType.translations || []).filter((tr) => tr.value).length === 0;
  }

  async function onSubmit () {
    // No creation; only update for now
    if (isDisabled() || !existingCompetenceCategoryType) {
      return;
    }

    setIsLoading(true);
    await persist(localCompetenceCategoryType);
    setIsLoading(false);

    refreshCompetenceCategoryTypes();

    redirectToArchives();
  }

  function redirectToArchives () {
    setTimeout(() => {
      history.push('/competence-category-types');
    }, 1000);
  }

  return (
    <form className="order-tab__form">
      <div className="row">
        <Input
          label={t('Type')}
          data-cy='competence-category-type__type'
          value={localCompetenceCategoryType.type_label}
          disabled
          className='col-8'
          floatingLabel
        />

      </div>

      <div className="row">
        <div className="col-8">
          <ModelTranslations
            model={localCompetenceCategoryType}
            property={'label'}
            onChange={translations => setUpdatedCompetenceCategoryType({
              ...localCompetenceCategoryType,
              ...{ translations },
            })}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-8">
          <LoadingButton
            isLoading={isLoading}
            data-cy="competence-category-type__submit"
            disabled={isDisabled()}
            onClick={() => onSubmit()}
          >
            {existingCompetenceCategoryType ? t('Update') : t('Create')}
          </LoadingButton>
        </div>
      </div>
    </form>
  );
}

CompetenceCategoryForm.propTypes = {
  existingCompetenceCategoryType: PropTypes.object
};

export default CompetenceCategoryForm;
