import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import store from '../stores/RootStore';
import Spinner from '../components/Spinner';
import SupplierRelations from '../library/SupplierRelations';
import TextHelpers from '../library/TextHelpers';
import { formatDate, getFuneralPlace } from '../library/functions';
import { useTranslation } from 'react-i18next';

/**
 * Get a supplier of type from the order suppliers.
 *
 * @param {Object} order
 * @param {String} type
 * @return {string}
 */
const getSupplierOfType = (order, type) => {
  const { t } = useTranslation();
  const relations = new SupplierRelations(order.funeral.suppliers || []);

  const supplier = relations.getSupplierOfType(type);

  return supplier ? supplier.name : t('not chosen');
};

/**
 * Get the suppliers of type from the order suppliers.
 *
 * @param {Object} order
 * @param {String} type
 * @return {string}
 */
const getSuppliersOfType = (order, type) => {
  const relations = new SupplierRelations(order.funeral.suppliers || []);

  const suppliers = relations.getSuppliersOfType(type);

  return suppliers.length ? suppliers.map(({ name }) => name).join(', ') : '';
};

/**
 * @param props
 * @return {null|*}
 * @constructor
 */
const SingleSupplierOrder = props => {
  const { t } = useTranslation();
  const [order, setOrder] = useState(null);
  const [loading, setLoader] = useState(false);
  const [hasLoadedOrder, setHasLoadedOrder] = useState(false);

  useEffect(() => {
    const { id } = props.match.params;

    if (hasLoadedOrder || loading) {
      return;
    }

    if (!id) {
      store.noticeStore.showNotice(t('Invalid ID!'), 'notice');
      return;
    }

    loadOrder(id);
  });

  /**
  * Load the order from the API
  * @param {Number} id
  */
  const loadOrder = id => {
    const { t } = useTranslation();
    setLoader(true);

    store.singleorder.loadOrder(id, 'customer,funeral,entombment,deceased,viewing,memorial,transport,location,attendees,appointments')
      .then(response => {
        setLoader(false);
        setHasLoadedOrder(true);

        if (response.status === 'error') {
          store.noticeStore.showNotice(response.errors.error_message || t('An error occurred.'), 'notice', 10000);
          return;
        }

        setOrder(response.data);
      });
  };

  if (loading) {
    return <Spinner />;
  }

  if (!order) {
    return null;
  }

  const ceremony = order.appointments.find(({ type }) => type === 'ceremony');
  const memorial = order.appointments.find(({ type }) => type === 'memorial');

  return (
    <div className='single-supplier-order'>
      <h1>{order.deceased.name} • #{order.number}</h1>

      <h3>{t('Customer')}</h3>
      <ul>
        <li><strong>{t('Name')}:</strong> {order.customer.name}</li>
        <li><strong>{t('Relation to the deceased')}:</strong> {TextHelpers.capitalize(order.customer.relation_to_deceased)}</li>
        <li><strong>{t('E-mail')}:</strong> <a href={`mailto:${order.customer.email}`}>{order.customer.email}</a></li>
        <li><strong>{t('Phone number')}:</strong> {order.customer.tel}</li>
        <li><strong>{t('Social security number')}:</strong> {order.customer.social_security_number}</li>
        <li><strong>{t('Address')}:</strong> {order.customer.address_1}, {order.customer.city}, {order.customer.postcode}</li>
      </ul>

      <h3>{t('Deceased')}</h3>
      <ul>
        <li><strong>{t('Name')}:</strong> {order.deceased.name}</li>
        <li><strong>{t('Social security number')}:</strong> {order.deceased.social_security_number}</li>
        <li><strong>{t('Deceased date')}:</strong> {order.deceased.date}</li>
        <li><strong>{t('Parish')}:</strong> {order.deceased.parish}</li>
        <li><strong>{t('Morgue')}:</strong> {getSupplierOfType(order, 'barhus')}</li>
        <li><strong>{t('Own clothes')}:</strong> {order.deceased.own_clothes}</li>
        <li><strong>{t('Ready for pick up')}:</strong> {order.deceased.pick_up_date}</li>
      </ul>

      <h3>{t('Ceremony')}</h3>
      <ul>
        <li><strong>{t('City')}:</strong> {order.location ? order.location.name : ''}</li>
        <li><strong>{t('Venue')}:</strong> {getFuneralPlace(order)}</li>
        <li><strong>{t('Divine economy')}:</strong> {TextHelpers.capitalize(order.funeral.divine_economy).replace('_', ' ')}</li>
        <li><strong>{t('Officiant')}:</strong> {order.funeral.officiant}</li>
        <li><strong>{t('Organist')}:</strong> {order.funeral.organist}</li>
        <li><strong>{t('Soloist')}:</strong> {order.funeral.soloist}</li>
        <li><strong>{t('Janitor')}:</strong> {order.funeral.janitor}</li>
        <li><strong>{t('Clothing')}:</strong> {order.funeral.clothing}</li>
        <li><strong>{t('Expected number of guests')}:</strong> {order.funeral.expected_number_of_guests}</li>
        <li><strong>{t('Number of people registered for memorial service')}:</strong> {order.attendees.length}</li>
        {ceremony && (
          <li><strong>{t('Date/time for the ceremony')}:</strong> {ceremony.start_date}</li>
        )}
        <li><strong>{t('Memorial fund')}:</strong> {getSupplierOfType(order, 'minnesfond')}</li>
        <li><strong>{t('Representative')}:</strong> {getSuppliersOfType(order, 'representant')}</li>
      </ul>

      {order.funeral && order.funeral.has_memorial
        ? (
          <>
            <h3>{t('Memorial')}</h3>
            <ul>
              <li><strong>{t('Memorial venue')}:</strong> {order.memorial.place}</li>
              {memorial && (
                <li><strong>{t('Date/time')}:</strong> {formatDate(memorial.start_date, order.country.code)} - {formatDate(memorial.end_date, order.country.code, 'p')}</li>
              )}
              <li><strong>{t('Food')}:</strong> {order.memorial.food}</li>
              <li><strong>{t('Catering supplier')}:</strong> {getSupplierOfType(order, 'cateringleverantoer')}</li>
              <li><strong>{t('RSVP for memorial service')}:</strong> {order.memorial.rsvp}</li>
            </ul>
          </>
        )
        : null}

      <h3>{t('Interment')}</h3>
      <ul>
        <li><strong>{t('Type')}:</strong> {TextHelpers.capitalize(order.funeral.type)}</li>
        <li><strong>{t('Cemetery')}:</strong> {order.funeral.graveyard}</li>
        <li><strong>{t('Entombment')}:</strong> {TextHelpers.capitalize(order.entombment.entombment)}</li>
        <li><strong>{t('Grave')}:</strong> {order.entombment.place}</li>
        <li><strong>{t('Burial rights holder')}:</strong> {order.entombment.burial_rights_holder}</li>
        <li><strong>{t('Relatives present at burial')}:</strong> {TextHelpers.convertBool(order.entombment.participation)}</li>
        <li><strong>{t('Participation date')}:</strong> {order.entombment.participation_date} {order.entombment.participation_start_time}</li>
      </ul>

      <h3>{t('Transport')}</h3>
      <ul>
        <li><strong>{t('Transporter')}:</strong> {getSupplierOfType(order, 'transportoer')}</li>
        <li><strong>{t('Coffin storage')}:</strong> {order.transport.coffin_storage}</li>
        <li><strong>{t('Invoice to church')}:</strong> {order.transport.invoice_to_swedish_church}</li>
        <li><strong>{t('Number of coffin carriers for invoice')}:</strong> {order.transport.num_coffin_carriers}</li>
      </ul>

      <h3>{t('Flowers')}</h3>
      <ul>
        <li><strong>{t('Florist')}:</strong> {getSupplierOfType(order, 'blomleverantoer')}</li>
      </ul>
    </div>
  );
};

SingleSupplierOrder.propTypes = {
  match: PropTypes.object.isRequired
};

export default withRouter(SingleSupplierOrder);
