import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { If } from 'Conditionals/index';
import PropTypes from 'prop-types';
import { Input } from 'FormElements/index';
import Checkbox from 'FormElements/Checkbox';
import TextHelpers from 'Library/TextHelpers';

const ModelTranslations = ({ translation, property, onChange, language, isEditing = true }) => {
  const [localTranslation, setLocalTranslation] = useState(translation);
  const { t } = useTranslation();

  const setChanges = (key, value) => {
    const updatedTranslation = { ...localTranslation, [key]: value };

    setLocalTranslation(updatedTranslation);

    onChange(updatedTranslation);
  };

  return (
    <tr>
      <td width={'100px'}>
        <Checkbox
          onChange={value => setChanges('active', value)}
          defaultChecked={!!localTranslation.active}
          label={''}
        />
      </td>
      <td width={'200px'}>
        <span>
          {language.english_label}
        </span>
      </td>
      <td>
        <If condition={isEditing}>
          <Input
            floatingLabel
            id={`input_${property}_${language.code}`}
            onChange={value => setChanges('value', value)}
            label={`${language.english_label} ${property}`}
            value={localTranslation.value}
          />
        </If>
        <If condition={!isEditing}>
          <span>
            {localTranslation.value || '-'} {t('content')}
          </span>
        </If>
      </td>
    </tr>
  );
}

ModelTranslations.propTypes = {
  model: PropTypes.object,
  property: PropTypes.string,
  onChange: PropTypes.func,
  isEditing: PropTypes.bool,
};

export default ModelTranslations;
